import { PureComponent } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  display: flex;
  width: ${(props) => (props.width ? props.width : "10em")};
  height: ${(props) => (props.height ? props.height : "2em")};
  padding: ${(props) => (props.padding ? props.padding : "0.5em")};
`;
const Skeleton = styled.div`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0.5em"};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  animation: skeleton 5s infinite;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: ${(props) => `linear-gradient(
      90deg,
      transparent,
      ${props.theme.skeletonForegroundColor},
      transparent
    )`};
    animation: shimmer 1.5s infinite;
    content: "";
  }
  @keyframes skeleton {
    0% {
      background: ${(props) => props.theme.skeletonForegroundColor};
    }
    50% {
      background: ${(props) => props.theme.skeletonBackgroundColor};
    }
    100% {
      background: ${(props) => props.theme.skeletonForegroundColor};
    }
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

export default class SimpleSkeleton extends PureComponent {
  render() {
    return (
      <Wrapper
        height={this.props.height}
        width={this.props.width}
        padding={this.props.padding}
      >
        <Skeleton borderRadius={this.props.borderRadius} />
      </Wrapper>
    );
  }
}
