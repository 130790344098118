import { PureComponent } from "react";
import { connect } from "react-redux";
import styled, { css, keyframes } from "styled-components";

const moveBlue = keyframes`
  0% {
    transform: translate(0, 0);
    animation-timing-function: cubic-bezier(0.37, 0.8, 0.57, 1.53);
  }
  30% {
    transform: translate(15px, 6px);
    animation-timing-function: cubic-bezier(0.03, 0.89, 0.51, 1.63);
  }
  60% {
    transform: translate(15px, 6px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translate(-5px, -2px);
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  100% {
    transform: translate(1px, 1px);
    animation-timing-function: ease;
  }
`;

const moveRed = keyframes`
  0% {
    transform: translate(0, 0);
    animation-timing-function: cubic-bezier(0.37, 0.8, 0.57, 1.53);
  }
  30% {
    transform: translate(-15px, -10px);
    animation-timing-function: cubic-bezier(0.03, 0.89, 0.51, 1.63);
  }
  60% {
    transform: translate(-15px, -10px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translate(4px, 4px);
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  100% {
    transform: translate(1px, 1px);
    animation-timing-function: ease;
  }
`;

const moveYellow = keyframes`
  0% {
    transform: translate(0, 0);
    animation-timing-function: cubic-bezier(0.37, 0.8, 0.57, 1.53);
  }
  30% {
    transform: translate(20px, -6px);
    animation-timing-function: cubic-bezier(0.03, 0.89, 0.51, 1.63);
  }
  60% {
    transform: translate(20px, -6px);
    animation-timing-function: ease-in;
  }
  80% {
    transform: translate(-5px, 2px);
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  100% {
    transform: translate(1px, 1px);
    animation-timing-function: ease;
  }
`;

const Svg = styled.svg`
  height: 100%;
  transition: ${(props) => props.theme.slowTransition};
  width: ${(props) => (props.width ? props.width : "auto")};
  max-height: ${(props) => (props.height ? props.height : "2.25em")};
  padding-left: ${(props) => (props.mobile ? "0.5em" : "0")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0.2em")};

  .logo-red {
    fill: ${props => props.fill || props.theme.redLogo};
    animation: ${props => props.animating && css`${moveRed} 1s 0.18s ease-out`};
  }

  .logo-blue {
    fill: ${props => props.fill || props.theme.blueLogo};
    animation: ${props => props.animating && css`${moveBlue} 1s 0.1s ease-out`};
  }

  .logo-yellow {
    fill: ${props => props.fill || props.theme.goldLogo};
    animation: ${props => props.animating && css`${moveYellow} 1s ease-out`};
  }

  .cls-4 {
    fill: ${(props) => props.fill || props.theme.greyLogo};
  }

  &:hover {
    transform: ${(props) => props.hoverTransform || "scale(1.03)"};
  }
`;

class Logo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      animating: this.props.animated,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.router &&
      prevProps.router.location &&
      prevProps.router.location.pathname &&
      prevProps.router.location.pathname.split("/")[1] !==
      this.props.router.location.pathname.split("/")[1]
    ) {
      this.setState({ animating: false }, () => {
        this.timerHandle = setTimeout(() => {
          this.setState({ animating: true });
          this.timerHandle = 0;
        }, 200);
      });
    }
  }

  componentWillUnmount() {
    // Clear any timer when component unmounts
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  render() {
    return (
      <Svg
        width={this.props.width}
        height={this.props.height}
        hoverTransform={this.props.hoverTransform}
        mobile={this.props.mobile}
        marginTop={this.props.marginTop}
        fill={this.props.fill}
        animating={this.state.animating} // pass animation condition
        viewBox="0 0 915.3 220.5"
        className="logoSvg"
      >
        <g>
          <path
            className="logo-red"
            d="M94.6,35.5l-40,15.6c-5.8,2.2-9.6,7.8-9.6,14v98.7c0,4.3,3.5,7.7,7.7,7.7s1.9-.2,2.8-.5l40-15.6c5.8-2.2,9.6-7.8,9.6-14V42.8c0-4.3-3.5-7.8-7.8-7.8s-1.9.2-2.8.5Z"
          />
          <path
            className="logo-blue"
            d="M168.9,86.4l-40,15.6c-5.8,2.2-9.6,7.8-9.6,14v47.8c0,4.3,3.5,7.7,7.7,7.7s1.9-.2,2.8-.5l40-15.6c5.8-2.2,9.6-7.8,9.6-14v-47.8c0-4.3-3.5-7.7-7.7-7.7s-1.9.2-2.8.5Z"
          />
          <path
            className="logo-yellow"
            d="M168.9,35.5l-40,15.6c-5.8,2.2-9.6,7.8-9.6,14v15.1c0,4.3,3.5,7.7,7.7,7.7s1.9-.2,2.8-.5l40-15.6c5.8-2.2,9.6-7.8,9.6-14v-15.1c0-4.3-3.5-7.8-7.8-7.8s-1.9.2-2.8.5Z"
          />
          <g>
            <path
              className="cls-4"
              d="M374.8,86.9c4.9,5.4,7.4,12.8,7.4,22.2v41.4h-21.6v-38.5c.3-4-1.1-8-3.7-11-5.6-5.3-14.3-5.3-19.9,0-2.6,3.1-3.9,7-3.7,11v38.5h-21.6v-85.6l21.6-8.4v32.6c2.3-3.2,5.4-5.8,9-7.5,4-1.9,8.4-2.8,12.8-2.8,7.4-.3,14.5,2.6,19.6,8.1h0Z"
            />
            <path
              className="cls-4"
              d="M459.5,120.2h-49.1c0,3.8,1.6,7.4,4.2,10.1,2.5,2.3,5.8,3.6,9.2,3.5,4.8.3,9.4-2.4,11.3-6.8h23.1c-1.2,4.6-3.4,8.9-6.4,12.6-3.1,3.8-7.1,6.8-11.6,8.8-4.9,2.2-10.2,3.2-15.6,3.2-6.5.1-12.8-1.4-18.5-4.4-5.4-2.9-9.8-7.3-12.7-12.7-3.1-5.9-4.7-12.6-4.6-19.3-.2-6.7,1.4-13.3,4.4-19.3,2.9-5.4,7.3-9.8,12.6-12.7,5.7-3,12.2-4.6,18.7-4.5,6.4-.1,12.7,1.3,18.3,4.3,5.3,2.8,9.6,7.1,12.5,12.3,3.1,5.7,4.6,12.2,4.5,18.7,0,2.1-.1,4.2-.3,6.3h0ZM437.6,108.2c.1-3.4-1.3-6.6-3.8-8.8-2.7-2.2-6-3.4-9.5-3.3-3.4,0-6.6,1-9.2,3.2-2.6,2.3-4.2,5.5-4.6,9h27.2c0,0,0,0,0,0Z"
            />
            <path className="cls-4" d="M488.1,56.5v94h-21.7v-85.7l21.7-8.3Z" />
            <path
              className="cls-4"
              d="M536.6,97.6h-8.9v52.8h-21.7v-52.8h-11.3v-18.1h11.3v-2c0-8.7,2.5-15.3,7.5-19.9,5-4.5,12.3-6.8,22-6.8s2.8,0,3.5.1v18.4c-4.1-.2-7.1.3-8.8,1.8s-2.5,4-2.5,7.7v.6h18.8l-9.9,18.1h0Z"
            />
            <path
              className="cls-4"
              d="M587.1,81.6c3.5,1.8,6.5,4.6,8.7,7.9v-10h21.7v70.8c0,6.1-1.2,12.2-3.9,17.7-2.5,5.3-6.7,9.8-11.8,12.7-6,3.3-12.9,4.9-19.7,4.7-10.5,0-19-2.5-25.5-7.4-6.4-4.8-10.5-12.1-11.2-20.1h21.5c.7,2.7,2.4,5,4.8,6.4,2.8,1.6,6.1,2.5,9.4,2.4,4,.2,7.8-1.3,10.7-4,2.7-2.6,4-6.7,4-12.4v-10c-2.2,3.4-5.2,6.2-8.8,8.1-4,2.1-8.5,3.2-13.1,3.1-5.6,0-11.1-1.5-15.9-4.5-4.9-3.1-8.8-7.6-11.2-12.8-2.9-6-4.3-12.6-4.1-19.2-.1-6.6,1.3-13.2,4.1-19.2,2.5-5.2,6.4-9.6,11.2-12.7,4.8-3,10.3-4.5,15.9-4.5,4.6,0,9.1.9,13.2,3h0ZM587.4,102.2c-5.8-6.1-15.5-6.2-21.6-.4-.1.1-.3.3-.4.4-3.2,3.5-4.8,8.1-4.5,12.8-.3,4.7,1.3,9.4,4.5,12.9,5.8,6.1,15.3,6.3,21.4.5.2-.2.4-.4.5-.5,3.2-3.5,4.8-8.1,4.5-12.8.3-4.7-1.3-9.3-4.5-12.8h0Z"
            />
            <path
              className="cls-4"
              d="M677.4,79.5l-9.8,18.1c-5.4,0-11.3,1.9-14.1,4.2s-4.3,5-4.3,15.7v32.9h-21.8v-70.9h21.8v5.6s5-9,28.1-5.6h0Z"
            />
            <path
              className="cls-4"
              d="M862.5,86.8c5.2,5.3,7.8,12.8,7.8,22.2v41.4h-21.6v-38.5c.2-3.9-1-7.7-3.6-10.6-5.7-4.9-14.2-4.9-19.8,0-2.6,2.9-3.9,6.7-3.7,10.6v38.5h-21.6v-38.5c.2-3.9-1.1-7.7-3.6-10.6-5.7-5-14.2-5-19.8,0-2.6,2.9-3.9,6.7-3.6,10.6v38.5h-21.8v-70.9h21.7v8.9c2.3-3,5.2-5.3,8.6-6.9,3.8-1.8,7.9-2.7,12-2.6,5,0,9.9,1.1,14.3,3.4,4.2,2.2,7.6,5.6,9.9,9.8,2.5-4,6-7.2,10-9.5,4.2-2.5,9-3.7,13.9-3.7,8.7,0,15.7,2.6,20.9,7.9h0Z"
            />
            <path
              className="cls-4"
              d="M266.4,153c-6.9.1-13.7-1.1-20.1-3.6-5.5-2.2-10.3-5.8-13.9-10.5-3.1-4.3-4.9-9.3-5.3-14.6h23.6c.5,3.2,2.1,6.2,4.5,8.5,2.9,2.5,6.6,3.8,10.4,3.6,3.7.2,7.3-.9,10.3-3.1,2.7-2.2,4.2-5.5,4-8.9,0-3-1.1-5.9-3.3-7.9-2.1-2-4.5-3.5-7.1-4.7-3.6-1.5-7.4-2.8-11.2-3.8-5.6-1.7-11.1-3.7-16.5-6.2-4.1-2.1-7.7-5.1-10.5-8.8-3-4.4-4.5-9.6-4.3-14.9,0-9.3,3.3-16.4,9.9-21.6,6.7-5.2,15.7-7.9,26.7-7.9s20.3,2.7,27,7.9c6.2,4.8,10,11.9,10.5,19.7h-23.9c-.5-3-2-5.7-4.3-7.6-2.9-2.3-6.5-3.5-10.2-3.4-3.2-.1-6.4.9-8.9,3-2.4,2.2-3.7,5.4-3.6,8.7,0,4.1,2.2,7.8,5.8,9.8,5,2.7,10.2,4.8,15.6,6.4,5.6,1.8,11.1,4,16.4,6.5,4.1,2.1,7.6,5,10.4,8.7,3,4.2,4.5,9.2,4.3,14.3,0,5.4-1.4,10.8-4.3,15.4-3.1,4.8-7.4,8.6-12.6,11-6.2,2.9-13,4.3-19.8,4.2h0Z"
            />
            <path
              className="cls-4"
              d="M702.8,152.3c-7.6,0-13.9-2.1-18.9-6.2-5.1-4.2-7.6-9.5-7.6-15.8s3.1-13.8,9.3-18c6-4.1,14.7-6.1,25.9-6.2h9.7v-2.4s0-9-11.5-9-11.7,8.3-11.7,8.3h-20v-1.5c0-4.3,1.4-8.3,4.2-11.9,2.8-3.6,6.8-6.4,11.8-8.5,5-2,10.6-3,16.8-3,9.4,0,17,2.3,22.6,6.7,5.7,4.5,8.5,11,8.5,19.1v47.1h-20.6v-7.7s-2.8,8.9-18.3,8.9h0ZM706,135.2c14.3,0,15.1-9.6,15.1-9.6v-5.8h-9.7c-12.8,0-16.3,3.3-16.3,8.2s1.2,7.3,11,7.3h0Z"
            />
          </g>
        </g>
      </Svg>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
});

export default connect(mapStateToProps, null)(Logo);
