import { PureComponent } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { addOpacity } from "constants/functions/index";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "30%")};
  overflow: hidden;
  min-width: 15em;
  height: 100%;
  text-align: center;
  position: relative;
  border-radius: 1em;
  box-shadow: ${(props) => props.theme.smallBoxShadow}
    ${(props) => props.theme.grey5};
  box-sizing: border-box;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.directory || props.onboarding ? "60%" : "cover"};
  user-select: none;
  cursor: ${(props) =>
    props.cursor
      ? props.cursor
      : props.fixed || props.noHover
      ? "auto"
      : "pointer"};
  font-size: 0.75em;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => props.theme.grey6};
  transition: ${(props) => props.theme.fastTransition};
  margin: ${(props) => (props.masonry ? "0" : "0.5em")};
  &:hover {
    opacity: 0.9;
    box-shadow: ${(props) => props.theme.smallBoxShadow}
      ${(props) => props.theme.grey4};
  }
  &:active {
    transform: ${(props) => (props.noHover ? "none" : "scale(0.98)")};
  }
  ${media.mobile`
    width: 100%;
  `};
`;
const HeightSize = styled.div`
  transition: ${(props) => props.theme.fastTransition};
`;
const Container = styled.div`
  width: 100%;
`;
const Group = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
`;
const CheckContainer = styled.span`
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  svg {
    fill: ${(props) => props.theme.white};
    height: 3em;
    width: 3em;
  }
`;
const RadialMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${(props) =>
    props.dark
      ? "radial-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.8))"
      : "radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))"};
`;
const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
`;
const Logo = styled.div`
  min-width: 3em;
  height: 3em;
  margin-left: 1em;
  border-radius: 100%;
  background-color: white;
  background-position: center;
  background-size: cover;
  border: 1px solid white;
`;
const Mask = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.9));
`;
const H3 = styled.h3`
  padding: 0 1em;
  text-transform: capitalize;
  margin: 0;
  font-weight: bold;
  color: white;
  font-size: 1.5em;
  box-sizing: border-box;
  text-align: ${(props) => (props.center ? "center" : "left")};
  text-rendering: optimizelegibility;
  text-shadow: 0 0 3px rgba(0, 0, 0, 1);
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  bottom: 0.75em;
  padding-bottom: 0.5em;
`;

class PillLarge extends PureComponent {
  render() {
    return (
      <>
        {this.props.directory ? (
          <Container>
            <Link
              to={
                this.props.tab
                  ? `/lp/${this.props.route}/${this.props.id}/${this.props.tab}`
                  : `/lp/${this.props.route}/${this.props.id}/feed`
              }
            >
              <Wrapper
                noHover={this.props.noHover}
                masonry={this.props.masonry}
                directory
                width={this.props.width}
                onClick={this.props.clickAction}
              >
                {this.props.backgroundImage ? (
                  <BackgroundImage
                    style={{
                      backgroundImage: this.props.backgroundImage,
                    }}
                  />
                ) : null}
                <RadialMask dark={this.props.dark} />
                <Mask />
                <Footer>
                  <H3>{this.props.text}</H3>
                </Footer>
                <HeightSize
                  style={{
                    height: this.props.masonry
                      ? `${6 + Math.random() * 8}em`
                      : "11em",
                  }}
                />
              </Wrapper>
            </Link>
          </Container>
        ) : (
          <Wrapper
            noHover={this.props.noHover}
            width={this.props.width}
            onClick={this.props.clickAction}
            onboarding={this.props.onboarding}
            cursor={this.props.cursor}
            style={{
              animation: this.props.animation
                ? this.props.animation
                : this.props.index
                ? `fadeIn ${0.5 + 0.25 * this.props.index}s`
                : "fadeIn 1s",
              opacity: this.props.opacity
                ? this.props.opacity
                : this.props.preSelected
                ? "0.3"
                : this.props.selected
                ? "1"
                : "0.7",
            }}
          >
            {this.props.backgroundImage ? (
              <BackgroundImage
                style={{
                  backgroundImage: this.props.backgroundImage,
                }}
              />
            ) : null}
            <RadialMask />
            <Mask />
            {this.props.selected ? (
              <CheckContainer>
                <FaCheckCircle />
              </CheckContainer>
            ) : null}
            {this.props.preSelectedText ? (
              <Footer>
                <Tooltip
                  unmountHTMLWhenHide
                  html={<TooltipText>{this.props.preSelectedText}</TooltipText>}
                  position="top"
                  open
                  animation="fade"
                  trigger="manual"
                >
                  <H3 center>{this.props.text}</H3>
                </Tooltip>
              </Footer>
            ) : (
              <Footer>
                <Group>
                  {this.props.img ? (
                    <Logo
                      style={{
                        backgroundImage: this.props.img,
                      }}
                    />
                  ) : null}
                  <H3 center>{this.props.text}</H3>
                </Group>
                {this.props.button ? this.props.button : null}
              </Footer>
            )}
            <HeightSize
              style={{
                height: this.props.masonry
                  ? `${6 + Math.random() * 8}em`
                  : "11em",
                background: this.props.primaryColor
                  ? `rgb${addOpacity(this.props.primaryColor, 0.4)}`
                  : "transparent",
              }}
            />
          </Wrapper>
        )}
      </>
    );
  }
}

export default PillLarge;
