import { PureComponent } from 'react';

import Responsive from 'react-responsive';

class Desktop extends PureComponent {
  render() {
    return <Responsive minWidth={1025}>{this.props.content}</Responsive>;
  }
}

export default Desktop;
