import { PureComponent } from "react";
import { connect } from "react-redux";
import { Map } from "pigeon-maps";
import styled, { withTheme } from "styled-components";
import { mapProvider } from "constants/functions/index";

const Wrapper = styled.div`
  width: 100%;
  margin: 0.25em;
  border-radius: 0.5em;
  overflow: hidden;
  cursor: pointer;
  height: 11em;
  background: ${(props) => props.theme.grey3};
  .map-of-locations {
    width: 100%;
    cursor: pointer;
    transition: filter 0.25s;
    filter: ${(props) =>
      props.darkMode
        ? "invert(1) brightness(1.5) hue-rotate(-150deg)"
        : "grayscale(0.3)"};
  }
`;

class MiniMap extends PureComponent {
  constructor(props) {
    super(props);
    this.openMap = this.openMap.bind(this);
  }
  openMap() {
    if (this.props.data && this.props.data.zoom) {
      this.props.setData({
        type: "SHOW_FULLSCREEN_MODAL",
        data: {
          is: this.props.is === "is",
          conditionIndex: this.props.conditionIndex,
          ruleIndex: this.props.ruleIndex,
          zoom: this.props.data.zoom,
          center: this.props.data.center,
        },
      });
    }
  }
  render() {
    console.log(this.props.data);
    return (
      <Wrapper onClick={this.openMap}>
        {this.props.data && this.props.data.center ? (
          <Map
            boxClassname="map-of-locations"
            center={this.props.data.center}
            zoom={this.props.data.zoom - 2}
            attribution={false}
            mouseEvents={false}
            touchEvents={false}
            provider={mapProvider}
          />
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  feedFilterRules: state.feedFilterRules,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(MiniMap));
