import { PureComponent } from "react";

import AltCTA from "components/buttons/alternate/index";
import { Link } from "react-router-dom";
import MainCTA from "components/buttons/main/index";
import { awsPrefix } from "constants/variables/index";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0.5em;
  animation: fadeIn 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-top: ${(props) => (props.topSpacing ? props.topSpacing : "0.5em")};
  margin-top: ${(props) => (props.marginTop ? "3em" : "auto")};
  margin-bottom: auto;
`;
const Img = styled.img`
  max-width: 80%;
  max-height: 30em;
  padding-top: 1em;
  z-index: 1;
  object-fit: contain;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
  ${media.mobile`
    max-width: 95%;
  `}
`;
const BeigeCircle = styled.div`
  width: 60%;
  background: ${(props) => props.theme.grey5};
  border-radius: 100%;
  transform: skewX(30deg);
  height: 80%;
  position: absolute;
`;
const P = styled.p`
  width: 90%;
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
  color: ${(props) => props.theme.grey2};
  max-width: 650px;
  padding-bottom: ${(props) => (props.extraSpacing ? "3em" : "auto")};
  ${media.mobile`
    width: 80%;
  `}
`;

export default class CircleGraphic extends PureComponent {
  render() {
    return (
      <Wrapper topSpacing={this.props.topSpacing}>
        {this.props.text ? (
          <Wrapper>
            <P extraSpacing={this.props.extraSpacing}>{this.props.text}</P>
          </Wrapper>
        ) : null}
        {this.props.body ? <Wrapper>{this.props.body}</Wrapper> : null}
        {this.props.ctaText && this.props.ctaLink ? (
          <Wrapper marginTop={this.props.marginTop}>
            {this.props.externalLink ? (
              <a
                href={this.props.ctaLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                {this.props.mainCTA ? (
                  <MainCTA
                    text={this.props.ctaText}
                    blue={this.props.blueCta}
                  />
                ) : (
                  <AltCTA text={this.props.ctaText} />
                )}
              </a>
            ) : (
              <Link to={this.props.ctaLink}>
                <AltCTA text={this.props.ctaText} />
              </Link>
            )}
          </Wrapper>
        ) : null}
        {this.props.primaryCtaText && this.props.primaryCtaClickAction ? (
          <Wrapper marginTop={this.props.marginTop}>
            <a onClick={this.props.primaryCtaClickAction}>
              {this.props.mainCTA ? (
                <MainCTA
                  text={this.props.primaryCtaText}
                  blue={this.props.blueCta}
                />
              ) : (
                <AltCTA text={this.props.primaryCtaText} />
              )}
            </a>
            {this.props.secondaryCtaClickAction &&
            this.props.secondaryCtaText ? (
              <span onClick={this.props.secondaryCtaClickAction}>
                <MainCTA
                  text={this.props.secondaryCtaText}
                  blue={this.props.blueCta}
                />
              </span>
            ) : null}
          </Wrapper>
        ) : null}
        {this.props.src ? (
          <Wrapper marginTop={this.props.marginTop}>
            <Img
              src={awsPrefix + this.props.src}
              draggable="false"
              style={{
                width: this.props.imgWidth ? this.props.imgWidth : "auto",
              }}
            />
            {this.props.noCircle ? null : <BeigeCircle />}
          </Wrapper>
        ) : null}
      </Wrapper>
    );
  }
}
