import { PureComponent } from "react";

import Responsive from "react-responsive";

class Mobile extends PureComponent {
  render() {
    return <Responsive maxWidth={420}>{this.props.content}</Responsive>;
  }
}

export default Mobile;
