import { FaCloudDownloadAlt, FaEllipsisH, FaShareAlt } from "react-icons/fa";
import { isMobileDevice } from "constants/variables/index";
import React, { PureComponent } from "react";
import { media } from "constants/responsive/index";
import { connect } from "react-redux";
import MoreMenu from "./more-menu/index";
import styled from "styled-components";
import { Modals } from "screens/modal/Modal.constants";
import { showModal } from "@redux/slices/modalSlice";

const Container = styled.div`
  display: flex;
  pointer-events: all;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  position: fixed;
  border-radius: 2em;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0.75em;
  margin-bottom: 0.5em;
  ${media.mobile`
    margin-bottom: 1em;
  `}
  ${media.tablet`
    font-size: 0.9em;
    margin-bottom: 1em;
  `}
`;
const Wrapper = styled.div`
  animation: fadeIn 0.5s;
  pointer-events: all;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 0.9em;
  list-style-type: none;
  z-index: 2;
`;
const Action = styled.div`
  pointer-events: all;
  box-sizing: border-box;
  padding: 0.5em;
  height: 2em;
  width: ${(props) => (props.circle ? "2em" : "auto")};
  border-radius: ${(props) => (props.circle ? "100%" : "2em")};
  background: ${(props) =>
    props.active ? props.theme.white : props.theme.white};
  opacity: ${(props) => (props.active ? "0.5" : "1")};
  cursor: ${(props) => (props.active ? "auto" : "pointer")};
  color: ${(props) => props.theme.grey2};
  transition: ${(props) => props.theme.defaultTransition};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 0.8em;
    padding-right: ${(props) => (props.circle ? "0" : "0.25em")};
    fill: ${(props) => props.theme.grey2};
    height: 0.8em;
  }
  &:hover {
    color: ${(props) => props.theme.grey1};
    svg {
      fill: ${(props) => props.theme.grey1};
    }
  }
`;
const A = styled.a`
  width: 100%;
`;

const Text = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.25em;
`;

const styles = {
  download: {
    width: "auto",
  },
};

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMoreMenu: false,
      mounted: false,
    };
    this.myRef = React.createRef();
    this.showMoreMenu = this.showMoreMenu.bind(this);
    this.hideMoreMenu = this.hideMoreMenu.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleShare = this.handleShare.bind(this);
  }
  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }
  showMoreMenu() {
    this.setState({ showMoreMenu: true });
  }
  hideMoreMenu() {
    this.setState({ showMoreMenu: false });
  }

  handleShare() {
    this.props.showModal({
      component: Modals.SHARE,
      data: {
        isFlyer: this.props.isFlyer,
        data: this.props.data
      }
    });
  }

  handleDownload() {
    if (!this.props.isFlyer && this.props.dataUser && this.props.data) {
      this.props.apiCall({
        method: "POST",
        component: "PHOTO_DOWNLOAD",
        body: {
          user_id: this.props.dataUser.id,
          photo_id: this.props.data.id,
        },
        route: "photo/download",
      });
    }
  }

  render() {
    return (
      <Wrapper ref={this.myRef}>
        {this.state.showMoreMenu ? (
          <MoreMenu
            data={this.props.data}
            position={this.myRef.current.getBoundingClientRect()}
            hideMoreMenu={this.hideMoreMenu}
          />
        ) : null}
        <Container>
          <A
            href={`/download/${this.props.isFlyer ? "flyer/" : ""}${
              this.props.data.img
            }?filename=${`Shelfgram - ${this.props.data.retailer}${
              this.props.data.city ? " - " + this.props.data.city + "," : ""
            } ${this.props.data.country || ""} - ${
              this.props.data.date_taken || this.props.data.start_date
            }`}`}
            target={isMobileDevice ? "_blank" : "_self"}
            style={styles.download}
          >
            <Action onClick={this.handleDownload}>
              <FaCloudDownloadAlt width="1.1em" height="0.6em"/>
              <Text>Download</Text>
            </Action>
          </A>
          {this.props.dataUser ? (
            <>
              <Action onClick={this.handleShare}>
                <FaShareAlt />
                <Text>{this.props.translations["21"] || "Share"}</Text>
              </Action>
              {!this.props.isFlyer && (
                <Action circle onClick={this.showMoreMenu}>
                  <FaEllipsisH />
                </Action>
              )}
            </>
          ) : null}
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  showModal: (payload) => dispatch(showModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
