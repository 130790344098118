import { PureComponent } from "react";
import { appPrefix, isMobileDevice } from "constants/variables/index";

import Body from "./body/index";
import Dropdown from "./dropdown/index";
import Header from "./header/index";
import { connect } from "react-redux";
import styled from "styled-components";
import { media } from "constants/responsive/index";

const Wrapper = styled.div`
  min-width: 100%;
  height: ${(props) => (props.fullHeight ? "auto" : "100%")};
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  animation: 0.5s ease-in both fadeIn;
  display: flex;
  opacity: ${(props) => (props.blurry ? "0.7" : "1")};
  filter: ${(props) => (props.blurry ? "blur(5px)" : "none")};
  pointer-events: ${(props) => (props.blurry ? "none !important" : "all")};
  flex-direction: column;
  max-height: 85vh;
  justify-content: space-evenly;
  background: ${(props) => props.theme.white};
  border-radius: 0.5em;
  transition: ${(props) => props.theme.fastTransition};
  box-shadow: ${(props) =>
    props.isSelected
      ? "0px 3px 5px #abd3ff"
      : props.theme.sortOfSubtleBoxShadow};
  border: 1px solid
    ${(props) =>
      props.isSelected
        ? props.theme.midBlue
        : props.darkMode
        ? props.theme.grey6
        : props.theme.white};
  .hoverCheckCircle {
    opacity: ${(props) => (props.selectModeOn ? "1" : "0")};
  }
  &:hover {
    .hoverCheckCircle {
      opacity: 1;
    }
  }
  ${media.mobile`
    .hoverCheckCircle {
      opacity: 1;
    }
  `}
`;
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.grey3};
`;
const A = styled.a`
  width: 100%;
`;

const styles = {
  largeCard: {
    transform: "scale(1.04)",
    boxShadow: "0px 0px 30px #bdc6cf",
  },
  normalCard: {},
};

class Card extends PureComponent {
  constructor(props) {
    super(props);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
  }

  handleSelectMultiple() {
    this.props.setData({
      type: `EDIT_SELECT_MULTIPLE_${this.props.isFlyer ? "FLYERS" : "PHOTOS"}`,
      data: { ...this.props.data, isFlyer: this.props.isFlyer },
    });
  }

  handleMouseEnter() {
    this.props.setData({
      type: "USER_SET_HOVERING_ID",
      data: this.props.data.id,
    });
  }

  handleMouseLeave() {
    this.props.setData({
      type: "USER_SET_HOVERING_ID",
      data: null,
    });
    this.setState({
      dropdownVisibility: false,
    });
  }

  render() {
    if (this.props.hide || !this.props.data) {
      return null;
    }
    const isSelected =
      this.props.selectEnabled && this.props.dataUser
        ? (!this.props.isFlyer &&
            this.props.feedMultiplePhotoSelect &&
            this.props.feedMultiplePhotoSelect.data &&
            this.props.feedMultiplePhotoSelect.data.length > 0 &&
            this.props.feedMultiplePhotoSelect.data
              .map((card) => card.id)
              .includes(this.props.data.id)) ||
          (this.props.isFlyer &&
            this.props.feedMultipleFlyerSelect &&
            this.props.feedMultipleFlyerSelect.data &&
            this.props.feedMultipleFlyerSelect.data.length > 0 &&
            this.props.feedMultipleFlyerSelect.data
              .map((card) => card.id)
              .includes(this.props.data.id))
        : false;
    return (
      <Wrapper
        blurry={this.props.blurry}
        darkMode={this.props.dataUser && this.props.dataUser.dark_mode}
        fullHeight={this.props.fullHeight}
        isSelected={isSelected}
        selectModeOn={
          this.props.selectEnabled && this.props.dataUser
            ? this.props.isFlyer
              ? this.props.feedMultipleFlyerSelect &&
                this.props.feedMultipleFlyerSelect.data &&
                this.props.feedMultipleFlyerSelect.data.length > 0
              : this.props.feedMultiplePhotoSelect &&
                this.props.feedMultiplePhotoSelect.data &&
                this.props.feedMultiplePhotoSelect.data.length > 0
            : false
        }
        style={
          !this.props.isSharePage &&
          this.props.dataViewerSettings.viewerOpenAsync &&
          this.props.dataViewer &&
          this.props.dataViewer.id === this.props.data.id
            ? styles.largeCard
            : styles.normalCard
        }
      >
        <Header
          data={this.props.data}
          width={this.props.headerWidth}
          handleSelectMultiple={this.handleSelectMultiple}
          isSelected={isSelected}
          selectEnabled={this.props.selectEnabled}
          isFlyer={this.props.isFlyer}
          hidePageNum={this.props.hidePageNum}
          showCity={this.props.showCity}
          showState={this.props.showState}
          showCountry={this.props.showCountry}
          showStoreNumber={this.props.showStoreNumber}
          showSpecificDate={this.props.showSpecificDate}
          showSpecificTime={this.props.showSpecificTime}
          embedded={this.props.embedded}
        />
        <Container
          style={{
            background: this.props.data.background_gradient
              ? this.props.data.background_gradient
              : this.props.data.primary_color
              ? `rgb${this.props.data.primary_color}`
              : null,
          }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {(isMobileDevice ||
            this.props.userPreferences.hoveringId === this.props.data.id) &&
          !isSelected ? (
            <Dropdown
              data={this.props.data}
              isFlyer={this.props.isFlyer}
              embedded={this.props.embedded}
              showDownloadButton={this.props.showDownloadButton}
            />
          ) : null}
          {this.props.embedded ? (
            <A
              href={`${appPrefix}s/${this.props.data.img}?embed=true`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Body
                img={this.props.data.img}
                isFlyer={this.props.isFlyer}
                primaryColor={this.props.data.primary_color}
                orientation={this.props.data.orientation}
              />
            </A>
          ) : (
            <Body
              img={this.props.data.img}
              isFlyer={this.props.isFlyer}
              primaryColor={this.props.data.primary_color}
              orientation={this.props.data.orientation}
              handleViewerOpen={this.props.handleViewerOpen}
            />
          )}
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  feedMultiplePhotoSelect: state.feedMultiplePhotoSelect,
  feedMultipleFlyerSelect: state.feedMultipleFlyerSelect,
  userPreferences: state.userPreferences,
  dataViewer: state.dataViewer,
  dataViewerSettings: state.dataViewerSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
