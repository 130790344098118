import styled, { keyframes } from "styled-components";
import { useEffect } from "react";

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Bar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0 8px 8px 0;
  height: 8px;
  background-color: ${(props) => props.theme.midBlue};
  z-index: 1000;
  transition: width 0.3s ease;
  animation: ${slideDown} 0.5s ease;
`;

const ProgressBar = ({ currentScreen, totalScreens }) => {
  const progress = (currentScreen / totalScreens) * 100;

  useEffect(() => {}, [currentScreen, totalScreens, progress]);

  return <Bar style={{ width: `${progress}%` }} />;
};

export default ProgressBar;
