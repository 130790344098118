import styled from "styled-components";
import { PureComponent } from "react";
import { media } from "constants/responsive/index";

const Wrapper = styled.div`
  min-width: 10em;
  width: ${(props) => (props.width ? props.width : "20%")};
  box-sizing: border-box;
  animation: fadeIn 0.5s;
  padding: 0.5em;
  ${media.mobile`
    width: 100%;
  `}
`;
const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.white};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.white};
  overflow: hidden;
  box-sizing: border-box;
  height: ${(props) => (props.height ? props.height : "8em")};
  min-height: ${(props) => (props.alignCenter ? "3.5em" : "4.5em")};
  display: flex;
  flex-wrap: ${(props) => (props.wraps ? "wrap" : "nowrap")};
  align-items: ${(props) =>
    props.collapsed || props.alignCenter ? "center" : "flex-start"};
  justify-content: center;
  border: 1px solid ${(props) => props.theme.grey5};
  border-radius: 0.75em;
  cursor: ${(props) => (props.interactive ? "pointer" : "default")};
  transition: ${(props) => props.theme.defaultTransition};
  color: ${(props) => props.theme.grey2};
  &:hover {
    color: ${(props) =>
      props.interactive ? props.theme.grey1 : props.theme.grey2};
    transform: ${(props) => (props.interactive ? "translateY(-2px)" : "none")};
    box-shadow: ${(props) =>
      props.interactive ? props.theme.subtleBoxShadow : "none"};
    .bentoBackgroundImage {
      opacity: 0.8;
    }
  }
`;
const Title = styled.h3`
  transition: ${(props) => props.theme.defaultTransition};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: ${(props) => (props.compress ? props.compress : "1em")};
  width: 100%;
  text-align: left;
  margin: 0;
`;
const Background = styled.div`
  background-size: ${(props) => (props.contain ? "contain" : "cover")};
  transition: ${(props) => props.theme.defaultTransition};
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) =>
    props.backgroundIconColor
      ? props.backgroundIconColor
      : props.contain
      ? "transparent"
      : props.theme.grey5};
  width: 100%;
  min-height: 70%;
  height: ${(props) =>
    props.collapsed && !props.backgroundHeight
      ? "100%"
      : props.backgroundHeight
      ? props.backgroundHeight
      : props.contain
      ? "5em"
      : "7em"};
  margin: ${(props) => (props.contain ? "1em" : "0")};
  filter: ${(props) =>
    props.contain
      ? "drop-shadow(rgba(0, 0, 0, 0.2) 0px 4px 4px)"
      : "contrast(0.8) saturate(0.8)"};
  ${media.mobile`
    height: ${(props) => (!props.collapsed ? "3em" : "6em")};
  `}
`;
const InnerContainer = styled.div`
  background: ${(props) =>
    props.innerBackgroundColor
      ? props.innerBackgroundColor
      : props.theme.white};
  display: flex;
  flex-wrap: ${(props) => (props.collapsed ? "wrap" : "nowrap")};
  align-items: center;
  z-index: 1;
  justify-content: ${(props) =>
    props.justifyCenter ? "center" : "space-between"};
  padding: 1em;
  height: ${(props) => (props.fullHeight ? "100%" : "auto")};
  width: 100%;
  ${media.mobile`
    justify-content: ${(props) => (props.collapsed ? "flex-start" : "center")};
  `}
`;
const Icon = styled.div`
  min-height: 1.75em;
  min-width: 1.75em;
  border-radius: 0.25em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
`;

export default class Bento extends PureComponent {
  render() {
    return (
      <Wrapper width={this.props.width} onClick={this.props.onClick}>
        <Container
          height={this.props.height}
          interactive={this.props.interactive}
          wraps={!this.props.collapsed && this.props.wraps}
          backgroundColor={this.props.backgroundColor}
          collapsed={this.props.collapsed}
          alignCenter={this.props.alignCenter}
        >
          {this.props.icon3 && !this.props.collapsed ? this.props.icon3 : null}
          {this.props.backgroundImage ? (
            <Background
              backgroundHeight={this.props.backgroundHeight}
              className="bentoBackgroundImage"
              collapsed={this.props.collapsed}
              style={{
                backgroundImage: `url(${this.props.backgroundImage})`,
              }}
            />
          ) : this.props.backgroundIcon ? (
            <Background
              contain
              backgroundHeight={this.props.backgroundHeight}
              collapsed={this.props.collapsed}
              className="bentoBackgroundImage"
              style={{
                backgroundImage: `url(${this.props.backgroundIcon})`,
              }}
            />
          ) : null}
          <InnerContainer
            collapsed={this.props.collapsed}
            innerBackgroundColor={this.props.innerBackgroundColor}
            justifyCenter={this.props.justifyCenter}
            fullHeight={this.props.fullHeightInnerContainer}
          >
            {this.props.icon0 && !this.props.collapsed
              ? this.props.icon0
              : null}
            {this.props.icon1 ? (
              <Icon
                style={{
                  marginRight: this.props.title ? "1em" : "0",
                  backgroundImage: `url(${this.props.icon1})`,
                }}
              />
            ) : null}
            {this.props.title ? (
              <Title
                bold={this.props.bold}
                compress={
                  !this.props.wide && this.props.title.length > 26
                    ? "0.8em"
                    : !this.props.wide && this.props.title.length > 17
                    ? "0.9em"
                    : "1em"
                }
              >
                {this.props.title}
              </Title>
            ) : null}
            {this.props.icon2 ? (
              <Icon
                style={{
                  marginLeft:
                    this.props.title && !this.props.collapsed ? "1em" : "0",
                  backgroundImage: `url(${this.props.icon2})`,
                }}
              />
            ) : null}
            {this.props.children}
          </InnerContainer>
        </Container>
      </Wrapper>
    );
  }
}
