import { PureComponent } from "react";
import { FallbackUserImage } from "components/fallback-user-image/FallbackUserImage";
import Login from "components/buttons/flat-login/index";
import SignUp from "components/buttons/flat-sign-up/index";
import { media } from "constants/responsive";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.mobile`
    padding: 0.25em;
    font-size: 0.9em;
  `};
`;

const P = styled.p`
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.theme.grey2};
  padding: 0.5em;
  margin: 0;
`;

const styles = {
  join: {
    flexWrap: "wrap",
    height: "6em",
  },
  cta: {
    fontWeight: "normal",
  },
};

export default class JoinToast extends PureComponent {
  render() {
    if (!this.props.dataUser)
      return (
        <Wrapper
          style={{
            padding: "0.5em",
            flexWrap: this.props.photo ? "nowrap" : "wrap",
          }}
        >
          {this.props.photo || this.props.firstName ? (
            <FallbackUserImage
              src={this.props.photo}
              firstName={this.props.firstName}
              width="5em"
              height="5em"
            />
          ) : (
            <P>You're signed out</P>
          )}
          <Wrapper style={styles.join}>
            {this.props.firstName ? (
              <P>
                {`Join ${this.props.firstName} on Shelfgram for more retail photos`}
              </P>
            ) : (
              <P style={styles.cta}>Sign in to get the best experience</P>
            )}
            <Login />
            <SignUp />
          </Wrapper>
        </Wrapper>
      );
  }
}
