import styled from "styled-components";

const PillContainer = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 0.5em 1em;
  margin: 0.5em;
  border-radius: 25px;
  border: 1px solid
    ${(props) => (props.selected ? props.theme.grey4 : props.theme.grey4)};
  background-color: ${(props) =>
    props.selected ? props.theme.grey2 : props.theme.white};
  color: ${(props) => (props.selected ? props.theme.white : props.theme.grey1)};
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;

  svg {
    fill: ${(props) =>
      props.selected ? props.theme.white : props.theme.grey2};
    margin-right: 0.5em;
  }
`;

const PillText = styled.span`
  font-size: 0.9em;
`;

const IconWrapper = styled.div`
  margin-right: 0.5em;
  color: ${(props) => props.theme.grey4};
`;

const SelectablePill = ({ icon, label, selected, onClick }) => {
  return (
    <PillContainer selected={selected} onClick={onClick}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <PillText>{label}</PillText>
    </PillContainer>
  );
};

export default SelectablePill;
