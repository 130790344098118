import styled, { keyframes, css } from "styled-components";

// Define keyframes for animation
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

// Chip container with animation and dynamic styling
const ChipContainer = styled.div`
  display: flex;
  position: relative;
  min-width: 60px;
  width: auto;
  height: 20px;
  border-radius: 6px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  text-align: center;
  padding: 0.3em;
  background-color: ${(props) => props.backgroundColor || props.theme.white};
  color: ${(props) => props.textColor || props.theme.black};
  font-size: 0.8em;
  font-weight: 500;
  opacity: 0;
  animation: ${(props) =>
    props.animateIn
      ? css`
          ${fadeIn} 0.3s ease-out forwards;
        `
      : css`
          ${fadeOut} 0.3s ease-out forwards;
        `};
  animation-delay: ${(props) => props.delay || 0}s;
  transition: all 0.3s ease-out;
`;

const IconContainer = ({ icon }) => {
  return <StyledIconContainer icon={icon} />;
};

const StyledIconContainer = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${(props) => props.icon});
  background-size: cover;
  background-position: center;
  margin-right: 0.3em;
`;

const ChipText = styled.span`
  display: inline-block;
  margin-left: 0.3em;
  font-size: 0.6em;
  font-weight: 600;
  color: ${(props) => props.theme.grey2};
`;

const Chip = ({
  label,
  backgroundColor,
  textColor,
  img,
  delay = 0,
  animateIn = true,
}) => {
  return (
    <ChipContainer
      backgroundColor={backgroundColor}
      textColor={textColor}
      delay={delay}
      animateIn={animateIn}
    >
      {img && <IconContainer icon={img} />}
      <ChipText>{label}</ChipText>
    </ChipContainer>
  );
};

export default Chip;
