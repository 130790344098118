import moment from "moment";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { utils, write } from "xlsx";
const { book_new, aoa_to_sheet, book_append_sheet, encode_col, encode_cell } =
  utils;

const MAX_COL_WIDTH = 60;

function sanitizeSheetName(name) {
  return name
    .replaceAll("/", " ")
    .replaceAll("[", " ")
    .replaceAll("]", " ")
    .replaceAll("*", " ")
    .replaceAll("?", " ")
    .replaceAll(":", " ");
}

export function excelExport(data, name) {
  toast.loading("Downloading...");
  const filename = `${name} - ${moment(new Date()).format("MMM D YYYY")}`;
  const workbook = book_new();
  data.forEach((sheet, i) => {
    let sanitizedSheetName = sanitizeSheetName(sheet.name);
    if (sanitizedSheetName.length > 30) {
      sanitizedSheetName = sanitizedSheetName.substring(0, 30);
    }
    const ws_data = [
      Object.keys(sheet.data[0]).map((x) => x.replace("_", " ")),
    ];
    sheet.data.forEach((row) => {
      ws_data.push(Object.values(row));
    });

    const worksheet = aoa_to_sheet(ws_data);
    // Set column width
    const cellWidths = Object.keys(sheet.data).map((x, i) =>
      Object.values(sheet.data[i]).map((y) =>
        typeof y === "string" ? `${y}`.length : y && y.text ? y.text.length : 12
      )
    );
    const columnWidths = Object.keys(sheet.data[0]).map((x, i) =>
      Math.max(...cellWidths.map((z) => z[i]).flat())
    );
    worksheet["!cols"] = columnWidths.map((x) => ({
      wch: x > MAX_COL_WIDTH ? MAX_COL_WIDTH : x,
    }));
    // Get the range of cells to apply the autofilter on
    const range = {
      s: { c: 0, r: 0 },
      e: { c: ws_data[0].length - 1, r: ws_data.length - 1 },
    };
    // Set the autofilter property on all columns
    for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
      const col = encode_col(colIndex);
      worksheet["!autofilter"] = {
        ref: `${encode_col(range.s.c)}${range.s.r + 1}:${encode_col(
          range.e.c
        )}${range.e.r + 1}`,
      };
      // Add hyperlinks
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellRef = encode_cell({ r: R, c: C });
          const cell = worksheet[cellRef];
          if (
            cell &&
            cell.hyperlink &&
            typeof cell.hyperlink === "string" &&
            (cell.hyperlink.startsWith("mailto:") ||
              cell.hyperlink.startsWith("http://") ||
              cell.hyperlink.startsWith("https://"))
          ) {
            cell.v = cell.text;
            cell.l = { Target: cell.hyperlink };
            cell.t = "s";
          }
        }
      }
    }
    book_append_sheet(workbook, worksheet, sanitizedSheetName);
  });

  const wbout = write(workbook, { bookType: "xlsx", type: "binary" });
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${filename.length < 31 ? filename : "Shelfgram - Excel Download"}.xlsx`
  );
  toast.dismiss();
}
