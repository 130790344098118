import {
  FaCloudDownloadAlt,
  FaFileArchive,
  FaFilePowerpoint,
  FaRegThumbsDown,
  FaShareAlt,
} from "react-icons/fa";
import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "services/with-router/index";
import AltCTA from "components/buttons/alternate/index";
import Card from "components/feed/cards/index";
import DocumentTitle from "react-document-title";
import { Flex, SkeletonContainer } from "constants/styled-components/index";
import JoinToast from "components/toasts/join/index";
import Loader from "components/loader/index";
import Mobile from "constants/responsive/mobile/index";
import SimpleSkeletonCard from "components/simple-skeleton-card/index";
import SimpleSkeleton from "components/simple-skeleton/index";
import { awsPhotosPrefixS3 } from "constants/variables/index";
import { connect } from "react-redux";
import { generatePowerpoint } from "services/download-powerpoint/photos/index";
import { letterToNumber } from "constants/functions/index";
import { media } from "constants/responsive/index";
import moment from "moment";
import toast from "react-hot-toast";
import { zipTheFeed } from "services/download-zip/index";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";

const CARD_WIDTH = 380;

const Container = styled.div`
  padding: 1em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
const CardContainer = styled.div`
  width: ${CARD_WIDTH}px;
  max-width: 90vw;
  padding: 0.75em;
`;
const GalleryActions = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${media.mobile`
    display: none;
  `};
`;
const H2 = styled.h2`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.grey2};
  padding: 1em;
  margin: 0;
`;

const styles = {
  container: {
    paddingBottom: "8em",
    overflowY: "scroll",
    height: "100%",
  },
  padding: {
    padding: "3em",
  },
};

class SharedPhotosPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      numPills: false,
      isValid: true,
      zipping: false,
      zipProcessed: 0,
    };
    this.showPowerpointExport = this.showPowerpointExport.bind(this);
    this.handleReportGeneration = this.handleReportGeneration.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleDownloadAsZIPOpen = this.handleDownloadAsZIPOpen.bind(this);
    this.handleDownloadSinglePhoto = this.handleDownloadSinglePhoto.bind(this);
    this.loginFunc = this.loginFunc.bind(this);
    this.handleEnterpriseGate = this.handleEnterpriseGate.bind(this);
  }
  componentDidMount() {
    this.setState({
      numPills: parseInt(letterToNumber(this.props.params.shareId), 10),
    });
    this.props.apiCall({
      method: "GET",
      component: "GALLERY_PAGE",
      route: `${this.props.isFlyer ? "flyers" : "photo"}/shared/${
        this.props.params.urlSlug
      }`,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dataGalleryPage &&
      this.props.dataGalleryPage.data &&
      !this.props.dataGalleryPage.data.error &&
      prevProps.showViewer &&
      !this.props.showViewer &&
      !this.props.dataUser &&
      !this.state.showToast
    ) {
      this.setState(
        {
          showToast: true,
        },
        () =>
          toast(
            <JoinToast
              photo={this.props.dataGalleryPage.data.photo}
              firstName={
                this.props.dataGalleryPage.data.first_name ||
                this.props.dataGalleryPage.data.share_name
              }
            />,
            { duration: 90000000 }
          )
      );
    }
  }

  showPowerpointExport() {
    if (this.props.dataGalleryPage.data.imgs.length === 1) {
      generatePowerpoint(
        this.props.dataGalleryPage.data.imgs,
        this.props.isFlyer ? "flyers" : "photos",
        this.props.isFlyer ? "Flyer from Shelfgram" : "Photo from Shelfgram",
        this.props.dataUser.ppt_high_res,
        this.props.userPreferences && this.props.userPreferences.current_pc,
        this.props.dataUser.ppt_logo
      );
    } else {
      this.props.showModal({
        component: Modals.POWERPOINT_EXPORT,
        data: {
          photos: this.props.dataGalleryPage.data.imgs,
          isFlyer: this.props.isFlyer,
          presentationName:
            this.props.isFlyer
              ? "Flyers from Shelfgram"
              : "Photos from Shelfgram",
        },
      });
    }
  }
  handleEnterpriseGate() {
    this.props.showModal({ component: Modals.UPGRADE_FLASH });
  }

  loginFunc() {
    this.props.showModal({ component: Modals.LOGIN });
  }

  handleShare() {
    this.props.showModal({
      component: Modals.SHARE,
      data: {
        isFlyer: this.props.isFlyer,
        isPhoto: !this.props.isFlyer,
        data: this.props.dataGalleryPage.data.imgs,
      }
    });
  }

  finishedZippedFunc() {
    this.setState({ zipping: false });
  }

  handleDownloadAsZIPOpen() {
    this.setState({ zipping: true }, () => {
      zipTheFeed(
        this.props.dataGalleryPage.data.imgs.map(
          (x) =>
            `${awsPhotosPrefixS3}${this.props.isFlyer ? "flyers" : "photos"}/${
              x.img
            }`
        ),
        this.props.dataUser.first_name + " " + this.props.dataUser.last_name,
        this.props.dataGalleryPage.data.imgs,
        this.finishedZippedFunc.bind(this),
        this.props.isFlyer ? "Flyers" : "Photos"
      );
    });
  }

  handleDownloadSinglePhoto() {
    if (this.props.dataUser && !this.props.isFlyer) {
      this.props.apiCall({
        method: "POST",
        component: "PHOTO_DOWNLOAD",
        body: {
          user_id: this.props.dataUser.id,
          photo_id: this.props.dataGalleryPage.data.imgs[0].id,
        },
        route: "photo/download",
      });
    }
  }

  handleViewerOpen(i) {
    this.props.setData({
      type: "VIEWER_DATA_SET",
      data: this.props.dataGalleryPage.data.imgs[i],
    });
    this.props.setData({
      type: "SHOW_VIEWER",
      data: { type: this.props.isFlyer ? "FLYER" : "SHARED_PHOTOS" },
    });
  }

  handleReportGeneration() {
    this.props.setData({
      type: "GENERATE_A_REPORT_FROM_SHARE_PAGE",
      data: this.props.dataGalleryPage.data.imgs,
    });
  }

  render() {
    return (
      <DocumentTitle title="Shared Photos - Shelfgram">
        <Container style={styles.container}>
          {!this.state.numPills ? (
            <Loader size="1em" padding="0" />
          ) : this.props.dataGalleryPage &&
            this.props.dataGalleryPage.data &&
            this.props.dataGalleryPage.data.error ? (
            <Container style={styles.padding}>
              <FaRegThumbsDown size="3em" color={this.props.theme.grey2} />
              <H2>Sorry, the link you're using isn't valid :(</H2>
            </Container>
          ) : this.props.dataGalleryPage &&
            this.props.dataGalleryPage.data &&
            this.props.dataGalleryPage.data.imgs ? (
            <Flex flexWrap="wrap">
              {this.props.dataGalleryPage.data.share_name?.trim() !== "Anonymous" ? (
                <H2>
                  {!this.props.dataGalleryPage.data.imgs.length
                    ? "This share link has been deleted"
                    : `${this.props.dataGalleryPage.data.share_name} shared ${
                        this.props.dataGalleryPage.data.imgs.length === 1
                          ? this.props.isFlyer
                            ? "a circular"
                            : "a photo"
                          : `${this.props.dataGalleryPage.data.imgs.length} ${
                              this.props.isFlyer ? "flyers" : "photos"
                            }`
                      } with you`}
                </H2>
              ) : null}
              {!!this.props.dataGalleryPage.data.imgs.length && (
                <GalleryActions>
                  {this.props.dataGalleryPage.data.imgs.length === 1 ? (
                    <a
                      onClick={this.handleDownloadSinglePhoto}
                      href={
                        this.props.isFlyer
                          ? `/download/flyer/${
                              this.props.dataGalleryPage.data.imgs[0].img
                            }?filename=Shelfgram - ${
                              this.props.dataGalleryPage.data.imgs[0].retailer
                            }${
                              this.props.dataGalleryPage.data.imgs[0]
                                .start_date &&
                              this.props.dataGalleryPage.data.imgs[0].end_date
                                ? ` - ${moment(
                                    this.props.dataGalleryPage.data.imgs[0]
                                      .start_date
                                  ).format("MMM D, YYYY")} - ${moment(
                                    this.props.dataGalleryPage.data.imgs[0]
                                      .end_date
                                  ).format("MMM D, YYYY")}`
                                : ""
                            } - Circular`
                          : `/download/${
                              this.props.dataGalleryPage.data.imgs[0].img
                            }?filename=${`Shelfgram - ${
                              this.props.dataGalleryPage.data.imgs[0].retailer
                            } - ${
                              this.props.dataGalleryPage.data.imgs[0].city
                                ? this.props.dataGalleryPage.data.imgs[0].city +
                                  " - "
                                : ""
                            }${
                              this.props.dataGalleryPage.data.imgs[0].country
                            } - ${moment(
                              this.props.dataGalleryPage.data.imgs[0].date_taken
                            ).format("MMM D, YYYY")}`}`
                      }
                    >
                      <AltCTA
                        text={`Download ${
                          this.props.isFlyer ? "Flyer" : "Photo"
                        }`}
                        icon={<FaCloudDownloadAlt />}
                      />
                    </a>
                  ) : (
                    <AltCTA
                      clickAction={
                        this.props.dataUser
                          ? this.props.dataUser.plan_id > 2
                            ? this.handleDownloadAsZIPOpen
                            : this.handleEnterpriseGate
                          : this.loginFunc
                      }
                      text={
                        this.state.zipping
                          ? this.state.zipProcessed > 0
                            ? this.props.dataGalleryPage.data.imgs.length < 5
                              ? "Zipping..."
                              : `Zipping ${this.state.zipProcessed} of ${this.props.dataGalleryPage.data.imgs.length}`
                            : "Zipping..."
                          : "ZIP"
                      }
                      disabled={this.state.zipping}
                      icon={
                        this.state.zipping ? (
                          <Loader
                            size="1em"
                            padding="0"
                            width="auto"
                            margin="0"
                            svgMargin="0"
                          />
                        ) : (
                          <FaFileArchive />
                        )
                      }
                    />
                  )}
                  <AltCTA
                    clickAction={
                      this.props.dataUser
                        ? this.props.dataUser.plan_id > 2
                          ? this.showPowerpointExport
                          : this.handleEnterpriseGate
                        : this.loginFunc
                    }
                    text="PowerPoint"
                    icon={<FaFilePowerpoint />}
                  />
                  <AltCTA
                    text="Reshare"
                    icon={<FaShareAlt />}
                    clickAction={
                      this.props.dataUser ? this.handleShare : this.loginFunc
                    }
                  />
                </GalleryActions>
              )}
              <Mobile
                content={
                  <AltCTA
                    text="Reshare"
                    icon={<FaShareAlt />}
                    clickAction={
                      this.props.dataUser ? this.handleShare : this.loginFunc
                    }
                  />
                }
              />
              <Container>
                {this.props.dataGalleryPage &&
                this.props.dataGalleryPage.data &&
                this.props.dataGalleryPage.data.imgs &&
                this.props.dataGalleryPage.data.imgs.length > 0
                  ? this.props.dataGalleryPage.data.imgs.map((x, i) => (
                      <CardContainer
                        key={`card-share-${i}-${x.img}`}
                        style={{
                          height: this.props.isFlyer ? "570px" : "380px",
                        }}
                      >
                        <Card
                          data={x}
                          showCity
                          showState
                          showCountry
                          headerWidth={CARD_WIDTH}
                          isFlyer={this.props.isFlyer}
                          handleViewerOpen={this.handleViewerOpen.bind(this, i)}
                        />
                      </CardContainer>
                    ))
                  : null}
              </Container>
            </Flex>
          ) : (
            <SkeletonContainer>
              <SkeletonContainer>
                <SimpleSkeleton width="70%" />
              </SkeletonContainer>
              <SimpleSkeleton width="15%" />
              <SimpleSkeleton width="15%" />
              <SimpleSkeleton width="15%" />
              <SimpleSkeletonCard
                pills={this.state.numPills > 6 ? 6 : this.state.numPills}
                width="25%"
                height="25em"
                mask
              />
            </SkeletonContainer>
          )}
        </Container>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  dataGalleryPage: state.dataGalleryPage,
  dataUser: state.dataUser,
  showViewer: state.showViewer,
  router: state.router,
  userPreferences: state.userPreferences,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default withRouter(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(SharedPhotosPage))
);
