import {
  LoadingComponent,
  cardHeight,
  cardWidth,
  queryStringToObject,
} from "constants/functions/index";
import React, { PureComponent } from "react";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import Card from "components/feed/cards/index";
import { CardSpacing } from "constants/styled-components/index";
import Grid from "react-virtualized/dist/commonjs/Grid";
import LoadMore from "components/feed/load-more/index";
import SimpleSkeletonCard from "components/simple-skeleton-card/index";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { emptyArray } from "constants/variables/index";
import { inspect } from "util";
import styled from "styled-components";
import { showModal } from "@redux/slices/modalSlice";

const CircleGraphic = Loadable({
  loader: async () =>
    import(
      /* webpackChunkName: "CircleGraphic" */ "components/circle-graphic/index"
    ),
  loading: LoadingComponent,
  delay: 300,
});

const numFlyersToLoad = 240;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  overflow: visible;
  width: 100%;
  .ReactVirtualized__Grid__innerScrollContainer {
    transition: all 0.3s ease-in;
    left: 50%;
    transform: translateX(-50%);
    margin-top: ${(props) => (props.multiSelect ? "3.5em" : "2em")} !important;
  }
`;

const styles = {
  grid: {
    outline: "none",
    paddingBottom: 500,
  },
};

function decodeUrlFilters(filters) {
  let actionData = {};
  if (filters.front_page_only) {
    actionData.front_page_only = filters.front_page_only === "true";
  }
  if (filters.country) {
    actionData.country =
      filters.country === "all" ? "all" : parseInt(filters.country);
  }
  if (filters.text_selected) {
    actionData.text_selected = decodeURIComponent(filters.text_selected);
  }
  if (filters.retailers_selected) {
    actionData.retailers_selected = filters.retailers_selected
      .split("+++")
      .map((y) => y.split("|"))
      .filter((z) => z.length === 4)
      .map((o, i) => ({
        id: decodeURIComponent(o[0]),
        name: decodeURIComponent(o[1]),
        img: decodeURIComponent(o[2]),
        source_id: decodeURIComponent(o[3]),
      }));
  }
  if (filters.brands_selected) {
    actionData.brands_selected = filters.brands_selected
      .split("+++")
      .map((y) => y.split("|"))
      .filter((z) => z.length === 3)
      .map((o, i) => ({
        id: decodeURIComponent(o[0]),
        name: decodeURIComponent(o[1]),
        img: decodeURIComponent(o[2]),
      }));
  }
  if (filters.categories_selected) {
    actionData.categories_selected = filters.categories_selected
      .split("+++")
      .map((y) => y.split("|"))
      .filter((z) => z.length === 3)
      .map((o, i) => ({
        id: decodeURIComponent(o[0]),
        name: decodeURIComponent(o[1]),
        img: decodeURIComponent(o[2]),
      }));
  }
  return actionData;
}

class FlyerFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowsLeft: 100,
      scrolledTo: 0,
      persistSettings: false,
      windowWidth: window.innerWidth,
      cardWidth: cardWidth(),
      cardHeight: cardHeight(
        this.props.dataUser.cards_in_flyer_layout || 4,
        1.5
      ),
      pageToLoad: 0,
    };
    this.wrapperRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.loadMoreFlyers = this.loadMoreFlyers.bind(this);
    this.overscanIndicesGetter = this.overscanIndicesGetter.bind(this);
    this._onScroll = this._onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setLayout();
    if (queryStringToObject(this.props.router.location.search).filtered) {
      // Init custom filters
      this.props.setData({
        type: "FLYER_SETTINGS_CUSTOM",
        data: decodeUrlFilters(
          queryStringToObject(this.props.router.location.search)
        ),
      });
    } else if (this.props.isLanding) {
      this.props.setData({
        type: "HIDE_HEADER",
      });
      this.resetFlyers();
      if (this.props.dataLandingPageAbout && this.props.page !== "text") {
        this.initLandingPageFeed();
      } else if (this.props.page === "text" && this.props.id) {
        this.initLandingPageFeedText();
      }
    } else {
      this.resetAndLoadFeed();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showSideMenu !== this.props.showSideMenu) {
      setTimeout(() => {
        this.setLayout();
      }, 300);
    } else {
      if (
        !queryStringToObject(this.props.router.location.search).filtered &&
        !prevProps.dataLandingPageAbout &&
        this.props.dataLandingPageAbout
      ) {
        this.initLandingPageFeed();
      }
      if (
        inspect(prevProps.dataFlyersSettings.existing, { depth: null }) !==
          inspect(this.props.dataFlyersSettings.existing, { depth: null }) ||
        prevProps.dataFlyersSettings.sort !==
          this.props.dataFlyersSettings.sort ||
        (prevProps.dataFlyersSettings.existing.dates_selected &&
          !this.props.dataFlyersSettings.existing.dates_selected) ||
        (!prevProps.dataFlyersSettings.existing.dates_selected &&
          prevProps.dataFlyersSettings.existing.dates_selected)
      ) {
        this.resetAndLoadFeed();
      } else if (
        prevProps.dataUser &&
        prevProps.dataUser.cards_in_flyer_layout !==
          this.props.dataUser.cards_in_flyer_layout
      ) {
        this.setLayout();
      }
      if (
        this.props.page === "text" &&
        this.props.id &&
        prevProps.id !== this.props.id
      ) {
        this.initLandingPageFeedText();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.resetFlyers();
  }

  handleResize() {
    this.setLayout();
  }

  resetFlyers() {
    if (this.props.dataFlyers) {
      this.props.setData({ type: "FEED_FLYERS_DATA_RESET" });
    }
    if (this.props.isLanding) {
      this.props.setData({ type: "FEED_FLYERS_FILTERS_RESET" });
    }
  }

  initLandingPageFeedText() {
    this.props.setData({
      type: "SET_FLYERS_TEXT_FILTER_ON_LOAD",
      data: this.props.id,
    });
  }

  initLandingPageFeed() {
    if (this.props.dataLandingPageAbout || this.props.page === "category") {
      this.props.setData({
        type: `FLYER_SETTINGS_LP_${
          this.props.page === "product"
            ? "PRODUCT"
            : this.props.page === "category"
            ? "CATEGORY"
            : this.props.page === "country"
            ? "COUNTRY"
            : this.props.dataLandingPageAbout.is_brand
            ? "BRAND"
            : this.props.dataLandingPageAbout.is_retailer
            ? "RETAILER"
            : "BRAND"
        }`,
        data: [
          {
            id: this.props.dataLandingPageAbout.id,
            img: this.props.dataLandingPageAbout.img,
            name: this.props.dataLandingPageAbout.name,
            primary_color: this.props.dataLandingPageAbout.primary_color,
            fixed: true,
          },
        ],
      });
    }
  }

  resetAndLoadFeed() {
    window.scrollTo(0, 0);
    if (
      this.props.dataFlyersSettings.prefetchedData &&
      !this.props.fetchReducer.fetching &&
      this.props.fetchReducer.component !== "FILTERS_CHECK_RESULTS_COUNT_FLYERS"
    ) {
      this.props.setData({
        type: "APPLY_PREFETCHED_FLYERS",
        data: this.props.dataFlyersSettings.prefetchedData,
      });
    } else {
      if (this.props.dataFlyers) {
        this.props.setData({ type: "FEED_FLYERS_DATA_RESET" });
      }
      if (
        !this.props.isLanding ||
        queryStringToObject(this.props.router.location.search).filtered ||
        (this.props.isLanding && this.props.dataLandingPageAbout) ||
        (this.props.id && this.props.page === "text")
      ) {
        this.setState({ pageToLoad: 0 }, () => {
          this.loadFunc(0);
        });
      }
    }
  }

  setLayout() {
    const parentWidth =
      this.props.showSideMenu && this.wrapperRef.current
        ? this.wrapperRef.current.offsetWidth
        : window.innerWidth;
    this.setState({
      windowWidth: parentWidth,
      cardWidth: cardWidth(this.props.dataUser.cards_in_flyer_layout || 4),
      cardHeight: cardHeight(
        this.props.dataUser.cards_in_flyer_layout || 4,
        1.5
      ),
    });
  }

  loadMoreFlyers() {
    const numFlyersLoaded = this.props.dataFlyers?.data?.length || 0;
    this.loadFunc(Math.floor(numFlyersLoaded / numFlyersToLoad));
  }

  loadFunc(pageToLoad) {
    if (!this.props.dataUser) {
      return false;
    } else if (
      this.props.dataFlyers &&
      this.props.fetchReducer.fetching &&
      this.props.fetchReducer.component === "FEED_FLYERS"
    ) {
      return false;
    } else if (
      pageToLoad != 0 &&
      this.props.dataFlyers &&
      this.props.dataFlyers.data &&
      (this.props.dataFlyers.data.length < numFlyersToLoad ||
        this.props.dataFlyers.meta.count < numFlyersToLoad)
    ) {
      return false;
    }
    this.setState({ pageToLoad });
    let body = {
      user_id: this.props.dataUser.id,
      page: pageToLoad,
      front_page_only:
        this.props.dataFlyersSettings.new.front_page_only || false,
      sort: this.props.dataFlyersSettings.sort || "newest",
      country:
        this.props.dataFlyersSettings.new.country ||
        (this.props.dataFlyersSettings.new.countries_selected.filter(
          (x) => x.id
        ).length > 0 &&
          this.props.dataFlyersSettings.new.countries_selected.map(
            (x) => x.id
          )),
      filters: {
        countries:
          this.props.dataFlyersSettings.new.country ||
          this.props.dataFlyersSettings.new.countries_selected.map((x) => x.id),
        products:
          this.props.dataFlyersSettings.new.products_selected &&
          this.props.dataFlyersSettings.new.products_selected.map((x) => x.id),
        retailers:
          this.props.dataFlyersSettings.new.retailers_selected &&
          this.props.dataFlyersSettings.new.retailers_selected.map((x) => x.id),
        sources:
          this.props.dataFlyersSettings.new.retailers_selected &&
          this.props.dataFlyersSettings.new.retailers_selected
            .filter((x) => x.source_id)
            .map((x) => x.source_id),
        brands:
          this.props.dataFlyersSettings.new.brands_selected &&
          this.props.dataFlyersSettings.new.brands_selected.map((x) => x.id),
        categories:
          this.props.dataFlyersSettings.new.categories_selected &&
          this.props.dataFlyersSettings.new.categories_selected.map(
            (x) => x.id
          ),
        text: this.props.dataFlyersSettings.new.text_selected,
        dates: this.props.dataFlyersSettings.new.dates_selected,
      },
    };
    body.excluded_ids =
      pageToLoad === 0
        ? emptyArray
        : this.props.dataFlyers &&
          this.props.dataFlyers.data &&
          this.props.dataFlyers.data.map((x) => x.id);
    this.props.apiCall({
      method: "POST",
      component: "FEED_FLYERS",
      body: body,
      version: 2,
      route: "flyers",
    });
  }

  handleViewerOpen(i) {
    this.props.setData({
      type: "VIEWER_DATA_SET",
      data: {
        ...this.props.dataFlyers.data[i],
        companies: this.props.dataFlyers.data[i].tags.filter(
          (x) => x.type === "companies"
        ),
        categories: this.props.dataFlyers.data[i].tags.filter(
          (x) => x.type === "categories"
        ),
        tags: this.props.dataFlyers.data[i].tags.filter(
          (x) => x.type === "tags"
        ),
      },
    });
    this.props.setData({ type: "SHOW_VIEWER", data: { type: "FLYER" } });
  }

  cellRenderer({ columnIndex, key, rowIndex, style }, numColumns) {
    if (this.props.dataFlyers && this.props.dataFlyers.data) {
      const i = rowIndex * numColumns + columnIndex;
      if (
        ((this.props.dataFlyers.meta &&
          this.props.dataFlyers.meta.count < numFlyersToLoad) ||
          this.props.dataFlyers.data.length < numFlyersToLoad) &&
        this.props.dataFlyers.data.length == i
      ) {
        return null;
      }
      const innerGridWidth = (this.state.cardWidth * numColumns).toFixed(2);
      const headerWidth = (
        style.width
        - (innerGridWidth * 0.02) // Subtract 1% padding
        + (this.state.windowWidth <= 420 ? 4 : 0) // Add 4px for mobile CardSpacing padding
      );

      return (
        <CardSpacing
          key={`${key}-${rowIndex}-${columnIndex}-${i}-${
            this.props.dataFlyers.data[i] ? this.props.dataFlyers.data[i].id : i
          }`}
          style={style}
        >
          <Card
            index={i}
            selectEnabled
            isFlyer
            hidePageNum={this.props.dataUser.cards_in_flyer_layout === 5}
            headerWidth={headerWidth}
            data={this.props.dataFlyers.data[i]}
            handleViewerOpen={this.handleViewerOpen.bind(this, i)}
            hide={
              this.props.dataFlyers.data[i] && this.props.dataFlyers.data[i].id
                ? this.props.reportedFlyersList &&
                  this.props.reportedFlyersList.includes(
                    this.props.dataFlyers.data[i].id
                  )
                : false
            }
          />
        </CardSpacing>
      );
    }
  }

  _onSectionRendered(numColumns, positions) {
    const stopIndex =
      positions.rowStopIndex * numColumns + positions.columnStopIndex;
    if (this.props.dataFlyers.data) {
      if (
        !this.props.showViewer &&
        !(this.props.dataFlyers.meta.count < numFlyersToLoad) &&
        !(this.props.dataFlyers.data.length < numFlyersToLoad)
      ) {
        const rowsLeft = Math.floor(
          (this.props.dataFlyers.data.length - stopIndex) / numColumns
        );
        this.setState({
          rowsLeft,
        });
        if (
          rowsLeft <= 4 &&
          this.props.dataFlyers.data.length / numFlyersToLoad > 1
        ) {
          this.loadMoreFlyers();
        } else if (
          numColumns > 4 &&
          this.props.dataFlyers.data.length / numFlyersToLoad == 1
        ) {
          this.loadFunc(2);
        }
      }
    }
  }

  overscanIndicesGetter({
    direction, // One of "horizontal" or "vertical"
    cellCount, // Number of rows or columns in the current axis
    scrollDirection, // 1 (forwards) or -1 (backwards)
    overscanCellsCount, // Maximum number of cells to over-render in either direction
    startIndex, // Begin of range of visible cells
    stopIndex, // End of range of visible cells
  }) {
    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(
        cellCount - 1,
        stopIndex + overscanCellsCount
      ),
    };
  }

  _onScroll({
    clientHeight,
    clientWidth,
    scrollHeight,
    scrollLeft,
    scrollTop,
    scrollWidth,
  }) {
    const scrolledTo = scrollHeight - scrollTop;
    if (scrolledTo / scrollHeight < 0.45) {
      this.setState({
        scrolledTo,
      });
      this.loadMoreFlyers();
    }
  }

  render() {
    return (
      <Wrapper
        ref={this.wrapperRef}
        multiSelect={
          this.props.feedMultipleFlyerSelect &&
          this.props.feedMultipleFlyerSelect.data &&
          this.props.feedMultipleFlyerSelect.data.length > 0
        }
      >
        {(!this.props.dataFlyers &&
          this.props.fetchReducer.fetching &&
          this.props.fetchReducer.component === "FEED_FLYERS") ||
        (this.props.isLanding &&
          !this.props.dataLandingPageAbout &&
          this.props.page !== "text") ? (
          <SimpleSkeletonCard width="20%" mask pills={8} height="50vh" />
        ) : this.props.dataFlyers &&
          this.props.dataFlyers.meta.count == 0 &&
          (this.props.dataFlyers.page == 0 ||
            this.props.dataFlyers.data.length == 0) ? (
          <CircleGraphic
            text="No circulars found matching your filters"
            imgWidth="25em"
            src="web-app/flyer.png"
          />
        ) : this.props.dataFlyers &&
          this.props.dataFlyers.data &&
          this.props.dataFlyers.data.length > 0 ? (
          <AutoSizer className="autosizer">
            {({ height, width }) => (
              <>
                {this.state.windowWidth > this.state.cardWidth ? (
                  <Grid
                    className="flyerGrid"
                    id="flyerGrid"
                    key={`feedGrid-${
                      this.props.dataFlyers &&
                      this.props.dataFlyers.last_updated
                    }`}
                    overscanRowCount={4}
                    overscanIndicesGetter={this.overscanIndicesGetter}
                    cellRenderer={(props) =>
                      this.cellRenderer(
                        props,
                        Math.floor(
                          this.state.windowWidth / this.state.cardWidth
                        )
                      )
                    }
                    onScroll={this._onScroll}
                    columnCount={Math.floor(
                      this.state.windowWidth / this.state.cardWidth
                    )}
                    columnWidth={this.state.cardWidth}
                    height={height ? height + 30 : window.innerHeight}
                    onSectionRendered={this._onSectionRendered.bind(
                      this,
                      this.state.windowWidth
                        ? Math.floor(
                            this.state.windowWidth / this.state.cardWidth
                          )
                        : Math.floor(window.innerWidth / this.state.cardWidth)
                    )}
                    rowCount={
                      this.props.dataFlyers &&
                      this.props.dataFlyers.data &&
                      this.props.dataFlyers.data.length > 0 &&
                      width
                        ? Math.ceil(
                            this.props.dataFlyers.data.length /
                              Math.floor(
                                this.state.windowWidth / this.state.cardWidth
                              )
                          )
                        : 4
                    }
                    rowHeight={this.state.cardHeight}
                    width={
                      this.state.windowWidth
                        ? this.state.windowWidth
                        : window.innerWidth
                    }
                    style={styles.grid}
                  />
                ) : null}
                {this.state.rowsLeft === 0 &&
                this.props.dataFlyers &&
                this.props.dataFlyers.meta &&
                this.props.dataFlyers.meta.count === numFlyersToLoad &&
                !this.props.fetchReducer.fetching &&
                !this.props.feedCurrentlyFetching ? (
                  <LoadMore clickAction={this.loadMoreFlyers} />
                ) : null}
              </>
            )}
          </AutoSizer>
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchReducer: state.fetchReducer,
  dataFlyers: state.dataFlyers,
  showViewer: state.showViewer,
  dataFlyersSettings: state.dataFlyersSettings,
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataUser: state.dataUser,
  feedMultipleFlyerSelect: state.feedMultipleFlyerSelect,
  reportedFlyersList: state.reportedFlyersList,
  router: state.router,
  showSideMenu: state.showSideMenu,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(FlyerFeed);
