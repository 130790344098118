import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import { queryStringToObject } from "constants/functions/index";
import Card from "components/feed/cards/index";
import { withRouter } from "services/with-router/index";
import Loader from "components/loader/index";
import Logo from "components/logo/index";
import { companyWebsite } from "constants/variables/index";
import { connect } from "react-redux";

const CARD_WIDTH = 380;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  animation: fadeIn 1s;
  padding: 2em 0.5em;
  overflow-y: scroll;
  height: 100%;
`;
const P = styled.p`
  color: ${(props) => props.theme.grey2};
  font-weight: bold;
  margin: 0;
`;
const Cards = styled.div`
  padding-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const A = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    span {
      color: ${(props) => props.theme.deepRed};
    }
    svg {
      fill: ${(props) => props.theme.deepRed};
    }
  }
`;
const CardContainer = styled.div`
  height: 380px;
  width: ${CARD_WIDTH}px;
  max-width: 90vw;
  padding: 0.75em;
`;
const Header = styled.div`
  background: white;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  justify-content: space-between;
  padding: 0.5em 1em;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
`;
const Span = styled.span`
  font-weight: bold;
  transition: ${(props) => props.theme.defaultTransition};
  font-size: 0.8em;
  color: ${(props) => props.theme.grey3};
`;

class Embed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
    };
  }
  componentDidMount() {
    const urlParams = queryStringToObject(this.props.router.location.search)
    this.props.apiCall({
      method: "GET",
      component: "FEED_PHOTOS_EMBED",
      route: `embed/${this.props.params.pageType}/${this.props.params.pageId}`,
      params: urlParams
    });
    this.setState({
      title: urlParams.title ? decodeURIComponent(urlParams.title) : "Retail Photos",
    });
  }
  render() {
    if (
      this.props.fetchReducer &&
      this.props.fetchReducer.fetching &&
      this.props.fetchReducer.component === "FEED_PHOTOS_EMBED"
    ) {
      return (
        <Wrapper>
          <Logo fill="grey" />
          <Loader />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Header>
          <P>{this.state.title}</P>
          <A href={companyWebsite} target="_blank" rel="noreferrer noopener">
            <Span>Powered by</Span>
            <Logo height="1.5em" marginTop="0" fill={this.props.theme.grey3} />
          </A>
        </Header>
        <Cards>
          {this.props.dataEmbed ? (
            this.props.dataEmbed.length === 0 ? (
              <P
                style={{
                  padding: "2em",
                }}
              >
                Sorry, there are no matching photos
              </P>
            ) : (
              <>
                {this.props.dataEmbed.map((x, i) => (
                  <CardContainer key={`embed-card-${x.id}-${i}`}>
                    <Card
                      data={x}
                      headerWidth={CARD_WIDTH}
                      embedded
                      showStoreNumber
                      showCity
                      showCountry
                      showDownloadButton
                    />
                  </CardContainer>
                ))}
              </>
            )
          ) : (
            <Loader />
          )}
        </Cards>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataEmbed: state.dataEmbed,
  router: state.router,
  fetchReducer: state.fetchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withRouter(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(Embed))
);
