import { PureComponent } from "react";
import { FaTimes } from "react-icons/fa";

import { media } from "constants/responsive/index";
import styled, { withTheme } from "styled-components";
import { textColor, toPastel } from "constants/functions/index";

const Li = styled.li`
  list-style: none;
  max-width: 21em;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.45em;
  padding: 0.5em 0.75em;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 1em;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.deletePending ? props.theme.midRed : props.theme.grey6};
  opacity: ${(props) => (props.selected ? "1" : "0.3")};
  transform: ${(props) => (props.selected ? "scale(1.05)" : "none")};
  transition: ${(props) => props.theme.fastTransition};
  color: ${(props) => (props.color ? props.color : props.theme.grey2)};
  font-weight: bold;
  .fadedImage {
    transition: ${(props) => props.theme.fastTransition};
    opacity: 0.6;
  }
  svg {
    font-size: 0.8em;
    margin-right: 0.5em;
  }
  &:hover {
    color: ${(props) =>
      props.deletePending ? props.theme.deepRed : props.theme.grey1};
    box-shadow: ${(props) => props.theme.subtleBoxShadow};
    .fadedImage {
      opacity: 0.9;
    }
  }
  &:active {
    transform: scale(1);
  }
`;
const DeleteButton = styled.div`
  padding-left: 0.5em;
  svg {
    fill: ${(props) => props.theme.midRed};
  }
`;

class SimplePill extends PureComponent {
  render() {
    return (
      <Li
        onClick={this.props.clickAction}
        selected={this.props.highlighted}
        deletePending={this.props.deletePending}
        color={
          this.props.deletePending
            ? this.props.theme.midRed
            : this.props.textColor
            ? this.props.textColor
            : this.props.primary_color
            ? this.props.pastel
              ? this.props.theme.grey1
              : textColor(this.props.primary_color)
            : this.props.theme.grey2
        }
        style={{
          backgroundColor: this.props.deletePending
            ? this.props.theme.white
            : this.props.primary_color
            ? `rgba${
                this.props.pastel
                  ? toPastel(this.props.primary_color, 50, 0.3)
                  : this.props.primary_color
              }`
            : this.props.theme.grey6,
        }}
      >
        {this.props.icon && this.props.icon}
        {this.props.name}
        {this.props.deletePending && (
          <DeleteButton>
            <FaTimes />
          </DeleteButton>
        )}
      </Li>
    );
  }
}

export default withTheme(SimplePill);
