import styled from "styled-components";

const CardBody = styled.div`
  display: flex;
  position: relative;
  background-color: ${(props) => props.backgroundColor || props.theme.grey4};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 70px;
  margin-right: 1em;
  opacity: ${(props) => (props.animate ? 0 : 1)};
  align-items: center;
  justify-content: center;
`;

const CardBottom = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  height: 20px;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  position: absolute;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  background-image: url(${(props) => props.icon});
  background-size: cover;
  background-position: center;
  margin-bottom: 1em;
`;

const CardText = styled.div`
  margin-left: 1em;

  position: relative;
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.6em;
  font-weight: 600;
  color: ${(props) => props.theme.grey2};
`;

const truncateText = (text = "", maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

const FauxCard = ({ backgroundIcon, name, primaryColor }) => {
  return (
    <CardBody backgroundColor={primaryColor}>
      <IconContainer icon={backgroundIcon} />
      <CardBottom>
        <CardText>{truncateText(name, 17)}</CardText>
      </CardBottom>
    </CardBody>
  );
};

export default FauxCard;
