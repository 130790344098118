import { PureComponent } from "react";

import Option from "./option/index";
import { connect } from "react-redux";
import {
  urlQueryToSearch,
  queryStringToObject,
} from "constants/functions/index";
import { media } from "constants/responsive/index";
import styled from "styled-components";
import { history } from "@redux/store";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.default ? "none" : props.theme.grey6)};
  ${media.mobile`
    flex-wrap: wrap;
  `};
`;

class FeedLayoutSettings extends PureComponent {
  selectLayout(layout) {
    this.props.setData({
      type: `USER_SET_${this.props.isFlyer ? "FLYER" : "FEED"}_LAYOUT`,
      data: layout,
    });
    const currentSearch =
      queryStringToObject(this.props.router.location.search) || {};
    currentSearch.layout = layout;
    history.replace({
      pathname: this.props.router.location.pathname,
      search: urlQueryToSearch(currentSearch),
      state: {},
    });
    if (
      this.props.isFlyer &&
      this.props.dataUser &&
      this.props.dataUser.cards_in_flyer_layout !== layout
    ) {
      this.props.apiCall({
        method: "POST",
        component: "USER_SET_FLYER_LAYOUT_PREFERENCE",
        route: "user/layout_flyer",
        body: {
          user_id: this.props.dataUser.id,
          layout: layout,
        },
      });
    } else if (
      this.props.dataUser &&
      this.props.dataUser.cards_in_layout !== layout
    ) {
      this.props.apiCall({
        method: "POST",
        component: "USER_SET_FLYER_LAYOUT_PREFERENCE",
        route: "user/layout",
        body: {
          user_id: this.props.dataUser.id,
          layout: layout,
        },
      });
    }
  }

  render() {
    return (
      <Wrapper
        default={this.props.default}
        style={{
          padding: this.props.default ? 0 : "1em",
        }}
      >
        <Option
          size={4}
          cards={6}
          title={`${this.props.translations["53"] || "Fit"} 2 ${
            this.props.translations["54"] || "cards"
          }`}
          selectLayout={this.selectLayout.bind(this, 2)}
          active={
            this.props.isFlyer
              ? this.props.dataUser &&
                this.props.dataUser.cards_in_flyer_layout === 2
              : this.props.dataUser && this.props.dataUser.cards_in_layout === 2
          }
        />
        <Option
          size={3}
          cards={24}
          title={`${this.props.translations["53"] || "Fit"} 3 ${
            this.props.translations["54"] || "cards"
          }`}
          active={
            this.props.isFlyer
              ? this.props.dataUser &&
                this.props.dataUser.cards_in_flyer_layout === 3
              : this.props.dataUser && this.props.dataUser.cards_in_layout === 3
          }
          selectLayout={this.selectLayout.bind(this, 3)}
        />
        <Option
          size={2}
          cards={24}
          title={`${this.props.translations["53"] || "Fit"} 4 ${
            this.props.translations["54"] || "cards"
          }`}
          active={
            this.props.isFlyer
              ? (this.props.dataUser &&
                  !this.props.dataUser.cards_in_flyer_layout) ||
                (this.props.dataUser &&
                  this.props.dataUser.cards_in_flyer_layout === 4)
              : (this.props.dataUser && !this.props.dataUser.cards_in_layout) ||
                (this.props.dataUser &&
                  this.props.dataUser.cards_in_layout === 4)
          }
          selectLayout={this.selectLayout.bind(this, 4)}
        />
        <Option
          size={1.75}
          cards={24}
          title={`${this.props.translations["53"] || "Fit"} 5 ${
            this.props.translations["54"] || "cards"
          }`}
          active={
            this.props.isFlyer
              ? this.props.dataUser &&
                this.props.dataUser.cards_in_flyer_layout === 5
              : this.props.dataUser && this.props.dataUser.cards_in_layout === 5
          }
          selectLayout={this.selectLayout.bind(this, 5)}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  translations: state.translations,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedLayoutSettings);
