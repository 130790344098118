import { PureComponent } from "react";
import {
  toPastel,
  queryStringToObject,
  replaceLastSlug,
} from "constants/functions/index";
import { Container } from "constants/styled-components/index";
import FeedSettings from "components/feed/settings/index";
import { Link } from "react-router-dom";
import Loader from "components/loader/index";
import PlanogramSettings from "components/planogram/settings/index";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled, { withTheme } from "styled-components";

const Wrapper = styled.div`
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  left: 0;
  right: 0;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  transition: ${(props) => props.theme.fastTransition};
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  animation: fadeIn 0.5s;
  ${media.tablet`
    font-size: 0.9em;
  `};
  ${media.mobile`
    font-size: 0.8em;
    .menuOptionSpacer {
      align-self: center;
    }
  `};
`;
const Placeholder = styled.div`
  border-radius: 2em;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  width: ${(props) => (props.width ? props.width : "5em")};
  font-size: 0.7em;
  padding: 1.74em;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  margin: 0.5em;
  height: 1.5em;
`;
const SubWrapper = styled.div`
  background: ${(props) =>
    props.primaryColor ? props.primaryColor : props.theme.grey3};
  transition: ${(props) => props.theme.fastTransition};
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  width: 100%;
  pointer-events: all;
  font-size: ${(props) => (props.showHeader ? "0.8em" : "0.7em")};
`;
const Links = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
const ColorContainer = styled.div`
  background: ${(props) => `linear-gradient(
    30deg,
    ${props.primaryColor},
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1),
    ${props.primaryColor}
  )`};
  min-height: 3.5em;
  padding: 0.75em;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
  flex-wrap: wrap;
  ${media.mobile`
    justify-content: center;
  `};
`;

const closeWhitelist = ["page-menu", "group-1", "group-2", "group-3"];

const styles = {
  loader: {
    position: "absolute",
    right: "1em",
  },
};

class PageMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick(e) {
    if (e && e.target && closeWhitelist.includes(e.target.id)) {
      this.props.setData({
        type: "HIDE_FEED_DROPDOWN_MENUS",
      });
    }
  }

  render() {
    const starred =
      queryStringToObject(this.props.router.location.search).starred &&
      decodeURIComponent(
        queryStringToObject(this.props.router.location.search).starred
      );
    return (
      <Wrapper
        fixed={this.props.fixed}
        id="page-menu"
        onClick={this.handleMenuClick}
      >
        <SubWrapper
          showHeader={this.props.showHeader}
          primaryColor={
            starred ? this.props.theme.midYellowAccent : this.props.primaryColor
          }
          id="group-1"
          onClick={this.handleMenuClick}
        >
          <ColorContainer
            primaryColor={
              starred
                ? this.props.theme.midYellowAccent
                : this.props.backgroundColor || "rgba(0,0,0,0.3)"
            }
            id="group-2"
            justifyContent={this.props.justifyContent}
            onClick={this.handleMenuClick}
          >
            {((this.props.dataLandingPageAbout &&
              this.props.dataLandingPageAbout.name) ||
              this.props.page === "text" ||
              this.props.page === "related") &&
            !this.props.showHeader ? (
              <>
                {this.props.activeTab === "displays" ||
                this.props.activeTab === "feed" ||
                this.props.activeTab === "circulars" ||
                this.props.activeTab.includes("custom_view") ? (
                  <FeedSettings
                    name={starred ? starred : this.props.name}
                    activeTab={this.props.activeTab}
                    img={starred ? null : this.props.img}
                    page={this.props.page}
                    isFlyer={this.props.activeTab === "circulars"}
                    menuPages={starred ? null : this.props.menuPages}
                    menuType={this.props.menuType}
                    type={this.props.type}
                    primaryColor={
                      starred
                        ? this.props.theme.midYellowAccent
                        : this.props.dataLandingPageAbout &&
                          this.props.dataLandingPageAbout.primary_color
                        ? `rgba${toPastel(
                            this.props.dataLandingPageAbout.primary_color,
                            30,
                            1
                          )}`
                        : this.props.page === "text" ||
                          this.props.page === "related"
                        ? "rgb(200,100,100)"
                        : null
                    }
                  />
                ) : (
                  <PlanogramSettings
                    name={this.props.name}
                    img={this.props.img}
                    page={this.props.page}
                    tab={this.props.activeTab}
                    isAnalytics={this.props.activeTab === "analytics"}
                    subTab={this.props.subTab}
                    subTab2={this.props.subTab2}
                    isPricing={this.props.activeTab === "pricing"}
                    isPlanogram={this.props.activeTab === "planogram"}
                    isLocations={this.props.activeTab === "locations"}
                    menuPages={this.props.menuPages}
                    menuType={this.props.menuType}
                    type={this.props.type}
                    primaryColor={
                      this.props.dataLandingPageAbout &&
                      this.props.dataLandingPageAbout.primary_color
                        ? `rgba${toPastel(
                            this.props.dataLandingPageAbout.primary_color,
                            30,
                            1
                          )}`
                        : this.props.page === "text" ||
                          this.props.page === "related"
                        ? "rgb(200,100,100)"
                        : null
                    }
                  />
                )}
              </>
            ) : this.props.menuPages && this.props.menuPages.length > 0 ? (
              <Links>
                {this.props.menuPages.map((x, i) => (
                  <Link
                    style={{
                      animation: `fadeIn ${(i + 0.5) / 2}s`,
                    }}
                    className="menuOptionSpacer"
                    key={`${i}-${x}`}
                    to={
                      replaceLastSlug(
                        this.props.router.location.pathname,
                        x.slug
                      ) || "/"
                    }
                  >
                    <Container
                      active={this.props.activeTab === (x.page || x.slug)}
                    >
                      {x.trans ? this.props.translations[x.trans] : x.name}
                    </Container>
                  </Link>
                ))}
              </Links>
            ) : this.props.showPills ? (
              <>
                <Links>
                  <Placeholder width="20em" />
                  <Placeholder width="8em" />
                  <Placeholder width="1.5em" />
                </Links>
                <Links>
                  <Placeholder width="8em" />
                </Links>
              </>
            ) : (
              <>
                <Container />
                <Container />
              </>
            )}
            {!this.props.dataLandingPageAnalytics &&
              this.props.router.location.pathname.includes(
                "/private_collection/"
              ) && (
                <div style={styles.loader}>
                  <Loader
                    width="auto"
                    noMargin
                    color="white"
                    padding="0 1em"
                    size="2em"
                  />
                </div>
              )}
            {this.props.component ? this.props.component : null}
          </ColorContainer>
        </SubWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
  router: state.router,
  showHeader: state.showHeader,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(PageMenu)
);
