import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";

import { FaInfoCircle } from "react-icons/fa";
import ToggleSwitch from "components/toggle-switch/index";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.noPadding ? 0 : "0 1em")};
  .react-switch-bg {
    background: ${(props) =>
      props.inMenu ? "rgba(0,0,0,0.35) !important" : "auto"};
  }
  ${media.mobile`
    padding: 1em;
  `}
`;
const Text = styled.div`
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.active
      ? props.theme.grey1
      : props.theme.grey3};
  transition: ${(props) => props.theme.fastTransition};
  padding: 0 0.5em;
  font-weight: bold;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};
`;

class ToggleTwoOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
  }

  handleToggleSwitch(off) {
    if (this.props.onActionConstant && this.props.offActionConstant) {
      if (off) {
        this.props.setData({ type: this.props.onActionConstant });
      } else {
        this.props.setData({ type: this.props.offActionConstant });
      }
    }
    if (this.props.clickAction) {
      this.props.clickAction(off);
    }
  }

  render() {
    return this.props.option1 && this.props.option2 ? (
      <Wrapper noPadding={this.props.noPadding} inMenu={this.props.inMenu}>
        {this.props.tooltip1 ? (
          <Tooltip
            unmountHTMLWhenHide
            html={<TooltipText>{this.props.tooltip1}</TooltipText>}
            position="top"
            width="auto"
          >
            <FaInfoCircle color={this.props.theme.grey3} />
          </Tooltip>
        ) : null}
        <Text
          active={!this.props.toggledOn}
          textColor={this.props.textColor}
          minWidth={this.props.minWidth}
        >
          {this.props.option1}
        </Text>
        <ToggleSwitch
          toggledOn={this.props.toggledOn}
          clickAction={this.handleToggleSwitch}
          onColor={this.props.theme.grey3}
          offColor={this.props.theme.grey3}
          checkedIcon={false}
          uncheckedIcon={false}
          margin="0 0.5em"
          height={this.props.height}
          width={this.props.width}
        />
        <Text
          active={this.props.toggledOn}
          textColor={this.props.textColor}
          minWidth={this.props.minWidth}
        >
          {this.props.option2}
        </Text>
        {this.props.tooltip2 ? (
          <Tooltip
            unmountHTMLWhenHide
            html={<TooltipText>{this.props.tooltip2}</TooltipText>}
            position="top"
            width="auto"
          >
            <FaInfoCircle color={this.props.theme.grey3} />
          </Tooltip>
        ) : null}
      </Wrapper>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type }),
});

export default withTheme(connect(null, mapDispatchToProps)(ToggleTwoOptions));
