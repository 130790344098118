import {
  appPrefix,
  awsPhotosPrefixS3,
  pptxFooterObjects,
  awsPrefixS3,
  presentationTypeToLayout,
  POWERPOINT_CONSTANTS,
} from "constants/variables/index";
import {
  loadImageDetails,
  powerpointtProcessImage,
  powerpointtCalculateStartingX,
} from "constants/functions/index";
import moment from "moment";
import PptxGenJS from "pptxgenjs";
import toast from "react-hot-toast";

export async function renderCard({
  highResPreference,
  pres,
  slide,
  x,
  y,
  retailer,
  geography,
  timestamp,
  logo,
  img,
  sizePercent,
  storeNum,
  borderColor,
}) {
  if (!slide) {
    console.error("The slide object is not defined!");
    return;
  }
  if (logo) {
    const baseOptions = {
      x,
      y,
      h: 3.9 * sizePercent,
      w: 3 * sizePercent,
      fill: "FFFFFF",
    };
    slide.addShape(pres.ShapeType.rect, {
      ...baseOptions,
      line: { color: borderColor ? borderColor : "e1e8ee" },
      shadow: {
        type: "outer",
        opacity: 0.1,
        blur: 20,
        offset: 10,
        angle: 45,
      },
    });
    const imgPath = `${awsPrefixS3}companies/${logo}`;
    await powerpointtProcessImage(imgPath, slide, x, y, sizePercent);
  }
  const imagePath = highResPreference
    ? `/image_proxy?url=${awsPhotosPrefixS3}photos/${img}`
    : `/image_proxy?url=${awsPhotosPrefixS3}compressed/${img}-M`;
  slide.addImage({
    path: imagePath,
    x: x + 0.01,
    y: y + 0.4 + 0.1 * sizePercent,
    sizing: {
      type: "cover",
      h: 3.4 * sizePercent,
      w: 2.98 * sizePercent,
    },
  });
  if (retailer) {
    const baseTextOptions = {
      x: x + 0.4 + 0.05 * sizePercent,
      w: 2.6 * sizePercent,
      fontFace: POWERPOINT_CONSTANTS.GLOBAL_FONT,
      color: "43484D",
      bold: true,
    };
    slide.addText(retailer, {
      ...baseTextOptions,
      y: geography ? y : y + 0.1,
      h: 0.4 * sizePercent,
      fontSize: retailer.length < 13 ? 12 * sizePercent : 8 * sizePercent,
    });
  }
  if (geography) {
    const geoText = storeNum ? `Store #${storeNum} - ${geography}` : geography;
    slide.addText(geoText, {
      x: x + 0.4 + 0.05 * sizePercent,
      y: y + 0.15 + 0.03 * sizePercent,
      h: 0.4 * sizePercent,
      w: 2.5 * sizePercent,
      fontFace: POWERPOINT_CONSTANTS.GLOBAL_FONT,
      fontSize: 9 * sizePercent,
      color: "86939E",
    });
  }
  if (timestamp) {
    slide.addText(timestamp, {
      align: "right",
      x: x,
      y: y + 0.01,
      w: 3 * sizePercent,
      h: 0.4 * sizePercent,
      fontFace: POWERPOINT_CONSTANTS.GLOBAL_FONT,
      fontSize: 9 * sizePercent,
      color: "5E6977",
    });
  }
  slide.addShape(pres.ShapeType.rect, {
    x: x,
    y: y,
    h: 4.15 * sizePercent,
    w: 3 * sizePercent,
    transparency: 100,
    hyperlink: { url: `${appPrefix}s/${img}` },
  });
}

export async function generatePowerpoint(
  feedData,
  presentationType,
  presentationName,
  highResPreference,
  companyInfo,
  logoPreference
) {
  toast.loading("Downloading...");
  const isFlyer = presentationType === "flyers";
  // 1. Create a new Presentation
  let pres = new PptxGenJS();
  pres.layout = "LAYOUT_16x9";
  pres.company = "www.shelfgram.com";

  // Clone pptxFooterObjects to avoid modifying the original array
  let objects = [...pptxFooterObjects];

  const imgPath =
    logoPreference && companyInfo && companyInfo.img
      ? `${awsPrefixS3}companies/${companyInfo.img}`
      : `${awsPrefixS3}logo/Shelfgram_New@2x.png`;
  const imageLink =
    logoPreference && companyInfo && companyInfo.img
      ? `${appPrefix}${
          companyInfo.login_slug ? `e/${companyInfo.login_slug}` : ""
        }`
      : appPrefix;
  const imageData = await loadImageDetails(imgPath);
  let slideHeight = 0.3;
  let slideWidth = (slideHeight * imageData.width) / imageData.height;
  if (slideWidth > 0.6) {
    slideWidth = 0.6;
    slideHeight = (slideWidth * imageData.height) / imageData.width;
  }
  let xPosition = 9.95 - slideWidth;
  let yPosition = 5.45 - slideHeight / 2;
  objects.push({
    image: {
      x: xPosition,
      y: yPosition,
      w: slideWidth,
      h: slideHeight,
      data: imageData.base64,
      hyperlink: {
        url: imageLink,
      },
    },
  });
  if (presentationType === "photos" || isFlyer) {
    objects.push({
      placeholder: {
        options: {
          name: "body",
          type: "body",
          fontFace: POWERPOINT_CONSTANTS.GLOBAL_FONT,
          fontSize: 12,
          x: 6,
          y: 1,
          w: 3.5,
          h: 3.5,
        },
        text: "Placeholder for your notes",
      },
    });
    pres.defineSlideMaster({
      title: "MASTER_SLIDE_WITH_NOTES",
      objects: objects,
    });
    for (let item of feedData) {
      const slide = pres.addSlide("MASTER_SLIDE_WITH_NOTES");
      const photoOptions = {
        x: 0.25,
        y: 0.3,
        w: item.photo_width
          ? (item.photo_width / item.photo_height) * 4.75
          : 3.56,
        h: 4.75,
        path: `/image_proxy?url=${awsPhotosPrefixS3}${presentationType}/${item.img}`,
        hyperlink: {
          url: `${appPrefix}${isFlyer ? "f" : "s"}/${item.img}`,
          tooltip: "More info",
        },
        shadow: {
          type: "outer",
          angle: 45,
          blur: 5,
          offset: 5,
          color: "bdc6cf",
          opacity: 0.7,
        },
      };
      slide.addImage(photoOptions);

      if (item.retailer) {
        // 5. Add footer
        const footerText = `* ${isFlyer ? "Flyer" : "Photo taken at"} ${
          item.retailer
        }${item.address ? `, ${item.address}` : ""}${
          item.store_num ? ` - Store #${item.store_num}` : ""
        }${
          isFlyer
            ? ` - ${moment(item.start_date).format("MMM D, YYYY")} to ${moment(
                item.end_date
              ).format("MMM D, YYYY")}`
            : `${
                item.city ? ` - ${item.city}, ${item.country}` : ""
              } on ${moment(item.date_taken).format("MMM D, YYYY")}.`
        }`;
        const footerOpts = {
          x: 0,
          y: 5.255,
          w: 10,
          h: 0.4,
          inset: 0.1,
          color: "FFFFFF",
          align: "left",
          fontFace: POWERPOINT_CONSTANTS.GLOBAL_FONT,
          fontSize: 8,
          rectRadius: 2,
          valign: "middle",
        };
        slide.addText(footerText, footerOpts);
      }
      // 6. Add notes
      slide.addNotes(
        `You can find this ${
          isFlyer ? "flyer" : "photo"
        } on Shelfgram at ${appPrefix}${isFlyer ? "f" : "s"}/${item.img}`
      );
    }
  } else if (feedData) {
    pres.defineSlideMaster({
      title: "MASTER_SLIDE_CARDS",
      objects: objects,
    });
    pres.defineSlideMaster({
      title: "MASTER_SLIDE_GRID",
      background: { color: "E1E8EE" },
      objects: [],
    });
    objects.push({
      placeholder: {
        options: {
          name: "body",
          type: "body",
          fontFace: POWERPOINT_CONSTANTS.GLOBAL_FONT,
          align: "center",
          valign: "middle",
          bold: true,
          color: "43484d",
          fontSize: 16,
          x: 1,
          y: 0.5,
          w: 7.75,
          h: 0.5,
        },
        text: "Placeholder for title",
      },
    });
    pres.defineSlideMaster({
      title: "MASTER_SLIDE_WITH_TITLE",
      objects: objects,
    });
    const cardsPerSlide = presentationTypeToLayout[presentationType].cards;
    const sizePercent = presentationTypeToLayout[presentationType].size;
    const yOffset = presentationTypeToLayout[presentationType].yOffset;
    const masterName = presentationTypeToLayout[presentationType].masterName;
    let slideNotes =
      "All photos on this slide are hyperlinked to Shelfgram. You can click on a photo during presentation mode to open it up. Here's the list of photos included on this slide:\n\n";
    const slidesArr = [...feedData];
    const chunks = [];
    while (slidesArr.length) {
      chunks.push(slidesArr.splice(0, cardsPerSlide));
    }
    for (const row of chunks) {
      const slide = pres.addSlide(masterName);
      let imageLinks = "";
      for (let i = 0; i < row.length; i++) {
        let item = row[i];
        imageLinks += `${appPrefix}s/${item.img}\n`;
        let xIndex = i > 4 ? i - 5 : i;
        await renderCard({
          highResPreference: highResPreference,
          pres: pres,
          slide: slide,
          x:
            (POWERPOINT_CONSTANTS.CARD_SPACING +
              xIndex * POWERPOINT_CONSTANTS.CARD_WIDTH_FACTOR) *
              sizePercent +
            powerpointtCalculateStartingX(row.length, sizePercent),
          y: i > 4 ? 0.75 + yOffset + 4.35 * sizePercent : 0.75 + yOffset,
          retailer: item.retailer,
          geography:
            item.city && item.country
              ? `${item.city}, ${
                  item.country.length < 13 ? item.country : item.country_acronym
                }`
              : null,
          timestamp:
            item.date_taken || item.timestamp
              ? moment(item.date_taken || item.timestamp).format("MMM D, YYYY")
              : null,
          logo: item.retailer_img,
          img: item.img,
          sizePercent: sizePercent,
          storeNum: item.store_num,
        });
      }
      slide.addNotes(slideNotes + imageLinks);
    }
  }

  // 7. Save the Presentation
  var currentdate = new Date().toDateString();
  await pres.writeFile({
    fileName: `${
      presentationName ? presentationName : "Retail Photo Report"
    } - ${currentdate}.pptx`,
  });
  toast.dismiss();
}
