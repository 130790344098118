import { PureComponent } from "react";

import { Link } from "react-router-dom";
import MainCTA from "components/buttons/main/index";
import styled from "styled-components";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  padding: 2em;
  text-align: center;
`;
const H1 = styled.h1`
  display: block;
`;
const P = styled.p`
  padding: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.grey2};
  max-width: 600px;
  margin: auto;
`;

class NothingYet extends PureComponent {
  render() {
    return (
      <Wrapper>
        {this.props.iconComponent ? this.props.iconComponent : null}
        <H1>{this.props.title}</H1>
        <P>{this.props.bodyText}</P>
        {this.props.noCta ? null : (
          <Link
            to={
              typeof this.props.ctaLink !== "undefined"
                ? this.props.ctaLink
                : "#"
            }
          >
            <MainCTA
              text={this.props.ctaText}
              clickAction={this.props.clickAction}
            />
          </Link>
        )}
      </Wrapper>
    );
  }
}

export default NothingYet;
