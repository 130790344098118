var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useAppSelector } from "@redux/hooks";
import { asyncSelectors, emptyArray } from "constants/variables";
import { emptyCuratedCollectionArray, selectCuratedCollectionsByTypeAndCountry } from "@redux/slices/curatedCollectionsSlice";
import { selectPendingQuestionCodeFilters } from "@redux/slices/adminPortalSlice";
import { getImageSource } from "shared/helpers/image";
import { Option } from "constants/styled-components";
import Selector from "components/selector";
var selectQuestionCodeCollections = function (state, linkType, countryId) {
    return linkType === "collections"
        ? selectCuratedCollectionsByTypeAndCountry(state, "curated", countryId)
        : emptyCuratedCollectionArray;
};
export var LinkSelector = function (_a) {
    var title = _a.title, linkType = _a.linkType, selectorType = _a.selectorType, countryId = _a.countryId, isFilter = _a.isFilter, onSelect = _a.onSelect, handleCreatePipeline = _a.handleCreatePipeline;
    var availableCollections = useAppSelector(function (state) { return selectQuestionCodeCollections(state, linkType, countryId); });
    var pendingFilters = useAppSelector(function (state) { return !!isFilter && selectPendingQuestionCodeFilters(state)[linkType]; });
    var isAsync = asyncSelectors.includes(selectorType);
    var selectorOptions = useMemo(function () {
        return availableCollections.map(function (collection) { return (__assign(__assign({}, collection), { value: collection.label, label: (_jsxs(Option, { children: [_jsx("img", { src: getImageSource(collection), alt: collection.label }), collection.label] })) })); });
    }, [availableCollections]);
    var currentFilterOptions = useMemo(function () {
        if (!pendingFilters)
            return isFilter && emptyArray;
        return Object.values(pendingFilters).map(function (item) { return (__assign(__assign({}, item), { value: item.name, label: (_jsxs(Option, { children: [_jsx("img", { src: getImageSource(item), alt: item.name }), item.name] })) })); });
    }, [pendingFilters]);
    return (_jsx(Selector, { async: isAsync, tagging: !isFilter, selectorType: selectorType, creatable: linkType === "collections", portal: !linkType.includes("collections"), clickAction: function (e) { return onSelect(e.data); }, hideSelectorType: linkType.includes("collections"), placeholder: "Select ".concat(title.toLowerCase(), "..."), handleCreateOption: handleCreatePipeline, defaultValue: isAsync && currentFilterOptions, value: currentFilterOptions, options: selectorOptions, minWidth: "100%" }));
};
