import { Container, Group } from "constants/styled-components/index";
import { PureComponent } from "react";
import NumberIndicator from "components/number-indicator/index";
import StarButton from "components/buttons/star/index";
import { FaFilter } from "react-icons/fa";
import { connect } from "react-redux";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";

const styles = {
  filters: { justifyContent: "flex-end" },
};

class PlanogramFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.openPlanogramFilters = this.openPlanogramFilters.bind(this);
  }
  openPlanogramFilters() {
    this.props.showModal({
      component: Modals.PLANOGRAM_FILTERS,
    });
  }
  render() {
    const existingFilters =
      this.props.dataLandingPageRVISettings &&
      this.props.dataLandingPageRVISettings.planogram_checks &&
      this.props.dataLandingPageRVISettings.planogram_checks.existing;
    const collectionsFiltered =
      existingFilters &&
      existingFilters.collections &&
      existingFilters.collections.length > 0
        ? 1
        : 0;
    const retailersFiltered =
      existingFilters &&
      existingFilters.retailers &&
      existingFilters.retailers.length > 0
        ? 1
        : 0;
    const brandsFiltered =
      existingFilters &&
      existingFilters.brands &&
      existingFilters.brands.length > 0
        ? 1
        : 0;
    const statesFiltered =
      existingFilters &&
      existingFilters.states &&
      existingFilters.states.length > 0
        ? 1
        : 0;
    const datesFiltered = existingFilters && existingFilters.dates ? 1 : 0;
    const numFilters =
      collectionsFiltered +
      retailersFiltered +
      statesFiltered +
      datesFiltered +
      brandsFiltered;
    if (!this.props.dataLandingPageRVI) {
      return null;
    }
    return (
      <Group style={styles.filters}>
        <Container onClick={this.openPlanogramFilters}>
          <FaFilter />
          Filter
          {numFilters > 0 ? <NumberIndicator number={numFilters} /> : null}
        </Container>
        <StarButton />
      </Group>
    );
  }
}

const mapStateToProps = (state) => ({
  dataLandingPageRVI: state.dataLandingPageRVI,
  dataLandingPageRVISettings: state.dataLandingPageRVISettings,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramFilters);
