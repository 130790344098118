import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import moment from "moment";
import { appPrefix, awsPhotosPrefix } from "constants/variables/index";
import { excelExport } from "services/download-excel/index";
import { connect } from "react-redux";
import { PureComponent } from "react";
import { FaFileExcel } from "react-icons/fa";
import { Container, Group } from "constants/styled-components/index";

const styles = {
  flexEnd: { justifyContent: "flex-end" },
};

class ExcelExportButton extends PureComponent {
  constructor(props) {
    super(props);
    this.handleExcelPricing = this.handleExcelPricing.bind(this);
    this.handleExcelFacings = this.handleExcelFacings.bind(this);
  }
  hideFeedDropdowns() {
    this.props.setData({
      type: "HIDE_FEED_DROPDOWN_MENUS",
    });
  }
  handleExcelPricing() {
    this.hideFeedDropdowns();
    if (this.props.data) {
      excelExport(
        [
          {
            name: "Product Pricing - Costco",
            data: this.props.data.map((x) => ({
              Date_Seen: `${moment(x.date_taken).format("MMM D, YYYY")}`,
              Time: `${moment(x.date_taken).format("h:mm a")}`,
              Country: x.country,
              State: x.state,
              City: x.city,
              Retailer: "Costco",
              Address: x.location_address,
              "Store Number": x.location_store_num
                ? `#${x.location_store_num}`
                : null,
              Demography: x.demography,
              Latitude: x.location_lat,
              Longitude: x.location_long,
              Planogram_Position: x.is_display ? 1 : x.position_from_left,
              Planogram_Size: x.is_display ? 1 : x.set_size,
              Name: {
                text: x.sku_name,
                hyperlink: `${appPrefix}lp/product/${x.sku_slug}/pricing`,
              },
              Brand: x.sku_brand,
              Type: x.is_display ? "Display" : "Main section",
              "Item Description": x.sku_summary,
              Price: x.price,
              Image: {
                text: "Price Reference",
                hyperlink: `${awsPhotosPrefix}thumbnails/${x.img_price}`,
              },
              Photo: {
                text: `${appPrefix}s/${x.img_photo}`,
                hyperlink: `${appPrefix}s/${x.img_photo}`,
              },
              First_Seen: `${moment(x.first_detected).format("MMM D, YYYY")}`,
              Asterisk: x.has_death_star ? "Yes" : "No",
            })),
          },
        ],
        "Pricing Report - Costco"
      );
    }
  }
  handleExcelFacings() {
    this.hideFeedDropdowns();
    if (this.props.data) {
      excelExport(
        [
          {
            name: "Product Pricing - Mass Retail",
            data: this.props.data.map((x) => ({
              Date: `${moment(x.date_taken).format("MMM D, YYYY")}`,
              Time: `${moment(x.date_taken).format("h:mm a")}`,
              Country: x.country,
              State: x.state,
              City: x.city,
              Retailer: x.retailer,
              Address: x.location,
              "Store Number": x.store_num ? `#${x.store_num}` : null,
              Demography: x.demography,
              Latitude: x.lat,
              Longitude: x.lng,
              Raw_Text: x.text,
              Price: x.price,
              Image: {
                text: "Price Reference",
                hyperlink: `${awsPhotosPrefix}crops/${x.img_facing}`,
                tooltip: `${awsPhotosPrefix}crops/${x.img_facing}`,
              },
              Photo: {
                text: `${appPrefix}s/${x.img_photo}`,
                hyperlink: `${appPrefix}s/${x.img_photo}`,
                tooltip: `${appPrefix}s/${x.img_photo}`,
              },
            })),
          },
        ],
        "Pricing Report - Mass Retail"
      );
    }
  }
  render() {
    return (
      <DesktopOrTablet
        content={
          <Group style={styles.flexEnd}>
            {this.props.data && this.props.data.length > 0 ? (
              <Container
                onClick={
                  this.props.facings
                    ? this.handleExcelFacings
                    : this.handleExcelPricing
                }
                svgSize="2em"
              >
                <FaFileExcel />
                Download Excel
              </Container>
            ) : null}
          </Group>
        }
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(null, mapDispatchToProps)(ExcelExportButton);
