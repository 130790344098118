import { PureComponent } from "react";
import styled from "styled-components";
import { Title, DiscoverContainer } from "constants/styled-components/index";
import Bento from "screens/discover/bento/index";
import { media } from "constants/responsive/index";
import { Link } from "react-router-dom";
import { awsPrefix } from "constants/variables/index";
import { connect } from "react-redux";

const CardLink = styled(Link)`
  min-width: 20%;
  width: 18em;
  max-width: 100%;
  ${media.mobile`
    width: 100%;
  `}
`;

export const featured = [
  {
    name: "Beyond North America",
    slug: "/feed?regions=is_not+-+vDF52DH|North%20America|3c88a6bc-4cc1-5a54-899a-4d48795e8895&filtered=true",
    backgroundIcon: `${awsPrefix}web-app/discover/global-store-checks.png`,
    primaryColor: "rgba(255, 215, 0, 0.2)",
    secondaryColor: "rgba(255, 255, 224, 0.2)",
  },
  {
    name: "QR Codes",
    slug: "/lp/tag/zpvygBa/feed",
    backgroundIcon: `${awsPrefix}web-app/discover/qr-codes.png`,
    primaryColor: "rgba(50, 50, 50, 0.2)",
    secondaryColor: "rgba(180, 180, 180, 0.2)",
  },
  {
    name: "Shelf Signage",
    slug: "/lp/collection/3G88fFoD/feed",
    backgroundIcon: `${awsPrefix}web-app/discover/shelf-signage.png`,
    primaryColor: "rgba(255, 127, 80, 0.2)",
    secondaryColor: "rgba(255, 250, 240, 0.2)",
  },
  {
    name: "Displays",
    slug: "/lp/tag/U_9aCZEN/feed",
    backgroundIcon: `${awsPrefix}web-app/discover/displays.png`,
    primaryColor: "rgba(120, 180, 240, 0.2)",
    secondaryColor: "rgba(255, 235, 205, 0.2)",
  },
  {
    name: "Contests",
    slug: "/lp/tag/quUw1YO/feed",
    backgroundIcon: `${awsPrefix}web-app/discover/contests.png`,
    primaryColor: "rgba(255, 20, 147, 0.2)",
    secondaryColor: "rgba(255, 240, 245, 0.2)",
  },
  {
    name: "Checkout Area",
    slug: "/lp/collection/pG0lBgAW/feed",
    backgroundIcon: `${awsPrefix}web-app/discover/checkout-area.png`,
    primaryColor: "rgba(144, 238, 144, 0.2)",
    secondaryColor: "rgba(224, 255, 255, 0.2)",
  },
  {
    name: "Limited Edition",
    slug: "/lp/tag/0QdSDfB/feed",
    backgroundIcon: `${awsPrefix}web-app/discover/limited-edition.png`,
    primaryColor: "rgba(128, 0, 128, 0.2)",
    secondaryColor: "rgba(255, 215, 0, 0.2)",
  },
];

class DiscoverCurated extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.setCurated();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.userPreferences &&
      this.props.userPreferences.current_pc &&
      prevProps.userPreferences.current_pc &&
      prevProps.userPreferences.current_pc.id !==
        this.props.userPreferences.current_pc.id
    ) {
      this.setCurated();
    }
  }
  setCurated() {
    let data = [...featured];
    if (
      this.props.userPreferences.current_pc &&
      this.props.userPreferences.current_pc.id
    ) {
      const teamData = {
        name: "Uploaded by my team",
        slug: `/feed?users=is+-+anyone|Anyone%20on%20my%20team|&private_collections=is+-+${
          this.props.userPreferences.current_pc.id
        }|${encodeURIComponent(this.props.userPreferences.current_pc.name)}|${
          this.props.userPreferences.current_pc.img
        }&filtered=true`,
        backgroundIcon:
          this.props.userPreferences &&
          this.props.userPreferences.current_pc &&
          this.props.userPreferences.current_pc.company
            ? `${awsPrefix}companies/${this.props.userPreferences.current_pc.img}`
            : `${awsPrefix}web-app/discover/mobile-app.png`,
        primaryColor: "rgba(250, 250, 200, 0.2)",
        secondaryColor: "rgba(200, 150, 200, 0.2)",
      };
      if (!data.some((item) => item.name === teamData.name)) {
        data.push(teamData);
      }
    }
    this.setState({
      data: data,
    });
  }
  render() {
    return (
      <DiscoverContainer>
        <Title>Editor's picks</Title>
        {this.state.data.map((x, i) => (
          <CardLink key={`bento-curated-${i}-${x.slug}`} to={x.slug}>
            <Bento
              title={x.name}
              interactive
              width="100%"
              height="auto"
              wraps
              backgroundColor={
                x.primaryColor
                  ? `radial-gradient(${x.secondaryColor}, ${x.primaryColor})`
                  : null
              }
              backgroundIcon={x.backgroundIcon}
            />
          </CardLink>
        ))}
      </DiscoverContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userPreferences: state.userPreferences,
});

export default connect(mapStateToProps, null)(DiscoverCurated);
