import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { createAPISelectors } from "@redux/selectors/apiSelectors";
var setTimeout = window.setTimeout, clearTimeout = window.clearTimeout;
export var useApiCall = function (component) {
    var dispatch = useAppDispatch();
    var timerRef = useRef();
    var apiCall = useCallback(function (payload) {
        dispatch({ type: "API_CALL_REQUEST", payload: payload });
    }, [dispatch]);
    var resetApiCall = useCallback(function () { return dispatch({ type: "API_CALL_RESET", payload: component }); }, [dispatch]);
    var _a = useMemo(function () { return createAPISelectors(component); }, [component]), isFetchingSelector = _a[0], isErrorSelector = _a[1], isSuccessSelector = _a[2];
    var isFetching = useAppSelector(isFetchingSelector);
    var isSuccess = useAppSelector(isSuccessSelector);
    var isError = useAppSelector(isErrorSelector);
    useEffect(function () {
        clearTimeout(timerRef.current);
        if (isSuccess) {
            timerRef.current = setTimeout(function () { return resetApiCall(); }, 1000);
        }
        return function () {
            clearTimeout(timerRef.current);
            if (isSuccess)
                resetApiCall();
        };
    }, [isSuccess]);
    return { apiCall: apiCall, isFetching: isFetching, isError: isError, isSuccess: isSuccess, resetApiCall: resetApiCall };
};
