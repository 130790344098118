import styled from "styled-components";

export const TooltipContainer = styled.div`
  padding: 1em;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  animation: fadeIn 0.5s;
  outline: none;
  align-items: center;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  border-radius: 1em;
`;
export const TooltipDash = styled.span`
  padding: 0 0.5em;
`;
export const Pies = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding-bottom: 2em;
`;
export const PieWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  animation: fadeIn 1s;
  width: 200px;
  border: 1px solid ${(props) => props.theme.grey5};
`;
export const PieContainer = styled.div`
  height: 150px;
  width: 150px;
  padding: 0.5em;
`;
export const Title = styled.p`
  margin: 0;
  min-width: 5em;
  color: ${(props) => props.theme.grey2};
  font-weight: bold;
`;
export const TooltipRow = styled.div`
  width: 100%;
  display: flex;
  color: ${(props) => props.theme.grey3};
`;
export const TooltipHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 0.5em;
`;
export const Td = styled.td`
  font-weight: bold;
  padding: 0.5em;
  max-width: 20em;
  word-wrap: break-word;
  overflow: hidden;
  text-align: center;
  height: ${(props) => (props.height ? props.height : "auto")};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  background: ${(props) =>
    props.white ? props.theme.grey6 : props.theme.grey5};
  transition: ${(props) => props.theme.fastTransition};
  color: ${(props) => props.theme.grey2};
  a {
    color: ${(props) => props.theme.grey2};
  }
`;
export const Divider = styled.div`
  width: 100%;
  height: 1em;
  margin-top: 2em;
  border-top: 1px solid ${(props) => props.theme.grey6};
`;
export const TopLeft = styled.th`
  color: ${(props) => props.theme.grey2};
  position: sticky;
  left: 0;
  border-color: ${(props) => props.theme.grey4};
  border-right-style: solid;
  border-bottom-style: solid;
  background: ${(props) => props.theme.white};
  top: 0;
  z-index: 2;
  width: 5em;
`;
export const Logo = styled.div`
  width: ${(props) => (props.width ? props.width : "2em")};
  height: ${(props) => (props.height ? props.height : "2em")};
  border-radius: 0.5em;
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: ${(props) => (props.margin ? props.margin : "1em")};
`;
export const Table = styled.table`
  display: block;
  border-collapse: separate;
  font-size: 0.8em;
  table-layout: fixed;
  max-width: 100%;
  width: fit-content;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 60vh;
  position: relative;
  white-space: nowrap;
  border-color: ${(props) => props.theme.grey4};
  border-style: solid;
  border-radius: 1em;
  border-spacing: 1px;
  .frozenColumn {
    background: ${(props) => props.theme.white};
    border-top: 1px solid ${(props) => props.theme.white};
    border-bottom: 1px solid ${(props) => props.theme.white};
    position: sticky;
    min-width: 15em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    top: auto;
    z-index: 1;
    border-right-style: solid;
    border-right-color: ${(props) => props.theme.grey4};
  }
`;
export const Th = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.grey2};
  padding: 1em;
  border-color: ${(props) => props.theme.grey4};
  border-bottom-style: solid;
`;
export const H3 = styled.h3`
  font-weight: bold;
  min-width: fit-content;
  padding: 0 0.5em;
  color: ${(props) => props.theme.grey2};
`;
export const Brand = styled.div`
  border-radius: 0.5em;
  transition: ${(props) => props.theme.fastTransition};
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  padding: ${(props) => (props.padding ? props.padding : "0.5em")};
  margin: 0.25em;
  animation: fadeIn 1s;
  min-width: 6em;
  cursor: pointer;
  display: flex;
  border-style: solid;
  border-width: 0.5px;
  align-items: center;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.active ? props.theme.grey3 : props.theme.white};
  color: ${(props) => (props.active ? props.theme.white : props.theme.grey2)};
  &:hover {
    border-color: ${(props) => props.theme.grey4};
    box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  }
`;
export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em 0.5em 0 0.5em;
  width: 100%;
  box-sizing: border-box;
`;
export const Legend = styled.div`
  width: 100%;
  display: flex;
  animation: fadeIn 1s;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 0.7em;
`;
export const Text = styled.span``;

export const TooltipDot = styled.div`
  width: 1em;
  height: 1em;
  min-height: 1em;
  min-width: 1em;
  border-radius: 50%;
  margin-right: 0.5em;
`;
export const TooltipLine = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  animation: fadeIn 0.5s;
  padding: 0.25em 0;
`;
