var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { avatarFromName } from "constants/functions";
import { awsPrefix } from "constants/variables";
import styled from "styled-components";
var ProfilePhoto = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 100%;\n  border-color: ", ";\n  border-style: solid;\n  user-select: none;\n  border-width: ", ";\n  width: ", ";\n  height: ", ";\n  margin-right: ", ";\n  margin-left: ", ";\n"], ["\n  border-radius: 100%;\n  border-color: ", ";\n  border-style: solid;\n  user-select: none;\n  border-width: ", ";\n  width: ", ";\n  height: ", ";\n  margin-right: ", ";\n  margin-left: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.grey6;
}, function (_a) {
    var borderWidth = _a.borderWidth;
    return borderWidth || "3px";
}, function (_a) {
    var width = _a.width;
    return width || "6em";
}, function (_a) {
    var height = _a.height;
    return height || "6em";
}, function (_a) {
    var marginRight = _a.marginRight;
    return marginRight || 0;
}, function (_a) {
    var marginLeft = _a.marginLeft;
    return marginLeft || 0;
});
export var FallbackUserImage = function (_a) {
    var src = _a.src, firstName = _a.firstName, lastName = _a.lastName, imageProps = __rest(_a, ["src", "firstName", "lastName"]);
    var _b = useState(!src), errored = _b[0], setErrored = _b[1];
    var imageSrc = useMemo(function () {
        if (errored) {
            if (firstName) {
                var name = lastName ? "".concat(firstName, " ").concat(lastName) : firstName;
                return avatarFromName(name);
            }
            return "".concat(awsPrefix, "generic-user.jpg");
        }
        return src;
    }, [errored, src, firstName, lastName]);
    useEffect(function () { return setErrored(!src); }, [src]);
    return (_jsx(ProfilePhoto, __assign({ src: imageSrc, draggable: "false", onError: function () { return setErrored(true); } }, imageProps)));
};
var templateObject_1;
