import { PureComponent } from "react";
import { ResponsiveHeatMap, ResponsiveHeatMapCanvas } from "@nivo/heatmap";

export default class Heatmap extends PureComponent {
  render() {
    const numRows = this.props.data.length;
    const numColumns = this.props.data[0].data.length;
    if (numRows * numColumns > 350) {
      return <ResponsiveHeatMapCanvas {...this.props} />;
    }
    return <ResponsiveHeatMap isInteractive {...this.props} />;
  }
}
