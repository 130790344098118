export const pastelTheme = { scheme: "set3" };
export const pastelTheme2 = { scheme: "pastel2" };
export const pastelTheme1 = { scheme: "pastel1" };
export const fromColor = { from: "color" };
export const dotColor = { theme: "background" };
export const baselineMarker = {
  axis: "y",
  value: 100,
  textStyle: { fill: "#AAAAAA" },
  lineStyle: { stroke: "#AAAAAA", strokeWidth: 2 },
  legend: "Baseline",
};
