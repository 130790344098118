var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Flex, Img, Padding, SkeletonContainer } from "constants/styled-components";
import CollectionGroup from "screens/collections/group/index";
import SimplePill from "components/simple-pill/index";
import SimpleSkeleton from "components/simple-skeleton/index";
import { adminPlanId, awsPrefix, partnerCountries } from "constants/variables";
import { FaPlus } from "react-icons/fa";
import MainCTA from "components/buttons/main";
import styled, { useTheme } from "styled-components";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";
import { useApiCall } from "hooks/useApiCall";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { curatedCollectionCountrySelected, selectCuratedCollectionsByTypeAndSelectedCountry } from "@redux/slices/curatedCollectionsSlice";
var Section = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-wrap: wrap;\n  padding: 2em 6em 0;\n  width: -webkit-fill-available;\n  width: -moz-available;\n"], ["\n  flex-wrap: wrap;\n  padding: 2em 6em 0;\n  width: -webkit-fill-available;\n  width: -moz-available;\n"])));
var Spacing = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media (min-width: 1408px) {\n    width: 18em;\n  };\n"], ["\n  @media (min-width: 1408px) {\n    width: 18em;\n  };\n"])));
var PageTitle = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n  text-transform: uppercase;\n  text-align: center;\n  margin: auto;\n  width: 100%;\n  padding: 0.5em;\n  animation: fadeIn 0.5s;\n\n  @media (min-width: 1408px) {\n    width: auto\n  }\n"], ["\n  font-weight: bold;\n  text-transform: uppercase;\n  text-align: center;\n  margin: auto;\n  width: 100%;\n  padding: 0.5em;\n  animation: fadeIn 0.5s;\n\n  @media (min-width: 1408px) {\n    width: auto\n  }\n"])));
export var CuratedCollections = function (_a) {
    var collectionType = _a.collectionType, title = _a.title;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var _b = useApiCall("CURATED_COLLECTIONS"), apiCall = _b.apiCall, isFetching = _b.isFetching;
    var _c = useAppSelector(function (state) { return state.curatedCollections; }), fetchedFromServer = _c.fetchedFromServer, selectedCountry = _c.selectedCountry;
    var collections = useAppSelector(function (state) { return selectCuratedCollectionsByTypeAndSelectedCountry(state, collectionType); });
    var isAdmin = useAppSelector(function (state) { var _a; return ((_a = state.dataUser) === null || _a === void 0 ? void 0 : _a.plan_id) === adminPlanId; });
    useEffect(function () {
        if (!fetchedFromServer) {
            apiCall({
                method: "GET",
                component: "CURATED_COLLECTIONS",
                route: "collection/curated_collections"
            });
        }
    }, [fetchedFromServer]);
    var handleCountrySelect = function (countryId) { return dispatch(curatedCollectionCountrySelected(countryId)); };
    var handleCreatePipelineCollection = function () {
        dispatch(showModal({
            component: Modals.CREATE_PIPELINE_COLLECTION,
        }));
    };
    if (isFetching) {
        return (_jsx(SkeletonContainer, { children: Array.from({ length: 18 }).map(function (_, i) { return (_jsx(SimpleSkeleton, { width: "15vw", height: "15vw" }, "pub-skel-".concat(i, "-").concat(i))); }) }));
    }
    return (_jsxs(_Fragment, { children: [collectionType === "retail_pipelines" && (_jsxs(_Fragment, { children: [_jsxs(Section, { children: [isAdmin && (_jsx(Spacing, {})), _jsx(PageTitle, { children: title || "All Retail Pipelines" }), isAdmin && (_jsx(MainCTA, { noMargin: true, text: "Create New Pipeline Collection", background: theme.midGreen, backgroundHover: theme.deepGreen, icon: _jsx(FaPlus, { className: "override-fill", fill: "white" }), clickAction: handleCreatePipelineCollection }))] }), _jsx(Section, { children: partnerCountries.map(function (country) { return (_jsx(SimplePill, { clickAction: function () { return handleCountrySelect(country.id); }, highlighted: country.id === selectedCountry, primary_color: "(255,255,255)", name: country.name, icon: _jsx(Img, { draggable: "false", src: "".concat(awsPrefix, "countries/").concat(country.img) }) }, country.id)); }) })] })), _jsx(Padding, { amount: "0 3em", children: _jsx(CollectionGroup, { fallbackText: "Contact us to access nationwide store checks in this country", data: collections }) })] }));
};
var templateObject_1, templateObject_2, templateObject_3;
