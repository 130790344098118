import { FaChartPie, FaChartLine, FaTable } from "react-icons/fa";

const L52WKVSYAGO = [
  { name: "L52 WK", id: "current" },
  { name: "L52 WK YAGO", id: "yago" },
];
const currentYear = new Date().getFullYear();
const CurrentVsPrevious = [
  { name: `${currentYear}`, id: "current" },
  { name: `${currentYear - 1}`, id: "yago" },
];
const L4WKL12WKL52WK = [
  { name: "L52 WK", id: "l52" },
  { name: "L12 WK", id: "l12" },
  { name: "L4 WK", id: "l4" },
];
const PieVsLine = [
  { name: <FaChartLine />, id: "line" },
  { name: <FaChartPie />, id: "pie" },
  { name: <FaTable />, id: "table" },
];
const MVSD = [
  { name: "Main section", id: "main_section" },
  { name: "Display", id: "display" },
];
const dataSourcePricing =
  "To get pricing data, we use the latest in machine learning to automatically draw a box around every facing within the main section planogram. From there, we automatically extract the price and deduplicate the data in case the facing was captured in a second photo.";
const dataSourceFlyers =
  "To get a representative view of the market, Shelfgram ingests circulars from 160 retailers in the US and 162 retailers in Canada. We use AI to chop up the flyer into ad blocks and add metadata tags to the circulars.";
const priceAssortmentDescription = [
  "There are multiple ways to arrive at a final purchase. While every shopper is different, each follows a decision tree to evaluate products in the category and make a buying decision. Shoppers consider a hierarchy of decision criteria including brand, flavor, size, form, and more. However, a major factor is almost always price. So to maximize category sales, retailers need to find the right balance of price assortment",
  "This report counts the number of shelf tags spotted at each price point at each retailer. It's essentially a share-of-shelf analysis by price point. You can use this data to identify which price points a retailer is under or over invested in. Identifying price assortment gaps is a great way to find opportunities for retailers to convert more browsers into buyers.",
  "By clustering products into price segments, you can also better position new product innovation to each retailer. For example, your new product might be an opportunity to trade up shoppers from Segment A to a more premium offering, offer shoppers a discount alternative to Segment B, or help a retailer fill a price assortment gap to compete more effectively in the marketplace.",
  dataSourcePricing,
];

export const analyticsReports = [
  {
    name: "Product",
    slug: "product",
    primaryColor: "#D0EDB4",
    secondaryColor: "#77DD77",
    reports: [
      {
        name: "Leaders by retailer",
        summary:
          "See a stack ranking of brand visibility by retailer over time.",
        description: [
          "Over the past few years, research has repeatedly shown that 70-80 percent of purchase decisions are made in-store. We know that shopper engagement is directly tied to brand visibility so to maximize sales, we need to understand how well your brand shows up vs. the competition.",
          "Shelfgram uses machine learning to rank the relative merchandising support that retailers provide to each brand in your category. We consider the frequency of brand tags as well as the quality (e.g. display, main section, shelf position, etc.) of each tag to evaluate how “visible” a brand is relative to the previous week and vs. its category competitors.",
          "The table in this report summarizes this data in a stack ranking showing which brands each retailer is supporting. If you're curious what's behind the ranking, you can click on any of the cells in the table and Shelfgram will take you to a virtual store check with the right filters automatically applied.",
          "To extract brand visibility rankings from retail photos, we score each brand in the category against every single photo. If the brand isn't in the photo because of a distribution gap, an out-of-stock, or any other reason, it gets zero points. The more facings the brand has - and the larger the brand block is - the more points that brand gets. We then express the summary scores as an index so you can track the top winners and losers each week.",
        ],
        yOffset: "-3em",
        coverage: true,
        img: "leaders-by-channel",
        background: "card-background_card-bump",
        slug: "leaders_by_retailer",
        legendRows: 1,
        filters: [PieVsLine, CurrentVsPrevious],
        ppt: "svg",
      },
      {
        name: "Leaders by channel",
        summary:
          "See a stack ranking of brand visibility by channel over time.",
        description: [
          "Over the past few years, research has repeatedly shown that 70-80 percent of purchase decisions are made in-store. We know that shopper engagement is directly tied to brand visibility so to maximize sales, we need to understand how well your brand shows up vs. the competition.",
          "Shelfgram uses machine learning to rank the relative merchandising support that retailers provide to each brand in your category. We consider the frequency of brand tags as well as the quality (e.g. display, main section, shelf position, etc.) of each tag to evaluate how “visible” a brand is relative to the previous week and vs. its category competitors.",
          "The table in this report summarizes this data in a stack ranking showing which brands each retailer is supporting. If you're curious what's behind the ranking, you can click on any of the cells in the table and Shelfgram will take you to a virtual store check with the right filters automatically applied.",
          "To extract brand visibility rankings from retail photos, we score each brand in the category against every single photo. If the brand isn't in the photo because of a distribution gap, an out-of-stock, or any other reason, it gets zero points. The more facings the brand has - and the larger the brand block is - the more points that brand gets. We then express the summary scores as an index so you can track the top winners and losers each week.",
        ],
        yOffset: "-3em",
        coverage: true,
        img: "report_leaders-by-retailer",
        background: "card-background_card-bump",
        tips: "Some retailers are classified in more than one channel. To see which channels they belong to, you can search for that retailer on Shelfgram and go to the about page.",
        slug: "leaders_by_channel",
        legendRows: 1,
        filters: [PieVsLine, CurrentVsPrevious],
        ppt: "svg",
      },
      {
        name: "Leaders by geography",
        summary: "See a stack ranking of brand visibility by state over time.",
        description: [
          "This report examines which brands have the greatest in-store presence by state or province, allowing for the comparison of a brand's performance over time and providing valuable insights for businesses looking to expand or improve their in-store presence.",
          "One key takeaway from this report is that regional differences can play a significant role in determining a brand's success. This underscores the importance of understanding local markets and tailoring strategies to fit the specific preferences of each region.",
          "Another important finding from the report is that the position of a brand in the stack rank can fluctuate over time so businesses should continually monitor their performance and adapt to changes in the market in order to maintain their position.",
        ],
        yOffset: "-3em",
        coverage: true,
        img: "report_leaders-by-geography",
        background: "card-background_card-bump",
        slug: "leaders_by_geography",
        legendRows: 1,
        filters: [PieVsLine, CurrentVsPrevious],
        ppt: "svg",
      },
      {
        name: "New items in the club channel",
        summary:
          "Review a list of items recently spotted in the main section for the first time.",
        description: [],
        legendRows: 3,
        tips: "Our AI picks up on any products in the photo. Often, they're in the background and unrelated but it's better to flag false positives then to not include them.",
        yOffset: "-1em",
        type: "table",
        img: "new-costco-products",
        background: "card-background_table",
        slug: "new_items_club",
      },
      {
        name: "Brand development by retailer",
        summary:
          "Explore an index of each brand's visibility by retailer, relative to the market as a whole.",
        description: [
          "Retailers invest a lot in building expertise to enable an understanding of how their shoppers think and behave. However, they come to different conclusions on how best to approach the category based on differences in overall strategy, the desire to create a differentiated shopping experience, and advice and influence by from their category captain.",
          "Ultimately, the store layout, planogram decisions, promotional calendars, inventory management approaches, and shifting category definitions all vary by retailer. Collectively, these have a material impact on which brands shoppers end up seeing in-store.",
          "With the information from this report, brands can understand how their relative performance varies by retailer, allowing them to appropriately allot trade investment budgets, identify opportunities for new growth, ang provide stronger recommendations to each retailer on how they can grow the category.",
        ],
        yOffset: "-0.75em",
        coverage: true,
        img: "report_bdi-by-retailer",
        background: "card-background_bar-chart",
        slug: "bdi_by_retailer",
        legendRows: 1,
        filters: [L52WKVSYAGO],
        ppt: "svg",
      },
      {
        name: "Brand development by geography",
        summary:
          "Explore an index of each brand's visibility by state, relative to the market as a whole.",
        description: [
          "There are a number of factors that affect brand visibility and performance by geography. As national brands come up against local players, evolving merchandising decisions on best to layout the category lead to different outcomes.",
          "There is a considerable amount of evidence that also suggests that regional psychological differences do exist among shoppers. We know from research in the social and health sciences, for example, that there are differences in political attitudes and economic prosperity by geography and we also know that different cultures and sub-cultures are not evenly distributed across a country. These factors are linked to differences in personality traits and psychological characteristics at a population level which influences purchasing behavior and brand preferences.",
          "Compounding these factors is that many retailers themselves over or under index regionally with their own business development and store counts. In some geographies, regional retailers hold high market share so their individual approaches to category management can cause some brands to flourish or flounder geographically.",
          "With this report you can identify strong and weak geographic areas for your brand - and your competitors too - informing smarter marketing investment decisions.",
        ],
        yOffset: "-3em",
        coverage: true,
        img: "report_bdi-by-geography",
        background: "card-background_bar-chart",
        slug: "bdi_by_geography",
        legendRows: 1,
        filters: [L52WKVSYAGO],
      },
      {
        name: "Brand development by urbanization",
        summary:
          "Explore how each brand's visibility is influenced by the level of urbanization",
        description: [
          "The history of the development of retail parallels the flow of people in and out of cities because the social, economic and technological shifts accompanying urbanization impact all areas of retail.",
          "Retail supply chains are organized around population density in a way that can affect product assortment, availability, and distribution. After all, shoppers that are taking public transit will innevitably have different baskets that those who arrive with an empty car trunk. For some brands and some categories, this has a noticeable affect on their reach and influence.",
          "The diverse, fast-paced and digitally savvy urban consumers often show a preference for brands that promise convenience or the latest innovation whereas shoppers in suburban or rural areas might optimize for other preferences like value, pack size, or reliability.",
          "All locations on Shelfgram are classified as Urban, Suburban, or Rural based on the store's proximity to the closest large city.",
        ],
        yOffset: "-3em",
        coverage: true,
        img: "report_bdi-by-urbanization",
        background: "card-background_bar-chart",
        slug: "bdi_by_urbanization",
        legendRows: 1,
        filters: [L52WKVSYAGO],
      },
      {
        name: "Brand listings",
        summary:
          "Monitor new competitive listings across the category backed by our integrated planogram viewer.",
        description: [
          "Finding and maintaining the optimal assortment of products to sell in stores has always been at the core of category management. Brands and retailers who get the assortment right enjoy more sales, higher gross margins, leaner operations, and more loyal shoppers.",
          "Assortment optimization is one of those things that's never finished. It's an ongoing process because each new listing triggers a delisting process and yields new insight on what's working and what's not. But keeping up with all the changes in the category can be a lot to manage.",
          "With this report, you can pinpoint the date that brands in the category got listed at a retailer. By viewing the planogram, you'll find out where a brand got a listing, which product is now on the shelf, and what the pricing is. Paired with your sales data, this can help you quantify the impact of each new listing. Combined with sales data, you can develop better assortment recommendations that grow your brand and the category.",
        ],
        tips: "Clicking on a cell in the table will open up the relevant photo in the viewer. To explore a brand or retailer, click on the name or logo in the first row or column.",
        yOffset: "-5.5em",
        coverage: true,
        img: "report_brand-listing",
        background: "card-background_table",
        slug: "listings",
        legendRows: 1,
      },
      {
        name: "Retailer assortment",
        summary:
          "Compare the number of brands that each retailer stocks in the category.",
        description: [
          "To meet the evolving needs of shoppers, brands and retailers must work together in optimizing retail space so they can provide the right mix of products in the category.",
          "Good assortment planning identifies and swaps lower-performing products for products with higher velocities that contribute more revenue, improve supply chain efficiency, and reduce inventory costs.",
          "This report provides a summary view of the number of brands identified in the category at each retailer within the main section. By comparing the change between the latest 4 week period vs. year ago, you can uncover if retailers are growing or shrinking brand assortment. Paired with sales data, this report can be used to contrast the sales performance of each retailer's assortment changes and uncover if shoppers prefer more brand selection or more products from the brands they already know and love.",
        ],
        yOffset: "-7em",
        coverage: true,
        img: "report_assortment",
        background: "card-background_table",
        slug: "brand_assortment",
        legendRows: 3,
      },
      {
        name: "Social media handles",
        summary:
          "Get a list of accounts to quickly follow brands in the category across the major platforms.",
        description: [
          "If you're looking to get up-to-speed on a new category or stay up-to-date on competitive marketing campaigns, one of your first steps is to follow the social media accounts of the competition. Even by just reading the bios from each brand's handle, you can learn a lot about how the brand positions itself among consumers.",
          "Reviewing digital media is also a great way to identify new market trends, understand what top-performing content has in common, and discover your competitor's audience size, profile, and behavior.",
          "One of the ways Shelfgram's AI works is by fingerprinting brands from their social media profiles. Since we have that information, we thought we'd make it available and downloadable as a report in our analytics suite.",
        ],
        legendRows: 3,
        tips: "Click on the handles in the table to open them in a new tab. If you set up a new social media account and follow everyone in the category, you'll find that keeping tabs on competitive activity is a lot easier.",
        yOffset: "-1em",
        type: "table",
        img: "report_social",
        background: "card-background_table",
        slug: "social_media",
      },
    ],
  },
  {
    name: "Price",
    slug: "pricing",
    primaryColor: "#ffcccc",
    secondaryColor: "#ffd9b3",
    reports: [
      {
        name: "Pricing by retailer",
        summary:
          "See how much it costs your shopper to buy the category at each retailer.",
        description: priceAssortmentDescription,
        yOffset: "-3.5em",
        coverage: true,
        img: "pricing-assort-by-retailer",
        background: "card-background_table",
        slug: "pricing_assortment_retailer",
        legendRows: 3,
        filters: [L4WKL12WKL52WK],
        ppt: "svg",
      },
      {
        name: "Pricing by geography",
        summary:
          "See how much it costs your shopper to buy the category by geography.",
        description: priceAssortmentDescription,
        yOffset: "-3.5em",
        coverage: true,
        img: "pricing-assort-by-geo",
        background: "card-background_table",
        slug: "pricing_assortment_geography",
        legendRows: 3,
        filters: [L4WKL12WKL52WK],
        ppt: "svg",
      },
      {
        name: "Pricing by urbanization",
        summary:
          "See how much it costs your shopper to buy the category by the level of urbanization.",
        description: priceAssortmentDescription,
        yOffset: "-3.5em",
        coverage: true,
        img: "pricing-assort-by-urbanization",
        background: "card-background_table",
        slug: "pricing_assortment_urbanization",
        legendRows: 3,
        filters: [L4WKL12WKL52WK],
        ppt: "svg",
      },
      {
        name: "Category inflation",
        summary: "Visualize the category's overall pricing trend.",
        description: [
          dataSourcePricing,
          "This report tracks that pricing data over time to see how much it costs a shopper to buy the category.",
          "To determine the average price of the category, we arrange all of the price tags spotted in all of the photos from the main section of the category from highest to lowest. Then, we find the median price and track it week over week.",
          "For revenue growth management teams, this report can be used as part of a larger analysis on category pricing or as part of a sales story to a retailer on why a price increase or decrease makes good business sense.",
        ],
        tips: "The chart reflects a blend of regular and temporarily reduced prices because it shows the median category price based on shelf tags.",
        yOffset: "-7em",
        coverage: true,
        img: "report_category-inflation",
        background: "card-background_line-graph",
        slug: "inflation",
        legendRows: 1,
        ppt: "svg",
      },
      {
        name: "Discounting strategy",
        summary:
          "Chart the percentage of temporary price reductions at each retailer over time.",
        description: [
          "There are a few popular pricing strategies used in retail to maximize revenue and boost margin but the two big ones are Everyday Low Prices (EDLP) and High-Low. EDLP allows companies to set a low price without making their customers wait for deals whereas a high-low pricing strategy sets a high initial price and then lowers it during promotional campaigns.",
          "This report charts the percentage of temporary price reductions observed in the category at each retailer over time. Paired with the 'Average price' report, you can quickly get a sense of each retailer's approach to pricing in the category.",
          "Traditionally, EDLP is thought to work best in categories that are predominantly needs-oriented because consumers don't want to constantly invest the time to find the best deal for items they need. By contrast, the big benefit to high-low pricing is the ability to segment the market and capture the surplus value found in categories with higher price-elasticity.",
          dataSourcePricing,
        ],
        tips: "The percent of prices classified as promotional is likely understated since we only capture verified price reductions where a sale tag or sticker is put up. The 'Category' view is a great way to understand promotional movements and seasonality between time periods. With the 'Retailer' view, you can see each retailer's discounting activity as an index (100) relative to that retailer's average to highlight when they increase or decrease temporary price reductions.",
        yOffset: "0.5em",
        coverage: true,
        img: "report_promo-strategy",
        background: "card-background_bar-chart",
        slug: "discounting",
        legendRows: 2,
        filters: [L52WKVSYAGO],
        ppt: "svg",
      },
    ],
  },
  {
    name: "Place",
    slug: "place",
    primaryColor: "#ffcccc",
    secondaryColor: "#ecc6ec",
    reports: [
      {
        name: "Brand distribution gaps",
        summary:
          "Identify opportunities to close distribution gaps with a summary report.",
        description: [
          "Ever since COVID-19 began, supply chains have been growing increasingly complex. Since distribution determines the total number of shoppers that have the opportunity to purchase your product, finding up-to-date data to measure distribution is super important. Simply put, increasing distribution is the gift that keeps on giving!",
          "This report helps you assess the revenue potential you’re missing out on based on your current distribution strategy. There's a lot of complex metrics to track distribution but we've simplified it to the three traffic lights. A green circle means that we've spotted the brand on our most recent visit to that location. A yellow circle means that we didn't spot the brand on our most recent audit of that location but we have seen it on past visits to the same location. Finally, a red circle means that we've never spotted that brand at that specific store location.",
          "We summarize the findings to give you a high-level idea of the breadth and the depth of your distribution. However, you can always expand the table to see individual locations. Clicking on the circles opens up our photo feed so you can get a closer look. Sometimes, gaps can be explained by temporary stock outs or an issue with our AI system. If it's the latter, let us know and we can fine-tune the algorithm to increase it's precision!",
        ],
        tips: "Click on a row in the table to expand the view and see individual locations. You can also click on the dots to open the photo feed for that location in a new tab.",
        coverage: true,
        img: "report_distribution-gaps",
        background: "card-background_table",
        slug: "distribution_gaps",
        legendRows: 2,
      },
      {
        name: "Distribution over time",
        summary:
          "Track a brand's distribution in the category to monitor gains and losses.",
        description: [
          "The most important prerequisite to sales is always distribution. If a brand isn’t available in stores and customers can’t get their hands on it, it doesn’t really matter how great their marketing is.",
          "While most brands have a pretty good idea of their own distribution, it's harder to know about competitive wins and losses. This is especially true when it comes to tracking smaller competitors or doing any kind of growth attributing modeling using your sales data.",
          "This report shows you each brand's market reach over time. We calculate this by looking at the percentage of locations where the brand was spotted at least once during the month. This focus of this report is on reach so it doesn't consider the number of facings or stock levels - just whether or not the brand is present.",
          "Some distribution metrics (like ACV) are weighted so you get more credit for showing up in big stores vs. small ones but we just report the percentage of total doors, assuming an equal weighting for each retailer and location.",
          "The percent of locations where a brand is spotted is most impacted by which retailers we're visiting for this collection and whether or not the brand is widely available at those retailers. We'd recommend going to the locations tab in this collection to learn more about the overall data sample.",
          "On that note, we use a sampling methodology that maximizes the breadth and depth of stores you see on Shelfgram. This creates natural fluctuations as different locations are visited each month with some repeating longitudinally. For brands that don't have mass distribution, it's normal to see swings of up to 5% in either direction. Any change month-to-month within that threshold for these brands is not significant.",
        ],
        tips: "Click on one or more brands to compare them in the chart!",
        yOffset: "-5em",
        coverage: true,
        imgRatio: 0.8,
        img: "report_distribution-growth",
        background: "card-background_line-graph",
        slug: "distribution_time",
        legendRows: 4,
        ppt: "svg",
      },
      {
        name: "Category adjacencies",
        summary:
          "Learn what other categories are most often spotted nearby in the main section and on display.",
        description: [
          "While shoppers make use of wayfinding signs to navigate stores, a key part of how they orient themselves is their expectations on where to find categories based on the location of adjacent categories. For example, a shopper that's looking to buy the Honey category and has discovered the Tea section might browse nearby.",
          "Shoppers that go down the aisle expecting to find a category and don’t find it end up confused and frustrated. But by pairing items that naturally complement one another, retailers and manufacturers can deliver a better experience while driving impulse sales and increasing the basket size.",
          "Ultimately, category adjacencies are a major influencer of shopper purchasing behavior, and therefore directly affect sales and profitability. Noticing these adjacencies can also open up new opportunities including developing partnerships with other non-competitive brands.",
          "For example, one manufacturer using Shelfgram found that in the Coffee category, the most adjacent category in the main section was Tea but on display, it was Cereal, because of the breakfast usage occasion being promoted by retailers. They were able to isolate a single retailer that was mostly executing Tea and Coffee together on displays, and they shared the insight with them as a way to sell-in an incremental display program.",
        ],
        yOffset: "-6em",
        coverage: true,
        img: "report_category-adjacencies",
        background: "card-background_radar-chart",
        slug: "category_adjacencies",
        legendRows: 3,
        filters: [MVSD],
        ppt: "svg",
      },
      {
        name: "Brand adjacencies",
        summary:
          "Determine which brands are being cross-promoted together on display and merchandised together in the main-section.",
        description: [
          "How well your brand stands out - and which shoppers discover it - has a lot to do with who’s next to you in the store. This is especially true for new brands in the category that have yet to be discovered by shoppers. Moreover, a common way of promoting products is through cross-selling, where one product that a shopper wants is combined with an adjacent product that they might not otherwise think about.",
          "For these reasons, successful brand builders evaluate how their packaging and branding decisions show up in a retail environment. Differentiating yourself from direct competitors is critical if you’re placed close together so knowing your brand adjacencies can help you design for the most common situations.",
          "Paired with sales data, an adjacency analysis can be a quick way to understand what kinds of retail conditions are favorable or unfavorable for a brand. When comparing adjacency data to a prior year, you can also uncover how shifts in retailer merchandising strategies may be impacting sales performance.",
          "Shelfgram's AI tags brands in photos irrespective of whether or not they're in the category. This gives us a unique vantage point to report back on brand adjacencies.",
        ],
        yOffset: "-5em",
        coverage: true,
        img: "report_brand-adjacencies",
        background: "card-background_radar-chart",
        slug: "brand_adjacencies",
        legendRows: 2,
        filters: [MVSD],
        ppt: "svg",
      },
      {
        name: "Aisle arrangement",
        summary:
          "Learn what brands appear the most at the start, middle, and end of the set.",
        description: [
          `This report allows you to understand which brands are typically found at the beginning, middle, or end of the aisle, and can be pulled by retailer to assess planogram compliance and execution of "perfect store" strategies.`,
          "By analyzing the position of brands in the aisle and cross-referencing sales data, you can identify opportunities to optimize their product placement and increase sales. For example, if a brand is consistently found at the beginning of the aisle at retailers where the category is growing fastest, this may indicate that it's presence is that position is good for the category.",
          "In some categories, well-recognized brands act as signposts to signal to shoppers the location of the category. When these brands are placed at the beginning or the end of the aisle, the category benefits from a greater percentage of shoppers finding their way to the shelf.",
          "When cross-referenced to sales data by retailer, this report can reveal the winning layout patterns and in-turn, help build a story around how retailers can grow the category by adjusting the planogram.",
        ],
        yOffset: "-6em",
        coverage: true,
        img: "report_aisle-arrangement",
        background: "card-background_table",
        slug: "aisle_arrangement",
        legendRows: 3,
        filters: [MVSD],
        ppt: "svg",
      },
      {
        name: "Planogram size by retailer",
        summary:
          "Compare how much space each retailer allocates to the category based on the average number of photos taken of the main section.",
        description: [
          "With a growing number of products offered by manufacturers, retailers are in constant quest to optimize their limited shelf space.",
          "Over time, retailers add or remove linear feet based on shifting consumer trends, category out-of-stock levels, new sections added in the store, and competing requests for space from other categories.",
          "Sometimes, your brand isn't winning or losing at a retailer or geographic area but in a planogram set size or format. The first step is to take stock of the most common planogram sizes at each retailer.",
          "This report - especially when combined with your own sales data - can help point you in the right direction to find out what your highest performing planograms have in common.",
        ],
        tips: "Each photo represents approximately 4 feet in width. While there's a bit of overlap between the photos, this should give you a rough estimate of the total length of the planogram. While the columns are ordered from smallest to largest, they are not sequential so you might see some jumps based on the data. This is to avoid showing empty columns.",
        yOffset: "-7em",
        coverage: true,
        img: "report_planogram",
        background: "card-background_table",
        slug: "pog_size",
        legendRows: 2,
        ppt: "canvas",
      },
      {
        name: "Planogram size over time",
        summary:
          "Check which retailers are allocating more or less space to the category",
        description: [
          "There are a lot of benefits to more shelf space including greater sales, better assortment, and fewer out of stock situations. For each product category, retailers must allocate space within a given total store footprint. But since space is a limited and precious resource, it needs to be used wisely.",
          "All categories in a store are interdependent in the sense that to increases space in one category, you need to take space from another category.",
          "This report helps you determine if the amount of selling space allocated to the category is growing or shrinking over time. It's also a useful proxy to benchmark which retailers are investing more (or less) in the category.",
          "The chart in this report shows the number of photos taken to capture the main section. Each photo roughly translates to about 4 feet of retail. The report shows an overall average to adjust for each photo being taken at a slightly different distance and with varying amounts of overlap.",
        ],
        tips: "To parse the signal from the noise in this chart, look for swings more than 2 photos in any direction. You can always view the Planogram tab to see how the category was captured.",
        yOffset: "-4em",
        coverage: true,
        img: "planogram-growth-time",
        background: "card-background_table",
        slug: "pog_over_time",
        filters: [L52WKVSYAGO],
        legendRows: 2,
        ppt: "canvas",
      },
      {
        name: "Share of shelf",
        summary:
          "Understand if you're getting your fair share within the category.",
        background: "card-background_bar-chart",
        coverage: true,
        slug: "sos",
        img: "report_share-shelf",
        ppt: "svg",
      },
      {
        name: "Discoverability",
        summary:
          "Analyze changes in each brand's approach to gaining in-store visibility.",
        description: [
          "The scatter plot in this report shows the number of photos that each brand was spotted in along two dimensions where the X axis are pictures taken in the main section and the Y axis represents photos taken of secondary displays",
          "By toggling between the time periods in the top right corner, you can animate shifts in merchandising strategies within the category to help explain what might be driving business performance.",
          "Another advantage of the 'Discoverability' tool is that it allows brands to compare their performance to others in the same category. By viewing the report for multiple brands at once, businesses can see how they stack up against their competitors and adjust their strategies accordingly",
          "Finally, you can use this report to estimate the critical amount of main section visibility that a brand needs before retailers are willing to provide material out-of-section display support. Brands that are slowly gaining visibility in the main section often experience significant growth after hitting that tipping point. Competitive brands can use this report as an early warning signal.",
        ],
        yOffset: "-3em",
        coverage: true,
        img: "report_discoverability",
        background: "card-background_dot-graph",
        slug: "discoverability",
        legendRows: 3,
        filters: [L4WKL12WKL52WK],
        ppt: "svg",
      },
      {
        name: "On-shelf availability",
        summary:
          "Visualize how voids, phantom inventory, and other supply chain hiccups impact product availability at each retailer.",
        description: [
          "Even the most loyal shoppers can’t buy their favorite products if they're out of stock. And even when a product is “in stock”, if it isn't on the shelf and readily available to consumers, sales can still be lost. That's why product availability consistently shows up in consumer research as one of the top reasons why shoppers abandon their trips. Conversely, high on-shelf availability helps shoppers convert at the moment of sale and builds consumer trust around product reliability.",
          "At Shelfgram, we use the latest in machine learning to score every shelf observed in the photos for availability. There's a lot of complicated ways to measure both shelf capacity and product availability but to simplify things, we just use a 5 point scale. A score of 5/5 represents a fully in-stock shelf vs. a score of 1/5 represents a complete stock out.",
          "In this report, we provide you with a summary analysis of that data so you can discover on-shelf availability trends across retailers and categories.",
          "This allows retailers to measure and benchmark out-of-stocks while pinpointing problem areas. By identifying out-of-shelf products at the most granular level, brands and retailers now have the ability to identify and quantify lost sales, prioritize opportunities, and execute on the root causes of out-of-stock products across the supply chain.",
        ],
        coverage: true,
        img: "report_osa",
        background: "card-background_table",
        slug: "osa",
        ppt: "svg",
      },
    ],
  },
  {
    name: "Promotion",
    slug: "promotion",
    primaryColor: "#b3ecff",
    secondaryColor: "#B4CFEC",
    reports: [
      {
        name: "Share of display",
        summary:
          "Understand when and where brands are allocating their trade investment in-store.",
        description: [
          "The category's main section only has a limited amount of space, so if you're looking to drive incremental visibility, display is your best bet. Whether they’re shopping off a list or not, most consumers can be swayed by in-store marketing and displays create an important point of interruption to bring your brand to life during the shopping trip.",
          "Displays can help shoppers realize that a new product is available and convince them to switch brands or try something new. They're also a great way to educate shoppers about a brand or share the details of a new marketing campaign.",
          "At Shelfgram, we define a display as any temporary secondary location in the store outside of the main section where the category is regularly shelved. Some manufacturers invest in permanent display fixtures but since we're shopper focused, we consider these to be displays.",
          "This report tracks the share that each brand has out of the total displays observed. You can use it to understand when and where brands are allocating their trade investment in-store. Tracking timing is especially important for seasonal businesses and when you layer on sales data, you can unlock new insights around the extent to which competitive display activity in the category is a causal driver of sales performance.",
        ],
        yOffset: "-7em",
        coverage: true,
        img: "report_share-of-display",
        background: "card-background_line-graph",
        slug: "display_share",
        legendRows: 3,
        filters: [L52WKVSYAGO],
        ppt: "svg",
      },
      {
        name: "Display calendar",
        summary:
          "Understand each brand's display activity by retailer over time",
        description: [
          "In-store retail displays allow you to draw attention to specific merchandise, educate shoppers about your brand, and flex your creative muscles.",
          "However, when it comes to display effectiveness, brands face a lot of challenges. Displays aren't always executed properly by retailers and the timing of display activity can be miscalculated. These phasing issues can lead to lower conversion among shoppers because they stock up on other products from the category.",
          "This report gives brands a turnkey way to track the execution compliance of their visual merchandising strategy and compare it against other players in the category. You can also see how a brand's display timing aligns with the competition and coincides with relevant shopping events and seasons.",
        ],
        tips: "Click on a cell in the table to preview the display photos.",
        yOffset: "-7em",
        coverage: true,
        img: "report_display-calendar",
        background: "card-background_calendar-chart",
        slug: "display_calendar",
        filters: [
          [
            { name: "Weekly", id: "week" },
            { name: "Monthly", id: "month" },
          ],
          L52WKVSYAGO,
        ],
      },
      {
        name: "Front page comparison",
        summary:
          "Compare full page promotional images in a direct side-by-side comparison.",
        description: [
          "Sometimes, brands invest in circular ads but don't see the expected sales lift at that retailer. One of the reasons might be that shoppers decided to take their trip to another retailer because the overall offer quality was higher during the promotional period.",
          "The front page of a flyer usually indicates a retailer's promotional theme and is some of the most valuable advertising real-estate for that retailer. In a busy world, shoppers use circulars as an opportunity to view brands and prices from competing grocers with ease. This report shows you the side-by-side comparison that those shoppers are seeing when that critical decision is made.",
          dataSourceFlyers,
        ],
        tips: "Click on the thumbnails to open them up in the photo viewer!",
        img: "report_front-page",
        background: "card-background_feed-view",
        slug: "front_page",
        live: true,
        filters: [
          [
            { name: "Canada", id: "canada" },
            { name: "USA", id: "usa" },
          ],
        ],
      },
      {
        name: "Offer type by retailer",
        summary:
          "Understand promotional changes that could impact sales performance.",
        description: [
          "When investing in a circular ad, it's important to be strategic about picking your promotional tactic. For example, you may find that your brand experiences incremental sales when running a '% OFF' offer vs. a '$ OFF' or a BOGO, even if all of the offers delivers the same mathematical value to shoppers.",
          "This report shows you what kinds of offers are being used most frequently within your category and can help vendors make stronger recommendations around ad effectiveness.",
          "This is particularly useful when sales data is layered-in so you can understand the impact that offer type has on sales. For brands and retailers that are losing in a category, finding out how the competition is approaching the category can lead to a stronger promotional strategy.",
          dataSourceFlyers,
        ],
        yOffset: "-5em",
        img: "report_offer-type",
        background: "card-background_bar-chart",
        slug: "offer_type",
      },
      {
        name: "Brand ad quality",
        summary:
          "Determine a brand's ad grade based on block size, placement, and competition.",
        description: [
          "Not all ads that appear in a retailer circular are created equal. If you're looking to evaluate campaign effectiveness and find new ways to increase your return on investment, it's important to measure the quality of your ad relative to other ads competing for the same audience.",
          "This report provides a diagnostic tool that gives you a sense of how well your ad quality compares to other advertisers in the category so you can benchmark your tactics vs. the competition.",
          "We use a consistent methodology for scoring ads. It considers 3 key factors to deliver a standardized quality metric:",
          "1) Ad block size - the total number of pixels contained within the ad's surface area relative to the average ad block size in the circular",
          "2) Placement - the page number and page position where the ad block is located",
          "3) Competition - how many other brands in the category are on the same page or in the same circular",
          dataSourceFlyers,
        ],
        img: "report_brand-ad-quality",
        background: "card-background_bar-chart",
        slug: "brand_ad_quality",
      },
      {
        name: "Brand share of voice",
        summary: "Compare a brand's ad visibility based on ad frequency.",
        description: [
          "A significant portion of trade investment goes to paid ads in retailer circulars. So it's important to measure each brand's share of the total ads that ran in the category.",
          "Tracking your share of voice can help you understand how visible your brand is to shoppers. A higher share of voice usually leads to more brand awareness and ultimately, more sales.",
          "Since brands need to achieve enough sales growth to justify their ad investment, often there's an optimal share of voice in the category. Exploring this report helps you understand how shifts in promotional strategies cascaded into market share changes.",
          dataSourceFlyers,
        ],
        img: "report_share-of-voice",
        background: "card-background_bar-chart",
        slug: "sov",
      },
      {
        name: "Ad adjacencies",
        summary:
          "Determine which brands or categories are being cross-promoted together.",
        description: [
          "To maximize your return on investment with circular ads, it's important to identify which promotional characteristics drive purchase behavior. One of those factors is ad adjacencies, which refers to ads that contain multiple products within them. Usually, these products are meant to complement each other.",
          "This report helps you uncover the extent to which these cross-promotions impacts ad performance and ultimately sales. It can also help you align your portfolio strategy so that shoppers see the same brands together at each retail touchpoint: in the circular, the main section, and on display.",
          dataSourceFlyers,
        ],
        img: "report_ad-adjacencies",
        background: "card-background_radar-chart",
        slug: "ad_adjacencies",
        legendRows: 4,
        filters: [L52WKVSYAGO],
      },
      {
        name: "Ad promo calendar",
        summary:
          "Understand each brand's flyer investment by retailer over time",
        description: [
          "One of the reasons brands don't see the expected sales lift of an ad investment in a retailer's circular is that a key competitor ran a hot deal the week prior and shoppers stocked up on category.",
          "Sometimes, a lazy competitor will repeat their business plan year over year, allowing savvy brand managers to 'out-phase' the competition by better organizing when, where and how their ads show up.",
          "This report gives brands a turnkey way to track the execution compliance of their ad strategy and compare it against other players in the category. You can also see how a brand's ad timing aligns with the competition and coincides with relevant shopping events and seasons.",
          dataSourceFlyers,
        ],
        yOffset: "-7em",
        img: "report_ad-calendar",
        background: "card-background_calendar-chart",
        slug: "ad_calendar",
      },
    ],
  },
];
