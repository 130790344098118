import { PureComponent } from "react";

import Loader from "components/loader/index";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 2em;
  display: flex;
  justify-content: center;
`;

class Loading extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Loader size="2em" />
      </Wrapper>
    );
  }
}

export default Loading;
