import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { PureComponent } from "react";

import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import { connect } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 12em;
  height: 10em;
  font-size: 0.8em;
  cursor: pointer;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) =>
    props.active ? props.theme.grey4 : props.theme.grey5};
  background: ${(props) => props.theme.white};
  border-radius: 0.5em;
  margin: 0.5em;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: ${(props) => props.theme.defaultTransition};
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  p {
    font-weight: bold;
    color: ${(props) => (props.active ? props.theme.grey1 : props.theme.grey2)};
  }
  svg {
    fill: ${(props) => (props.active ? props.theme.grey1 : props.theme.grey2)};
  }
  .layout-card {
    transition: ${(props) => props.theme.defaultTransition};
    background: ${(props) =>
      props.active ? props.theme.grey4 : props.theme.grey5};
  }
  &:hover {
    box-shadow: ${(props) =>
      props.active
        ? props.theme.subtleBoxShadow
        : props.theme.sortOfSubtleBoxShadow};
    border-color: ${(props) => props.theme.grey4};
    .layout-card {
      background: ${(props) => props.theme.grey4};
    }
    p {
      color: ${(props) => props.theme.grey1};
    }
    svg {
      fill: ${(props) => props.theme.grey1};
    }
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  width: 100%;
`;
const Fader = styled.div`
  width: 100%;
  height: 5em;
  position: absolute;
  bottom: 0;
  background: ${(props) =>
    `linear-gradient(transparent, ${
      props.darkMode ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.6)"
    })`};
`;
const Title = styled.p`
  width: 100%;
  margin: 0;
  text-align: left;
`;

class Option extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cardsToRender: Array.from(Array(this.props.cards).keys()),
    };
  }

  render() {
    return (
      <Wrapper active={this.props.active} onClick={this.props.selectLayout}>
        <Header>
          <Title>{this.props.title}</Title>
          {this.props.active ? (
            <FaCheckCircle size="1em" />
          ) : (
            <FaRegCircle size="1em" />
          )}
        </Header>
        {this.state.cardsToRender.map((x) => (
          <div
            className="layout-card"
            key={`${this.props.size}-${x}-card`}
            style={{
              borderRadius: `${this.props.size / 8}em`,
              height: this.props.wide ? "2em" : `${this.props.size}em`,
              width: this.props.wide ? "100%" : `${this.props.size}em`,
              margin: `${this.props.size / 8}em`,
            }}
          />
        ))}
        <DesktopOrTablet
          content={<Fader darkMode={this.props.dataUser.dark_mode} />}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
});

export default connect(mapStateToProps, null)(Option);
