import { PureComponent } from "react";
import styled from "styled-components";

import { FaArrowUp } from "react-icons/fa";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";

const Wrapper = styled.div`
  display: flex;
  margin: ${(props) => (props.margin ? props.margin : "0 0.25em")};
  font-size: 0.8em;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: ${(props) => props.theme.fastTransition};
  min-height: 1.5em;
  min-width: 1.5em;
  max-width: 1.5em;
  max-height: 1.5em;
  background: ${(props) =>
    `linear-gradient(${props.theme.midYellow},${props.theme.deepYellow})`};
  svg {
    fill: ${(props) => props.theme.white} !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 1em;
    width: 1em;
    transition: ${(props) => props.theme.fastTransition};
  }
`;

export default class UpgradeIcon extends PureComponent {
  render() {
    return (
      <Wrapper className="upgradeIcon" margin={this.props.margin}>
        <Tooltip
          unmountHTMLWhenHide
          html={
            <TooltipText>
              This feature is included in Shelfgram Enterprise
            </TooltipText>
          }
          position="right"
          width="auto"
        >
          <FaArrowUp />
        </Tooltip>
      </Wrapper>
    );
  }
}
