import { useEffect, useRef, memo, useMemo } from "react";
import styled, { keyframes, css } from "styled-components";
import Chip from "../chip";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedChipsWrapperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
`;

const ChipAnimationWrapper = styled.div`
  ${(props) =>
    props.stagger
      ? css`
          opacity: 0;
          animation: ${fadeIn} 0.5s ease-out forwards;
          animation-delay: ${props.delay}s;
        `
      : css`
          opacity: 1;
        `}
`;

const AnimatedChipsWrapper = ({ chips }) => {
  const previousChipsRef = useRef([]);
  const previousChips = previousChipsRef.current;

  const newChips = useMemo(
    () =>
      chips.filter(
        (chip) =>
          !previousChips.some((prevChip) => prevChip.label === chip.label)
      ),
    [chips, previousChips]
  );

  useEffect(() => {
    previousChipsRef.current = chips;
  }, [chips]);

  return (
    <AnimatedChipsWrapperContainer>
      {chips.map((chip, index) => {
        const isNew = newChips.some((newChip) => newChip.label === chip.label);
        const staggerIndex = newChips.findIndex(
          (newChip) => newChip.label === chip.label
        );

        const chipKey = chip.label || `chip-${index}`;

        return (
          <ChipAnimationWrapper
            key={chipKey}
            stagger={isNew}
            delay={isNew ? staggerIndex * 0.2 : 0}
          >
            <Chip
              label={chip.label}
              img={chip.img}
              backgroundColor={chip.color}
            />
          </ChipAnimationWrapper>
        );
      })}
    </AnimatedChipsWrapperContainer>
  );
};

export default memo(AnimatedChipsWrapper);
