import { PureComponent } from "react";

import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`;
const Preview = styled.div`
  padding: 1em;
  opacity: 0.8;
  max-width: 25em;
  border: 1px solid ${(props) => props.theme.grey2};
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  margin: 0.25em 1em;
  background: ${(props) =>
    props.darkMode
      ? props.theme.white
      : `linear-gradient(45deg, ${props.theme.veryLightGrey}, white, ${props.theme.veryLightGrey})`};
  ${media.mobile`
    flex-wrap: wrap;
    flex-direction: column-reverse;
  `}
`;
const PreviewImage = styled.div`
  min-width: 10em;
  height: 8em;
  border-radius: 0.25em;
  animation: background_color_change 2s infinite;
  background-size: cover;
  background-position: center;
  ${media.mobile`
    width: 100%;
  `}
`;
const P = styled.p`
  font-size: 0.75em;
  color: ${(props) => props.theme.grey2};
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  margin: 0 1em;
  padding: 0.5em 1em 0.5em 1em;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1em;
  ${media.mobile`
    padding: 1em 0;
  `}
`;
const H3 = styled.h3`
  margin: 0;
  width: 100%;
  font-weight: bold;
  text-align: left;
  color: ${(props) => props.theme.grey2};
`;
const Description = styled.p`
  color: ${(props) => props.theme.grey3};
  font-weight: bold;
  text-align: left;
  padding-top: 1em;
  margin: 0;
`;

class SharePreview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      preview: false,
    };
  }

  componentDidMount() {
    if (this.props.image) {
      return;
    } else {
      fetch(
        this.props.url
          ? `${window.location.origin}/og_preview?path=${this.props.url}`
          : `${window.location.origin}/og_preview?path=${window.location.href}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (this.props.showEmbedButton) {
            this.props.showEmbedButton();
          }
          this.setState({
            preview: data,
          });
        });
    }
  }
  render() {
    if (!this.state.preview && !this.props.image) {
      return null;
    }
    return (
      <Wrapper>
        <P>Preview</P>
        <a href={this.props.url} target="_blank" rel="noreferrer noopener">
          <Preview darkMode={this.props.dataUser.dark_mode}>
            <PreviewImage
              style={{
                backgroundImage: `url("${
                  this.props.image ? this.props.image : this.state.preview.image
                }")`,
              }}
            />
            <Flex>
              <H3>
                {this.props.title ? this.props.title : this.state.preview.name}
              </H3>
              <Description>
                {this.props.description
                  ? this.props.description
                  : this.state.preview.description}
              </Description>
            </Flex>
          </Preview>
        </a>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
});

export default connect(mapStateToProps, null)(SharePreview);
