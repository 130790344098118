export var questionCodeLinkRows = [
    {
        title: "Categories",
        linkType: "categories",
        selectorType: "categories",
        route: "category",
    },
    {
        title: "Tags",
        linkType: "tags",
        selectorType: "tags",
        route: "tag"
    },
    {
        title: "Collections",
        linkType: "collections",
        selectorType: "curated_collections",
        route: "collection"
    },
    {
        title: "Private Spaces",
        linkType: "private_collections",
        selectorType: "private_collections",
        route: "private_collection"
    }
];
export var questionCodeMetadataRows = [
    {
        title: "Access Type",
        source: 'access_types',
        field: "access_type_id"
    },
    {
        title: "Photo Source",
        source: "photo_sources",
        field: "photo_source_id"
    }
];
export var FA_PRIVATE_SPACE = {
    id: "EaKejhOe",
    value: "Field Agent - Retail Pipelines",
    name: "Field Agent - Retail Pipelines",
    img: "5d7eb2cc-9d94-57f3-91fd-21b6e2ff627d",
    source: "companies",
};
