var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var StyledQuestionCode = {
    Wrapper: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: flex;\n      justify-content: center;\n      height: ", ";\n    "], ["\n      display: flex;\n      justify-content: center;\n      height: ", ";\n    "])), function (props) { return !props.$isNew && "100%"; }),
    Container: styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      border-radius: 1em;\n      box-shadow: ", ";\n      background: ", ";\n      margin: 0.5em;\n      padding: 1em;\n      display: flex;\n      align-items: center;\n      align-content: flex-start;\n      justify-content: center;\n      flex-wrap: wrap;\n      width: ", ";\n    "], ["\n      border-radius: 1em;\n      box-shadow: ", ";\n      background: ", ";\n      margin: 0.5em;\n      padding: 1em;\n      display: flex;\n      align-items: center;\n      align-content: flex-start;\n      justify-content: center;\n      flex-wrap: wrap;\n      width: ", ";\n    "])), function (props) { return props.theme.subtleBoxShadow; }, function (props) { return props.theme.white; }, function (props) { return props.$isNew ? "100%" : "24em"; }),
    Header: styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      width: 100%;\n      margin: auto;\n    "], ["\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      width: 100%;\n      margin: auto;\n    "]))),
    TitleContainer: styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      width: 100%;\n      max-width: 100%;\n      padding: 10px;\n      box-sizing: border-box;\n      svg {\n        cursor: pointer;\n      }\n    "], ["\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: center;\n      width: 100%;\n      max-width: 100%;\n      padding: 10px;\n      box-sizing: border-box;\n      svg {\n        cursor: pointer;\n      }\n    "]))),
    H2: styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      margin: 0;\n      padding-top: 0.5em;\n      text-align: center;\n    "], ["\n      margin: 0;\n      padding-top: 0.5em;\n      text-align: center;\n    "]))),
    TextArea: styled.textarea(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      width: 100%;\n      height: 3em;\n      text-align: center;\n      align-content: center;\n      font-size: medium;\n      border-radius: 1em;\n      border: ", ";\n      text-transform: uppercase;\n    "], ["\n      width: 100%;\n      height: 3em;\n      text-align: center;\n      align-content: center;\n      font-size: medium;\n      border-radius: 1em;\n      border: ", ";\n      text-transform: uppercase;\n    "])), function (_a) {
        var $isDuplicate = _a.$isDuplicate, theme = _a.theme;
        return $isDuplicate && "2px solid ".concat(theme.deepRed);
    }),
    Error: styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      color: ", ";\n      text-align: center;\n      width: 100%;\n    "], ["\n      color: ", ";\n      text-align: center;\n      width: 100%;\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.midRed;
    }),
    ButtonsContainer: styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      width: 100%;\n      display: flex;\n      justify-content: ", ";\n\n      button {\n        width: 7em;\n      }\n    "], ["\n      width: 100%;\n      display: flex;\n      justify-content: ", ";\n\n      button {\n        width: 7em;\n      }\n    "])), function (props) { return props.$isNew ? "flex-end" : "center"; }),
    MetadataPills: styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      width: 100%;\n      padding-bottom: 0.5em;\n      li {\n        cursor: auto;\n      }\n    "], ["\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      width: 100%;\n      padding-bottom: 0.5em;\n      li {\n        cursor: auto;\n      }\n    "]))),
    MetadataType: styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      color: ", ";\n      text-align: left;\n    "], ["\n      color: ", ";\n      text-align: left;\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.grey2;
    }),
    TooltipDescription: styled.p(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      text-align: left;\n      margin-left: 0.75em;\n      margin-bottom: 0;\n      width: 100%;\n    "], ["\n      text-align: left;\n      margin-left: 0.75em;\n      margin-bottom: 0;\n      width: 100%;\n    "]))),
    SummaryList: styled.ul(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      text-align: left;\n      padding-left: 2em;\n    "], ["\n      text-align: left;\n      padding-left: 2em;\n    "]))),
    MetadataSelectors: styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: flex-start;\n      width: 100%;\n      margin: auto;\n    "], ["\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: flex-start;\n      width: 100%;\n      margin: auto;\n    "]))),
    LinkPills: styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      padding: 0.5em;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-wrap: wrap;\n      width: 100%;\n    "], ["\n      padding: 0.5em;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-wrap: wrap;\n      width: 100%;\n    "]))),
};
export var getSummaryTooltipStyle = function (questionCode) { return ({
    position: "absolute",
    right: "".concat(Math.floor(-((questionCode.name.length - 8.25) / 2)) + 8.25, "em"),
    top: "3.35em",
}); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
