var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import Pill from "components/pill";
import SimplePill from "components/simple-pill";
import AltCTA from "components/buttons/alternate";
import MainCTA from "components/buttons/main";
import TextBox from "components/text-box";
import Loader from "components/loader";
import { asArray } from "shared/helpers/asArray";
import { useTheme } from "styled-components";
import { useApiCall } from "hooks/useApiCall";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getImageSource } from "shared/helpers/image";
import { MetadataSelectors } from "components/question-code/MetadataSelectors/MetadataSelectors";
import { FA_PRIVATE_SPACE, questionCodeLinkRows, questionCodeMetadataRows } from "./QuestionCode.config";
import { selectQuestionCodeById, selectQuestionCodeMetadata, selectIsValidQuestionCodeName, newQuestionCodeRequested, questionCodeUpdated, } from "@redux/slices/adminPortalSlice";
import { FaPen, FaQuestionCircle, FaSave, FaTrash } from "react-icons/fa";
import { FaFileCirclePlus } from "react-icons/fa6";
import { FullWidth, Img, TooltipText } from "constants/styled-components";
import { getSummaryTooltipStyle, StyledQuestionCode as Styled } from "./QuestionCode.styled";
import { hideModal, showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";
import { LinkSelector } from "components/question-code/LinkSelector/LinkSelector";
import { newPipelineRequested, selectPendingPipelineForQuestion } from "@redux/slices/curatedCollectionsSlice";
import { CreatePipelineCollection } from "components/modal/create-pipeline-collection/CreatePipelineCollection";
import { partnerCountryMapping } from "constants/variables";
import Tooltip from "components/tooltip";
export var QuestionCode = function (_a) {
    var questionId = _a.questionId, measure = _a.measure;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var isNewQuestionCode = !questionId;
    var _b = useState(''), validationError = _b[0], setValidationError = _b[1];
    var _c = useState(isNewQuestionCode), isEditing = _c[0], setIsEditing = _c[1];
    var _d = useState(isNewQuestionCode), hasUnsavedChanges = _d[0], setHasUnsavedChanges = _d[1];
    var metadata = useAppSelector(selectQuestionCodeMetadata);
    var questionCode = useAppSelector(function (state) { return selectQuestionCodeById(state, questionId); });
    var _e = useState(__assign({}, questionCode)), localQuestionCode = _e[0], setLocalQuestionCode = _e[1];
    var pendingPipeline = useAppSelector(function (state) { return selectPendingPipelineForQuestion(state, localQuestionCode.id); });
    var isValidName = useAppSelector(function (state) { return selectIsValidQuestionCodeName(state, localQuestionCode.name, isNewQuestionCode); });
    var apiCallComponent = isNewQuestionCode ? "CREATE_QUESTION_CODE" : "UPDATE_QUESTION_CODE_".concat(questionId);
    var _f = useApiCall(apiCallComponent), apiCall = _f.apiCall, resetApiCall = _f.resetApiCall, isSaving = _f.isFetching, isErrorSaving = _f.isError, isSaved = _f.isSuccess;
    var accessType = useMemo(function () { var _a; return (_a = metadata === null || metadata === void 0 ? void 0 : metadata.access_types[localQuestionCode.access_type_id]) === null || _a === void 0 ? void 0 : _a.name; }, [metadata === null || metadata === void 0 ? void 0 : metadata.access_types, localQuestionCode.access_type_id]);
    var sourceCountryId = useMemo(function () { var _a; return (_a = metadata === null || metadata === void 0 ? void 0 : metadata.photo_sources[localQuestionCode.photo_source_id]) === null || _a === void 0 ? void 0 : _a.country_id; }, [metadata === null || metadata === void 0 ? void 0 : metadata.photo_sources, localQuestionCode.photo_source_id]);
    var canSave = useMemo(function () { return !validationError && !!localQuestionCode.name.trim() && !!accessType && !!localQuestionCode.photo_source_id; }, [validationError, accessType, localQuestionCode]);
    useEffect(function () {
        if (isSaved) {
            if (isNewQuestionCode) {
                dispatch(hideModal());
            }
            else {
                dispatch(questionCodeUpdated(localQuestionCode));
                setHasUnsavedChanges(false);
                setIsEditing(false);
            }
        }
    }, [isSaved]);
    useEffect(function () {
        if (!isValidName && localQuestionCode.name.trim()) {
            setValidationError("A question code with the name \"".concat(localQuestionCode.name, "\" already exists. Please choose a different name."));
        }
        else if (accessType === "Private" && localQuestionCode.private_collections.length !== 1) {
            setValidationError("Question codes with the \"Private\" type must be linked to exactly one private space");
        }
        else {
            setValidationError('');
        }
        if (isErrorSaving)
            resetApiCall();
    }, [isEditing, isValidName, accessType, localQuestionCode]);
    useEffect(function () {
        var private_collections = localQuestionCode.private_collections;
        var faSpaceId = FA_PRIVATE_SPACE.id;
        if (accessType === "Syndicated" && private_collections.length) {
            if (!private_collections.find(function (pc) { return pc.id === faSpaceId; })) {
                handleAddLink("private_collections", FA_PRIVATE_SPACE);
            }
            else if (private_collections.length === 1) {
                handleRemoveLink("private_collections", faSpaceId);
            }
        }
    }, [accessType, localQuestionCode.private_collections]);
    useEffect(function () {
        if (pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.id) {
            var collectionLink = __assign(__assign({}, pendingPipeline), { value: pendingPipeline.label });
            handleAddLink("collections", collectionLink);
        }
    }, [pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.id]);
    useEffect(function () {
        if (typeof measure === 'function')
            measure(); // Remeasure whenever component size may change
    }, [isEditing, localQuestionCode, validationError, isSaving, isErrorSaving]);
    var handleChangeMandatoryField = function (key, value) {
        setLocalQuestionCode(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[key] = value, _a)));
        });
        setHasUnsavedChanges(true);
    };
    var handleAddLink = function (linkType, data) {
        var isSingleSelector = linkType === "private_collections" && accessType === "Private";
        var _a = asArray(data)[0], id = _a.id, name = _a.value, img = _a.img, source = _a.source;
        if (!localQuestionCode[linkType].find(function (link) { return link.id === id; })) {
            setLocalQuestionCode(function (prev) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[linkType] = (isSingleSelector ? [] : prev[linkType]).concat({ id: id, name: name, img: img, source: source }), _a)));
            });
            setHasUnsavedChanges(true);
        }
    };
    var handleRemoveLink = function (linkType, linkId) {
        setLocalQuestionCode(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[linkType] = prev[linkType].filter(function (link) { return link.id !== linkId; }), _a)));
        });
        setHasUnsavedChanges(true);
    };
    var handleSave = function () {
        if (!hasUnsavedChanges) {
            setIsEditing(false);
            return;
        }
        var id = localQuestionCode.id, data = __rest(localQuestionCode, ["id"]);
        if (isNewQuestionCode) {
            dispatch(newQuestionCodeRequested(localQuestionCode));
        }
        apiCall({
            method: "POST",
            component: apiCallComponent,
            route: "admin/question_code/update",
            body: __assign(__assign({}, data), { question_id: id, name: data.name.trim(), tags: data.tags.map(function (x) { return x.id; }), categories: data.categories.map(function (x) { return x.id; }), collections: data.collections.map(function (x) { return x.id; }), private_collections: accessType === "Public" ? [] : data.private_collections.map(function (x) { return x.id; }) })
        });
    };
    var handleDelete = function () {
        if (!isNewQuestionCode) {
            dispatch(showModal({
                component: Modals.DELETE_QUESTION_CODE,
                data: { questionId: questionId }
            }));
        }
    };
    var handleCancel = function () {
        if (isNewQuestionCode) {
            dispatch(hideModal());
        }
        else {
            setLocalQuestionCode(__assign({}, questionCode));
            if (isErrorSaving)
                resetApiCall();
            setHasUnsavedChanges(false);
            setIsEditing(false);
        }
    };
    var handleCreatePipeline = function (inputValue) {
        dispatch(newPipelineRequested({
            questionId: localQuestionCode.id,
            initialName: inputValue,
        }));
        if (!isNewQuestionCode) { // Need to render modal directly for new questions to avoid unmounting
            dispatch(showModal({
                component: Modals.CREATE_PIPELINE_COLLECTION,
                data: { presetCountryId: sourceCountryId }
            }));
        }
    };
    if (isNewQuestionCode && pendingPipeline) {
        return (_jsx(CreatePipelineCollection, { presetCountryId: sourceCountryId }));
    }
    return questionCode && metadata && (_jsx(Styled.Wrapper, { "$isNew": isNewQuestionCode, children: _jsxs(Styled.Container, { "$isNew": isNewQuestionCode, children: [_jsxs(Styled.Header, { children: [_jsxs(Styled.TitleContainer, { children: [isNewQuestionCode ? (_jsx(Styled.TextArea, { value: localQuestionCode.name, placeholder: "Enter the question code...", "$isDuplicate": !!localQuestionCode.name.trim() && !isValidName, onChange: function (e) { return handleChangeMandatoryField("name", e.target.value.toUpperCase()); } })) : (_jsxs(_Fragment, { children: [_jsx(Styled.H2, { children: localQuestionCode.name }), localQuestionCode.summary && (_jsx(Tooltip, { width: "auto", position: "top", unmountHTMLWhenHide: true, style: getSummaryTooltipStyle(localQuestionCode), html: _jsx(TooltipText, { maxWidth: "400px", children: _jsx("p", { children: localQuestionCode.summary }) }), children: _jsx(FaQuestionCircle, { className: "override-fill" }) }))] })), validationError && (_jsx(Styled.Error, { children: validationError }))] }), !isEditing && (_jsx(Styled.MetadataPills, { children: questionCodeMetadataRows.map(function (_a) {
                                var source = _a.source, field = _a.field;
                                return (_jsx(SimplePill, { highlighted: true, name: metadata[source][questionCode[field]].name, icon: source === "photo_sources" && (_jsx(Img, { src: getImageSource(partnerCountryMapping[metadata[source][questionCode[field]].country_id]) })) }, source));
                            }) })), _jsx(Styled.ButtonsContainer, { "$isNew": isNewQuestionCode, children: isEditing ? (_jsxs(_Fragment, { children: [_jsx(AltCTA, { text: "Cancel", clickAction: handleCancel }), _jsx(MainCTA, { text: isNewQuestionCode ? "Create" : "Save", background: isNewQuestionCode && theme.midGreen, backgroundHover: isNewQuestionCode && theme.deepGreen, icon: isNewQuestionCode
                                            ? _jsx(FaFileCirclePlus, { className: "override-fill", fill: "white", size: "0.9em" })
                                            : _jsx(FaSave, { className: "override-fill", fill: "white", size: "0.9em" }), clickAction: handleSave, disabled: !canSave })] })) : (_jsxs(_Fragment, { children: [_jsx(MainCTA, { text: "Delete", background: theme.midRed, backgroundHover: theme.deepRed, icon: _jsx(FaTrash, { className: "override-fill", fill: "white", size: "0.8em" }), clickAction: handleDelete }), _jsx(MainCTA, { text: "Edit", icon: _jsx(FaPen, { className: "override-fill", fill: "white", size: "0.8em" }), clickAction: function () { return setIsEditing(true); } })] })) }), isSaving ? (_jsx(Loader, { size: "1.5em" })) : isEditing && isErrorSaving && (_jsx(Styled.Error, { children: "An error occurred while saving. Please try again." }))] }), isEditing && (_jsx(MetadataSelectors, { localQuestionCode: localQuestionCode, onSelect: handleChangeMandatoryField })), questionCodeLinkRows
                    .filter(function (row) { return accessType !== "Public" || row.linkType !== "private_collections"; })
                    .map(function (_a) {
                    var linkType = _a.linkType, selectorType = _a.selectorType, title = _a.title, route = _a.route;
                    return (_jsx(TextBox, { showOverflow: true, title: title, minWidth: "100%", text: _jsxs(_Fragment, { children: [_jsx(Styled.LinkPills, { children: localQuestionCode[linkType].map(function (_a) {
                                        var name = _a.name, img = _a.img, source = _a.source, id = _a.id;
                                        return (isEditing ? (_jsx(SimplePill, { name: name, highlighted: true, deletePending: true, icon: _jsx(Img, { src: getImageSource({ source: source, img: img, name: name }) }), clickAction: function () { return handleRemoveLink(linkType, id); } }, id)) : (_jsx(Pill, { name: name, img: img, id: id, route: route, source: source, tab: "about", embedded: true }, id)));
                                    }) }), isEditing && (_jsx(FullWidth, { children: _jsx(LinkSelector, { title: title, linkType: linkType, selectorType: selectorType, handleCreatePipeline: handleCreatePipeline, onSelect: function (data) { return handleAddLink(linkType, data); }, countryId: sourceCountryId }) }))] }) }, linkType));
                })] }) }));
};
