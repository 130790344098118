import { awsPrefix, useCompaniesS3Key } from "constants/variables";
import { avatarFromName } from "constants/functions";
export var getImageSource = function (_a) {
    var img = _a.img, source = _a.source, type = _a.type, name = _a.name;
    var imgSource = source || type;
    if (img && imgSource) {
        return "".concat(awsPrefix).concat(useCompaniesS3Key.includes(imgSource) ? "companies" : imgSource, "/").concat(img);
    }
    else if (name) {
        return avatarFromName(name);
    }
};
