import moment from "moment";
import ToggleTwoOptions from "components/toggle-two-options/index";
import { avatarFromName } from "constants/functions";
import { PureComponent } from "react";
import { awsPrefix } from "constants/variables";
import { connect } from "react-redux";
import styled from "styled-components";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components";
import { media } from "constants/responsive";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  ${media.tablet`
    flex-wrap: wrap;
  `}
  ${media.mobile`
    flex-wrap: wrap;
  `}
`;
const Options = styled.div`
  padding: 1em;
  width: 100%;
  display: flex;
  transition: ${(props) => props.theme.defaultTransition};
  flex-wrap: wrap;
  ${media.tablet`
    font-size: 0.8em;
  `}
  ${media.mobile`
    font-size: 0.8em;
  `}
`;
const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) =>
    props.hide ? "transparent" : props.theme.grey5};
`;
const Card = styled.div`
  margin: 0.5em;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background: ${(props) => props.theme.white};
  border-radius: 0.5em;
  border: 1px solid ${(props) => props.theme.grey5};
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  min-width: 315px;
  width: 325px;
`;
const CardContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  height: 2.5em;
  justify-content: flex-start;
  width: 100%;
  padding: 0.2em;
`;
const Img = styled.div`
  height: 100%;
  min-width: 3em;
  max-width: 3em;
  display: flex;
  border-radius: 3px 0px 0px 0px;
  overflow: hidden;
  background-position: center;
  background-size: 95%;
  background-image: url("${avatarFromName("r n")}");
  filter: opacity(0.85);
  background-repeat: no-repeat;
`;
const SubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.25em;
  position: relative;
  width: 100%;
`;
const TimeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25em;
`;
const Footnote = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${(props) => props.theme.grey2};
  width: 100%;
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  background-color: ${(props) => props.theme.grey4};
  background-image: ${`url("${awsPrefix}web-app/generic-card-preview.png")`};
  height: 265px;
`;
const RetailerName = styled.h3`
  font-weight: bold;
  font-size: 1.1em;
  margin: 0;
  user-select: none;
  color: ${(props) => props.theme.grey2};
`;
const Bullet = styled.span`
  padding-right: 0.5em;
  padding-left: 0.25em;
`;
const SubTitle = styled.p`
  text-align: left;
  color: ${(props) => props.theme.grey2};
`;
const Text = styled.span`
  animation: pulse 1s;
  border-radius: 2em;
  display: flex;
  align-items: center;
  padding-right: 0.25em;
  font-size: 0.7em;
`;

class CardLayoutSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dataUser.card_pref_city !==
        this.props.dataUser.card_pref_city ||
      prevProps.dataUser.card_pref_state !==
        this.props.dataUser.card_pref_state ||
      prevProps.dataUser.card_pref_country !==
        this.props.dataUser.card_pref_country ||
      prevProps.dataUser.card_pref_store !==
        this.props.dataUser.card_pref_store ||
      prevProps.dataUser.card_pref_time_ago !==
        this.props.dataUser.card_pref_time_ago
    ) {
      this.props.apiCall({
        method: "POST",
        component: "USER_SAVE_CARD_PREFS",
        body: {
          user_id: this.props.dataUser.id,
          card_pref_city: this.props.dataUser.card_pref_city,
          card_pref_state: this.props.dataUser.card_pref_state,
          card_pref_country: this.props.dataUser.card_pref_country,
          card_pref_store: this.props.dataUser.card_pref_store,
          card_pref_time_ago: this.props.dataUser.card_pref_time_ago,
          card_pref_share: this.props.dataUser.card_pref_share,
        },
        route: "user/card_preferences",
      });
    }
  }

  render() {
    return (
      <Wrapper>
        <CardContainer>
          <Card>
            <Header>
              <Img />
              <SubHeader>
                <RetailerName>Retailer Name</RetailerName>
                <Footnote>
                  {this.props.dataUser.card_pref_store && (
                    <Text>{`#210${
                      this.props.dataUser.card_pref_city ||
                      this.props.dataUser.card_pref_state ||
                      this.props.dataUser.card_pref_country
                        ? ","
                        : ""
                    }`}</Text>
                  )}
                  {this.props.dataUser.card_pref_city && (
                    <Text>{`City${
                      this.props.dataUser.card_pref_state ||
                      this.props.dataUser.card_pref_country
                        ? ","
                        : ""
                    }`}</Text>
                  )}
                  {this.props.dataUser.card_pref_state && (
                    <Text>{`State${
                      this.props.dataUser.card_pref_country ? "," : ""
                    }`}</Text>
                  )}
                  {this.props.dataUser.card_pref_country && (
                    <Text>Country</Text>
                  )}
                  {this.props.dataUser.card_pref_store ||
                  this.props.dataUser.card_pref_city ||
                  this.props.dataUser.card_pref_state ||
                  this.props.dataUser.card_pref_country ? (
                    <Bullet>•</Bullet>
                  ) : null}
                  {this.props.dataUser.card_pref_time_ago ? (
                    <Tooltip
                      unmountHTMLWhenHide
                      html={
                        <TooltipText>
                          {moment(this.state.currentDate).format("MMM D, YYYY")}
                        </TooltipText>
                      }
                      position="top"
                      width="auto"
                    >
                      <Text>6 minutes ago</Text>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      unmountHTMLWhenHide
                      html={<TooltipText>6 minutes ago</TooltipText>}
                      position="top"
                      width="auto"
                    >
                      <Text>
                        {moment(this.state.currentDate).format("MMM D, YYYY")}
                      </Text>
                    </Tooltip>
                  )}
                  {this.props.dataUser.card_pref_specific_time && (
                    <TimeContainer>
                      <Text>
                        {moment(this.state.currentDate)
                          .subtract(6, "minutes")
                          .format("h:mm a")}
                      </Text>
                    </TimeContainer>
                  )}
                </Footnote>
              </SubHeader>
            </Header>
            <Body />
          </Card>
        </CardContainer>
        <Options>
          <Section>
            <SubTitle>Country</SubTitle>
            <ToggleTwoOptions
              noPadding
              option2="Show"
              option1="Hide"
              offActionConstant="USER_SET_CARD_PREF_DISABLED_COUNTRY"
              onActionConstant="USER_SET_CARD_PREF_ENABLED_COUNTRY"
              toggledOn={this.props.dataUser.card_pref_country}
              minWidth="5em"
            />
          </Section>
          <Section>
            <SubTitle>State</SubTitle>
            <ToggleTwoOptions
              noPadding
              option2="Show"
              option1="Hide"
              offActionConstant="USER_SET_CARD_PREF_DISABLED_STATE"
              onActionConstant="USER_SET_CARD_PREF_ENABLED_STATE"
              toggledOn={this.props.dataUser.card_pref_state}
              minWidth="5em"
            />
          </Section>
          <Section>
            <SubTitle>City</SubTitle>
            <ToggleTwoOptions
              noPadding
              option2="Show"
              option1="Hide"
              offActionConstant="USER_SET_CARD_PREF_DISABLED_CITY"
              onActionConstant="USER_SET_CARD_PREF_ENABLED_CITY"
              toggledOn={this.props.dataUser.card_pref_city}
              minWidth="5em"
            />
          </Section>
          <Section>
            <SubTitle>Store number</SubTitle>
            <ToggleTwoOptions
              noPadding
              option2="Show"
              option1="Hide"
              offActionConstant="USER_SET_CARD_PREF_DISABLED_STORE"
              onActionConstant="USER_SET_CARD_PREF_ENABLED_STORE"
              toggledOn={this.props.dataUser.card_pref_store}
              minWidth="5em"
            />
          </Section>
          <Section>
            <SubTitle>Date</SubTitle>
            <ToggleTwoOptions
              noPadding
              option1="Specific date"
              option2="Time ago"
              offActionConstant="USER_SET_CARD_PREF_DISABLED_TIMEAGO"
              onActionConstant="USER_SET_CARD_PREF_ENABLED_TIMEAGO"
              toggledOn={this.props.dataUser.card_pref_time_ago}
              minWidth="5em"
            />
          </Section>
          <Section hide>
            <SubTitle>Time</SubTitle>
            <ToggleTwoOptions
              noPadding
              option2="Show"
              option1="Hide"
              offActionConstant="USER_SET_CARD_PREF_DISABLED_TIME"
              onActionConstant="USER_SET_CARD_PREF_ENABLED_TIME"
              toggledOn={this.props.dataUser.card_pref_specific_time}
              minWidth="5em"
            />
          </Section>
        </Options>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardLayoutSettings);
