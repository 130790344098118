import { memo } from "react";
import styled, { keyframes, css } from "styled-components";
import FauxCard from "../card";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedCardWrapperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
`;

const CardAnimationWrapper = styled.div`
  opacity: 0;
  ${(props) =>
    css`
      animation: ${fadeIn} 0.3s ease-out forwards;
      animation-delay: ${props.delay}s;
    `}
`;

const AnimatedCardWrapper = ({ cardData }) => {
  const wrapperKey = cardData.map((card) => card.name).join("-");

  return (
    <AnimatedCardWrapperContainer key={wrapperKey}>
      {cardData.map((card, index) => (
        <CardAnimationWrapper key={index} delay={index * 0.1}>
          <FauxCard
            primaryColor={card.primaryColor}
            name={card.name}
            backgroundIcon={card.backgroundIcon}
          />
        </CardAnimationWrapper>
      ))}
    </AnimatedCardWrapperContainer>
  );
};

export default memo(AnimatedCardWrapper);
