import { Container, DropdownOptions, LandingPageTitle, Mask } from "constants/styled-components";
import { PureComponent } from "react";
import { textColor } from "constants/functions";
import { fullWidth, styleFlexCenter } from "constants/variables";
import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import { FallbackUserImage } from "components/fallback-user-image/FallbackUserImage";
import { Link } from "react-router-dom";
import Mobile from "constants/responsive/mobile/index";
import { FaAngleDown } from "react-icons/fa";
import { connect } from "react-redux";
import styled from "styled-components";

const Icon = styled.img`
  width: 2em;
  height: 2em;
  border-radius: 100%;
  border: 0.5px solid rgba(250, 250, 250, 0.3);
  object-fit: contain;
  background: white;
  margin-left: 0.5em;
`;

const styles = {
  dropdownMobile: {
    margin: 0,
    height: "2.5em",
    width: "2.5em",
  },
  padding: { padding: "1em" },
};

class LandingPageMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.toggleCompanyTypeMenu = this.toggleCompanyTypeMenu.bind(this);
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
    this.toggleCompanyTypeToBrand = this.toggleCompanyTypeToBrand.bind(this);
    this.toggleCompanyTypeToRetailer =
      this.toggleCompanyTypeToRetailer.bind(this);
  }

  toggleCompanyTypeMenu() {
    this.props.setData({
      type: this.props.showFeedCompanyTypeMenu
        ? "HIDE_FEED_DROPDOWN_MENUS"
        : "SHOW_FEED_COMPANY_TYPE_MENU",
    });
  }

  toggleDropdownMenu() {
    this.props.setData({
      type: this.props.showFeedNavMenu
        ? "HIDE_FEED_DROPDOWN_MENUS"
        : "SHOW_FEED_NAV_MENU",
    });
  }

  toggleCompanyTypeToBrand() {
    this.props.setData({
      type: "HIDE_FEED_DROPDOWN_MENUS",
    });
    this.props.setData({
      type: "SHOW_FEED_AS_BRAND",
    });
    this.props.setData({
      type: "FEED_SETTINGS_PRESET",
      data: {
        filter: [
          [
            {
              match: "brands",
              is: "is",
              item: [
                {
                  id: this.props.dataLandingPageAbout.id,
                  img: this.props.dataLandingPageAbout.img,
                  name: this.props.dataLandingPageAbout.name,
                  fixed: true,
                },
              ],
            },
          ],
        ],
      },
    });
  }

  toggleCompanyTypeToRetailer() {
    this.props.setData({
      type: "HIDE_FEED_DROPDOWN_MENUS",
    });
    this.props.setData({
      type: "SHOW_FEED_AS_RETAILER",
    });
    this.props.setData({
      type: "FEED_SETTINGS_PRESET",
      data: {
        filter: [
          [
            {
              match: "retailers",
              is: "is",
              item: [
                {
                  id: this.props.dataLandingPageAbout.id,
                  img: this.props.dataLandingPageAbout.img,
                  name: this.props.dataLandingPageAbout.name,
                  fixed: true,
                },
              ],
            },
          ],
        ],
      },
    });
  }

  render() {
    return (
      <>
        <Link
          to={`/${this.props.menuType}/about`}
          style={{
            ...styleFlexCenter,
            pointerEvents: this.props.menuPages ? "all" : "none",
          }}
        >
          {this.props.dataUser &&
          (this.props.type === "saved-collection" ||
            this.props.type === "uploaded-collection") ? (
            <FallbackUserImage
              firstName={this.props.dataUser.first_name}
              lastName={this.props.dataUser.last_name}
              width="2em"
              height="2em"
              borderWidth="1px"
              marginRight="0.75em"
              marginLeft="0.25em"
              src={this.props.dataUser.photo}
            />
          ) : this.props.img ? (
            <Icon src={this.props.img} />
          ) : null}
          <LandingPageTitle
            style={{
              color: this.props.primaryColor
                ? textColor(this.props.primaryColor)
                : this.props.dataLandingPageAbout &&
                  this.props.dataLandingPageAbout.primary_color
                ? textColor(this.props.dataLandingPageAbout.primary_color)
                : "white",
            }}
          >
            {this.props.name ||
              (this.props.dataLandingPageAbout &&
                this.props.dataLandingPageAbout.name)}
          </LandingPageTitle>
        </Link>
        {this.props.dataLandingPageAbout &&
          this.props.dataLandingPageAbout.is_brand &&
          this.props.dataLandingPageAbout.is_retailer && (
            <Container onClick={this.toggleCompanyTypeMenu}>
              <LandingPageTitle>
                {this.props.dataLandingPageAbout &&
                this.props.dataLandingPageAbout.show_as_retailer
                  ? "Retailer"
                  : "Brand"}
              </LandingPageTitle>
              <FaAngleDown className="FaAngleDown" />
              {this.props.showFeedCompanyTypeMenu ? (
                <DropdownOptions
                  left="0"
                  primaryColor={
                    this.props.primaryColor ? this.props.primaryColor : null
                  }
                >
                  <Mask>
                    <Container
                      style={fullWidth}
                      onClick={
                        this.props.dataLandingPageAbout &&
                        this.props.dataLandingPageAbout.show_as_retailer
                          ? this.toggleCompanyTypeToBrand
                          : this.toggleCompanyTypeToRetailer
                      }
                    >
                      {this.props.dataLandingPageAbout &&
                      this.props.dataLandingPageAbout.show_as_retailer
                        ? "Brand"
                        : "Retailer"}
                    </Container>
                  </Mask>
                </DropdownOptions>
              ) : null}
            </Container>
          )}
        {!this.props.hideDropdown &&
        this.props.menuPages &&
        this.props.menuPages.length > 1 ? (
          <Container
            svgMarginRight="0"
            active={
              this.props.showFeedNavMenu ||
              (this.props.menuPages && this.props.menuPages.length == 0)
            }
            mobileAlign="center"
            onClick={
              this.props.menuPages && this.props.menuPages.length > 0
                ? this.toggleDropdownMenu
                : null
            }
          >
            <DesktopOrTablet
              content={
                <>
                  {this.props.imgInner && <Icon src={this.props.imgInner} />}
                  <LandingPageTitle>
                    {this.props.activeTab.includes("custom_view") &&
                    this.props.dataLandingPageAbout.custom_tabs &&
                    this.props.dataLandingPageAbout.custom_tabs[
                      this.props.activeTab.replace("custom_view_", "")
                    ].question_id
                      ? this.props.dataLandingPageAbout.custom_tabs[
                          this.props.activeTab.replace("custom_view_", "")
                        ].name
                      : this.props.activeTab === "feed"
                      ? "photos"
                      : this.props.activeTab === "planogram"
                      ? "planograms"
                      : this.props.activeTab.replace(/_/g, " ")}
                  </LandingPageTitle>
                </>
              }
            />
            <Mobile content={<FaAngleDown className="FaAngleDown" />} />
            <DesktopOrTablet
              content={
                this.props.menuPages && this.props.menuPages.length > 0 ? (
                  <FaAngleDown className="FaAngleDown" />
                ) : (
                  <span style={styles.padding} />
                )
              }
            />
            {this.props.menuPages &&
            this.props.menuPages.length > 0 &&
            this.props.showFeedNavMenu ? (
              <DropdownOptions
                left="0"
                primaryColor={
                  this.props.primaryColor ? this.props.primaryColor : null
                }
              >
                <Mask>
                  {this.props.menuPages
                    .filter(
                      (page) => page && page.slug !== this.props.activeTab
                    )
                    .map((x, i) => (
                      <Link
                        key={`${i}-${x}`}
                        to={`/${this.props.menuType}/${x.slug}`}
                        style={fullWidth}
                      >
                        <Container>
                          {x.trans ? this.props.translations[x.trans] : x.name}
                        </Container>
                      </Link>
                    ))}
                </Mask>
              </DropdownOptions>
            ) : null}
          </Container>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  showFeedNavMenu: state.showFeedNavMenu,
  showFeedCompanyTypeMenu: state.showFeedCompanyTypeMenu,
  dataLandingPageAbout: state.dataLandingPageAbout,
  router: state.router,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageMenu);
