import { PureComponent } from "react";

import Responsive from "react-responsive";

class DesktopOrTablet extends PureComponent {
  render() {
    return <Responsive minWidth={421}>{this.props.content}</Responsive>;
  }
}

export default DesktopOrTablet;
