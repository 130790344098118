import React, { PureComponent, Suspense, lazy } from "react";
import { maxSizeStyle, maxUploadSize } from "constants/variables/index";

import Loader from "components/loader/index";
import Dropzone from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { connect } from "react-redux";
import styled from "styled-components";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";

const UploadProgress = lazy(() =>
  import(
    /* webpackChunkName: "dynamic-UploadProgress" */ "components/upload-progress/index"
  )
);

const DropzonePreview = styled.div`
  background: rgba(250, 250, 250, 0.975);
  width: 100%;
  height: 100%;
  z-index: 999999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  position: relative;
  transition: ${(props) => props.theme.defaultTransition};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
`;
const IconContainer = styled.div`
  position: absolute;
  top: 0;
  margin-top: -2.5em;
  padding: 1em;
  border-radius: 100%;
  background: ${(props) => props.theme.grey2};
  animation: pulse 2s infinite;
  display: flex;
  svg {
    fill: white;
  }
`;
const H1 = styled.h1`
  color: ${(props) => props.theme.grey2};
`;

export let dropzoneRef = React.createRef();

const inputProps = {
  id: "shelfgram-upload-dropzone",
};

class Upload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      retailer: null,
    };
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
  }

  onDrop(uploads) {
    if (!this.props.dataUser || this.props.dataUser.plan_id === 1) {
      return;
    }
    if (
      this.props.router.location?.pathname?.split("/").pop() === "settings" &&
      !this.props.router.location?.pathname?.includes("user")
    ) {
      this.props.setData({ type: "HIDE_DROPZONE" });
      return;
    }
    this.props.setData({
      type: "PENDING_UPLOAD_DROP_PHOTOS",
      data: uploads.map(file => ({
        name: file.name,
        size: file.size,
        mimeType: file.type,
        blobUrl: file.preview,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
      }))
    });
    this.props.setData({ type: "HIDE_DROPZONE" });
    this.props.showModal({
      component: Modals.UPLOAD_PHOTOS,
    });
    this.props.setData({
      type: "HIDE_UPLOAD_PROGRESS",
    });
  }

  onDragEnter() {
    if (
      this.props.router.location?.pathname?.split("/").pop() === "settings" &&
      !this.props.router.location?.pathname?.includes("user")
    ) {
      return;
    }
    if (this.props.dataUser?.plan_id > 1 && !this.props.showDropzone) {
      this.props.setData({ type: "SHOW_DROPZONE" });
    }
  }

  onDragLeave() {
    if (this.props.showDropzone) {
      this.props.setData({ type: "HIDE_DROPZONE" });
    }
  }

  onDropRejected(file) {
    this.props.setData({ type: "HIDE_UPLOAD_PROGRESS" });
    const maxSize = Math.max(...file.map((x) => x.size));
    this.props.showModal({
      component: Modals.FAILED_UPLOAD,
      data: {
        reason: maxSize > maxUploadSize
          ? "fileSize"
          : "image"
      }
    });
  }

  render() {
    return (
      <Dropzone
        ref={(node) => {
          dropzoneRef = node;
        }}
        style={maxSizeStyle}
        accept={["image/*"]}
        minSize={0}
        maxSize={maxUploadSize}
        disableClick
        multiple
        inputProps={inputProps}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDropRejected={this.onDropRejected}
        className="Dropzone"
      >
        {this.props.showDropzone && (
          <DropzonePreview>
            <Container>
              <IconContainer>
                <FaCloudUploadAlt size="3em"/>
              </IconContainer>
              <H1>Drop to upload to Shelfgram</H1>
            </Container>
          </DropzonePreview>
        )}
        {this.props.showUploadProgress && (
          <Suspense fallback={<Loader/>}>
            <UploadProgress/>
          </Suspense>
        )}
        {this.props.children}
      </Dropzone>
    );
  }
}

const mapStateToProps = (state) => ({
  showDropzone: state.showDropzone,
  dataUser: state.dataUser,
  router: state.router,
  showUploadProgress: state.showUploadProgress,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
