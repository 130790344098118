import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import Loader from "components/loader/index";
import MainCTA from "components/buttons/main/index";
import { connect } from "react-redux";
import SelectButton from "../../components/industry-select";
import SelectablePill from "../../components/selectable-pill";
import {
  FaPen,
  FaBriefcase,
  FaBuilding,
  FaStore,
  FaSearch,
  FaAd,
  FaQrcode,
  FaChartBar,
  FaSearchDollar,
  FaBullhorn,
  FaList,
  FaBoxes,
  FaExclamationTriangle,
  FaGlobe,
  FaFileInvoiceDollar,
  FaChevronLeft,
} from "react-icons/fa";

const Input = styled.input`
  border-radius: 0.25em;
  padding: 1em;
  margin-bottom: 1em;
  border: 1px solid ${(props) => props.theme.grey4};
  width: ${(props) => (props.size === "half" ? "calc(50% - 4em)" : "100%")};
  margin-right: ${(props) => (props.size === "half" ? "1em" : "0")};

  &:nth-child(2) {
    margin-right: 0;
  }

  &::placeholder {
    color: ${(props) => props.theme.grey4};
  }
`;

const BackIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;

  z-index: 1000;
  color: ${(props) => props.theme.grey4};

  &:hover {
    color: ${(props) => props.theme.grey3};
  }
`;

const Center = styled.div`
  flex-wrap: wrap;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  animation: fadeIn 1s;
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 1em 0em;

  input {
    color: ${(props) => props.theme.grey1};
  }
`;

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const H2 = styled.h2`
  width: 100%;
  margin-top: 1em;
  color: ${(props) => props.theme.grey1};
`;

class ActivateAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      userCompanyName: "",
      mounted: false,
      selectedIndustry: "",
      selectedPills: [],
      pillData: [],
      domain: "",
      logo: "",
    };
    this.modifyFirstName = this.modifyFirstName.bind(this);
    this.modifyLastName = this.modifyLastName.bind(this);
    this.modifyuserCompanyName = this.modifyuserCompanyName.bind(this);
    this.handlePillToggle = this.handlePillToggle.bind(this);
    this.fetchCompanyLogo = this.fetchCompanyLogo.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleIndustrySelect = this.handleIndustrySelect.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.mounted && this.state.mounted) {
      this.inputRef && this.inputRef.focus();
    }
  }

  modifyFirstName(e) {
    const firstName = e.target.value;
    this.setState({ firstName });
    this.props.onNameChange(firstName, this.state.lastName);
  }

  modifyLastName(e) {
    const lastName = e.target.value;
    this.setState({ lastName });
    this.props.onNameChange(this.state.firstName, lastName);
  }

  handleIndustrySelect(industry) {
    this.setState({ selectedIndustry: industry }, () => {
      if (typeof this.props.onIndustryChange === "function") {
        this.props.onIndustryChange(industry);
      } else {
        console.warn("onIndustryChange is not a function");
      }
    });
  }

  handlePillToggle(pillLabel) {
    this.setState((prevState) => {
      const isSelected = prevState.selectedPills.includes(pillLabel);
      const selectedPills = isSelected
        ? prevState.selectedPills.filter((pill) => pill !== pillLabel)
        : [...prevState.selectedPills, pillLabel];

      this.props.onPillDataChange(selectedPills);

      return { selectedPills };
    });
  }

  modifyuserCompanyName(e) {
    const userCompanyName = e.target.value;
    this.setState({ userCompanyName });
  }

  fetchCompanyLogo(userCompanyName) {
    fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${userCompanyName}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          const selectedCompany = data[0];
          this.setState({
            domain: selectedCompany.domain,
            logo: selectedCompany.logo,
          });

          if (typeof this.props.onLogoChange === "function") {
            this.props.onLogoChange(selectedCompany.logo);
          }
        } else {
          this.setState({ domain: "", logo: "" });

          if (typeof this.props.onLogoChange === "function") {
            this.props.onLogoChange("");
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching company logo:", error);
        this.setState({ domain: "", logo: "" });

        if (typeof this.props.onLogoChange === "function") {
          this.props.onLogoChange("");
        }
      });
  }

  handleNextStep() {
    const { currentStep } = this.props;
    if (currentStep === 2) {
      this.fetchCompanyLogo(this.state.userCompanyName);
    }
    this.props.onNextStep();
  }

  renderStep() {
    const { currentStep } = this.props;

    const industryOptions = [
      {
        name: "Manufacturer",
        icon: <FaBuilding />,
        description: "Stay a step ahead at the shelf",
        gradient:
          "radial-gradient(circle at 100% -20%, #fc4e4e, #ff9800, #ff980000)",
      },
      {
        name: "Retailer",
        icon: <FaStore />,
        description: "Ensure optimal product placement",
        gradient:
          "radial-gradient(circle at 100% -20%, #369bff, #3d00ff, #3d00ff00)", // Example gradient
      },
      {
        name: "Broker",
        icon: <FaBriefcase />,
        description: "Connect brands with the right stores",
        gradient:
          "radial-gradient(circle at 100% -20%, #ff9750, #ff9750, #ff975000)", // Example gradient
      },
      {
        name: "Agency",
        icon: <FaPen />,
        description: "Create impactful marketing strategies",
        gradient:
          "radial-gradient(circle at 100% -20%, #1aaa4d, #369bff, #369bff00)", // Example gradient
      },
    ];

    const pillOptions = [
      { label: "Competitive Intelligence", icon: <FaSearch /> },
      { label: "Promotional Compliance", icon: <FaAd /> },
      { label: "Promotional Campaigns", icon: <FaQrcode /> },
      { label: "Category Insights", icon: <FaChartBar /> },
      { label: "Pricing Data", icon: <FaSearchDollar /> },
      { label: "Circulars", icon: <FaFileInvoiceDollar /> },
      { label: "Marketing", icon: <FaBullhorn /> },
      { label: "Shelf Availability", icon: <FaList /> },
      { label: "Product Rollout", icon: <FaBoxes /> },
      { label: "Product Recall", icon: <FaExclamationTriangle /> },
      { label: "International Markets", icon: <FaGlobe /> },
    ];

    switch (currentStep) {
      case 1:
        return (
          <>
            <H2>What do your colleagues call you?</H2>
            <Container>
              <Input
                placeholder="Your first name"
                value={this.state.firstName}
                onChange={this.modifyFirstName}
                disabled={this.props.fetchReducer.fetching}
                autoFocus
                ref={(input) => {
                  this.inputRef = input;
                }}
                size="half"
              />
              <Input
                placeholder="Your last name"
                value={this.state.lastName}
                disabled={this.props.fetchReducer.fetching}
                onChange={this.modifyLastName}
                size="half"
              />
            </Container>
          </>
        );

      case 2:
        return (
          <>
            <H2>What is the name of your company?</H2>
            <Container>
              <Input
                placeholder="Your company name"
                value={this.state.userCompanyName}
                onChange={this.modifyuserCompanyName}
                disabled={this.props.fetchReducer.fetching}
                autoFocus
              />
            </Container>
          </>
        );

      case 3:
        return (
          <>
            <H2>Select your industry</H2>
            <Container>
              {industryOptions.map((option) => (
                <SelectButton
                  key={option.name}
                  icon={option.icon}
                  title={option.name}
                  description={option.description}
                  selected={this.state.selectedIndustry === option.name}
                  gradient={option.gradient}
                  onClick={() => this.handleIndustrySelect(option.name)}
                />
              ))}
            </Container>
          </>
        );

      case 4:
        return (
          <>
            <H2>What are you looking for?</H2>
            <p>{this.state.selectedPills.length} selected</p>
            <PillContainer>
              {pillOptions.map((option) => (
                <SelectablePill
                  key={option.label}
                  icon={option.icon}
                  label={option.label}
                  selected={this.state.selectedPills.includes(option.label)}
                  onClick={() => this.handlePillToggle(option.label)}
                />
              ))}
            </PillContainer>
          </>
        );

      case 5:
        return (
          <>
            <H2>Schedule a Setup Call</H2>
            <p>
              Please choose a time that works to finish setting up your account
              with one of our success managers.
            </p>
            <div
              className="meetings-iframe-container"
              data-src="https://meetings.hubspot.com/shelfgram/dshelfgram?embed=true"
              style={{
                width: "100%",
                height: "100%",
                transition: "opacity 0.5s ease",
              }}
            ></div>
          </>
        );

      default:
        return null;
    }
  }

  render() {
    const { currentStep } = this.props;
    const submitting = this.props.fetchReducer.fetching;

    return (
      <Center>
        <BackIconWrapper onClick={() => this.props.onBack()}>
          <FaChevronLeft size={24} />
        </BackIconWrapper>
        <Container />
        <Wrapper>{this.renderStep()}</Wrapper>
        <Container>
          {submitting ? (
            <Loader />
          ) : (
            <>
              {currentStep < 5 && (
                <MainCTA
                  text="Next"
                  width="15em"
                  blue
                  disabled={
                    currentStep === 1
                      ? !(
                          this.state.firstName.length > 0 &&
                          this.state.lastName.length > 0
                        )
                      : currentStep === 2
                      ? this.state.userCompanyName.length === 0
                      : currentStep === 3
                      ? this.state.selectedIndustry.length === 0
                      : currentStep === 4
                      ? this.state.selectedPills.length === 0
                      : false
                  }
                  clickAction={this.handleNextStep}
                />
              )}
            </>
          )}
        </Container>
      </Center>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchReducer: state.fetchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(ActivateAccount)
);
