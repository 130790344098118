import { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import Logo from "components/logo";
import { FaSearch, FaUser } from "react-icons/fa";
import AnimatedCardWrapper from "./card-wrapper";
import AnimatedChipWrapper from "./chip-wrapper";

const fadeInSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const animateWidth = keyframes`
  0% {
    width: 80px;
  }
  100% {
    width: 65px;
  }
`;

const BrowserWindow = styled.div`
  width: 100%;
  max-width: 950px;
  border-radius: 10px;
  box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.037),
    0px 6.7px 5.3px rgba(0, 0, 0, 0.053), 0px 12.5px 10px rgba(0, 0, 0, 0.065),
    0px 22.3px 17.9px rgba(0, 0, 0, 0.077),
    0px 41.8px 33.4px rgba(0, 0, 0, 0.093), 0px 100px 80px rgba(0, 0, 0, 0.13);
  background-color: ${(props) => props.theme.white};
`;

const BrowserContent = styled.div`
  padding: 0.5em 1.2em;
  height: 350px;
  background-color: ${(props) => props.theme.grey5};
  border-radius: 0 0 6px 6px;
  overflow-y: auto;
  word-break: break-word;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.white};
  height: 35px;
  position: relative;
  border-radius: 10px 10px 0 0;
  margin-left: 1em;
  margin-right: 1em;
`;

const LogoWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 100px;
`;

const SGLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: left;
  position: relative;

  ${(props) =>
    props.isLoading === false &&
    css`
      animation: ${animateWidth} 0.3s ease-out forwards;
    `}
`;

const CompanyLogo = styled.div`
  margin-left: 0.8em;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 5px;
  border: 0.5px solid ${(props) => props.theme.grey5};
  background-size: contain;
  background-image: ${(props) => `url(${props.logo})`};
  background-color: ${(props) => props.theme.white};
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;

  ${(props) =>
    props.isLoading === false &&
    css`
      animation: ${fadeInSlideDown} 0.5s ease-out forwards;
    `}
`;

const CompanyText = styled.div`
  margin-left: 0.8em;
  width: 100px; // You can adjust this width for text
  font-size: 1em;
  color: ${(props) => props.theme.grey1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
`;

const AccountImage = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.grey5};
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
`;

const SearchBar = styled.div`
  display: flex;
  width: 80px;
  height: 18px;
  border-radius: 50px;
  margin-right: 8px;
  align-items: center;
  padding-left: 0.5em;
  background-color: ${(props) => props.theme.grey5};
`;

const UsernameHeader = styled.h2`
  font-size: 0.7em;
  font-weight: 700;
  color: ${(props) => props.theme.grey2};
`;

const letterAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedLetter = styled.span`
  display: inline-block;
  opacity: 0;
  ${(props) =>
    props.animate &&
    css`
      animation: ${letterAnimation} 0.2s ease-out forwards;
    `}
`;

const DashboardTitle = styled.h2`
  font-size: 1.5em;
  color: ${(props) => props.theme.grey2};
`;

const SectionTitle = styled.div`
  border-radius: 1em;
  width: ${(props) => {
    switch (props.size) {
      case "medium":
        return "90px";
      case "large":
        return "120px";
      default:
        return "60px";
    }
  }};
  height: 12px;
  background-color: ${(props) => props.theme.grey4};
  margin-top: 1em;
  margin-bottom: 1em;
`;

const BrowserMock = ({
  firstName,
  logo,
  userCompanyName,
  cardData,
  chipData,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (logo) {
      const img = new Image();
      img.src = logo;
      img.onload = () => setIsLoading(false);
      img.onerror = () => setIsLoading(false);
    } else {
      setIsLoading(false); // Ensure loading stops even if there's no logo
    }
  }, [logo]);

  // Split the firstName into an array of characters and animate them
  const animatedFirstName = firstName
    ? firstName.split("").map((char, index) => (
        <AnimatedLetter key={index} animate={true}>
          {char}
        </AnimatedLetter>
      ))
    : null;

  return (
    <BrowserWindow>
      <Header>
        <LogoWrapper>
          <SGLogoWrapper isLoading={isLoading}>
            <Logo />
          </SGLogoWrapper>
          {logo && !isLoading ? (
            <CompanyLogo logo={logo} isLoading={isLoading} />
          ) : (
            <CompanyText>{userCompanyName}</CompanyText>
          )}
        </LogoWrapper>

        <AccountWrapper>
          <SearchBar>
            <FaSearch size={8} color="grey" />
          </SearchBar>
          <AccountImage>
            <FaUser size={8} color="grey" />
          </AccountImage>
          {firstName && <UsernameHeader>{firstName}</UsernameHeader>}
        </AccountWrapper>
      </Header>

      <BrowserContent>
        {firstName ? (
          <DashboardTitle>Welcome, {animatedFirstName}!</DashboardTitle>
        ) : (
          <DashboardTitle>Your Dashboard</DashboardTitle>
        )}

        <AnimatedCardWrapper cardData={cardData} />
        <SectionTitle size="medium" />
        <AnimatedChipWrapper chips={chipData} />
      </BrowserContent>
    </BrowserWindow>
  );
};

export default BrowserMock;
