import { PureComponent } from "react";

import { FaInfoCircle } from "react-icons/fa";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import styled from "styled-components";

const Error = styled.span`
  color: ${(props) => props.theme.deepRed};
  width: 100%;
  animation: fadeIn 1s;
  text-align: center;
  padding-bottom: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const styles = {
  error: { paddingRight: "0.5em" },
};

export default class ErrorMessage extends PureComponent {
  render() {
    return (
      <Error>
        {this.props.error && (
          <span style={{ ...styles.error, ...this.props.style }}>
            {this.props.error}
          </span>
        )}
        {this.props.tooltip ? (
          <Tooltip
            unmountHTMLWhenHide
            html={<TooltipText>{this.props.tooltip}</TooltipText>}
            position="top"
            width="auto"
          >
            <FaInfoCircle />
          </Tooltip>
        ) : null}
      </Error>
    );
  }
}
