import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";

import PropTypes from "prop-types";
import Switch from "react-switch";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  display: inline-block;
  transition: ${(props) => props.theme.defaultTransition};
  margin: ${(props) => (props.margin ? props.margin : "0.5em 1em")};
  width: ${(props) => (props.width ? props.width : "auto")};
  .react-switch-bg {
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : "pointer"} !important;
  }
  .react-switch-bg svg {
    opacity: 0.5;
    align-self: center;
    transform: scale(0.8);
  }
  .react-switch-handle {
    cursor: ${(props) =>
      props.disabled ? "not-allowed" : "pointer"} !important;
  }
  &:hover {
    transform: scale(1.025);
  }
`;

class ToggleSwitch extends PureComponent {
  handleDisabledClick() {
    console.log("Toggle switch is disabled");
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.props.wrapperWidth}
        disabled={this.props.disabled}
      >
        <Switch
          onChange={
            this.props.disabled
              ? this.handleDisabledClick.bind(this)
              : this.props.clickAction
          }
          height={this.props.height ? this.props.height : 28}
          width={this.props.width ? this.props.width : 56}
          offHandleColor={this.props.theme.grey6}
          onHandleColor={this.props.theme.grey6}
          offColor={
            this.props.offColor ? this.props.offColor : this.props.theme.deepRed
          }
          onColor={
            this.props.onColor ? this.props.onColor : this.props.theme.midGreen
          }
          checkedIcon={this.props.checkedIcon}
          uncheckedIcon={this.props.uncheckedIcon}
          checked={this.props.toggledOn}
        />
      </Wrapper>
    );
  }
}

ToggleSwitch.propTypes = {
  toggledOn: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  toggledOn: true,
};

export default withTheme(ToggleSwitch);
