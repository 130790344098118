import { PureComponent } from "react";
import {
  FaMapMarkerAlt,
  FaCamera,
  FaCalendarAlt,
  FaInfoCircle,
} from "react-icons/fa";
import styled from "styled-components";
import BarChart from "./bar-chart/index";
import moment from "moment";
import { connect } from "react-redux";
import Loader from "components/loader/index";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { numberFormatter } from "constants/functions/index";

const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => props.blue ? props.theme.pastelBlue : props.theme.grey5};
  display: flex;
  padding: 1em;
  justify-content: center;
  box-sizing: border-box;
  flex-wrap: wrap;
`;
const H3 = styled.h3`
  width: 100%;
  text-align: center;
  font-weight: ${(props) => (props.light ? "normal" : "bold")};
  color: ${(props) => (props.light ? props.theme.grey3 : props.theme.grey2)};
  margin: 1em 0 0 0;
  padding: 1em;
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.5em 0.5em 1em 0.5em;
  svg {
    cursor: pointer;
    fill: ${(props) => props.theme.grey4};
  }
`;
const P = styled.p`
  font-weight: bold;
  margin: 0 0.5em 0 0 !important;
  text-align: center;
  color: ${(props) => props.theme.grey3} !important;
`;
const Section = styled.div`
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
`;
const SubText = styled.div`
  padding-top: 1em;
  width: 100%;
`;
const InfoHighlight = styled.div`
  padding: 2em 1em;
  width: 25%;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  background: ${(props) => props.theme.white};
  border-radius: 0.5em;
  color: ${(props) => props.theme.grey3};
  margin: 0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  strong {
    padding-top: 1em;
  }
  .coverageIcon {
    fill: ${(props) => props.theme.grey3};
    width: 100%;
    height: 2em;
  }
`;
const InfoGraphic = styled.div`
  padding: 1em;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  margin: 1em;
  display: flex;
  align-items: center;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  color: ${(props) => props.theme.grey2};
  border-radius: 0.5em;
  background: ${(props) => props.theme.white};
`;

class CoverageModel extends PureComponent {
  componentDidMount() {
    if (!this.props.dataLandingPageAnalytics.coverage_model) {
      this.props.apiCall({
        method: "GET",
        component: "COLLECTION_COVERAGE_MODEL",
        route: `collection/coverage_model/id/${this.props.dataLandingPageAbout.id}`,
      });
    }
  }

  render() {
    return (
      <Wrapper blue={this.props.blue}>
        {!this.props.dataLandingPageAnalytics.coverage_model ? (
          <Loader />
        ) : (
          <>
            <Section>
              <H3>Key stats</H3>
              <InfoHighlight>
                <FaCamera className="coverageIcon" />
                <strong>
                  {numberFormatter(
                    this.props.dataLandingPageAnalytics.coverage_model.key_stats
                      .num_photos
                  )}{" "}
                </strong>
                <SubText>Photos taken</SubText>
              </InfoHighlight>
              <InfoHighlight>
                <FaCalendarAlt className="coverageIcon" />
                <strong>
                  {moment(
                    this.props.dataLandingPageAnalytics.coverage_model.key_stats
                      .first_visit
                  ).format("MMMM D, YYYY")}
                </strong>
                <SubText>Date of first visit</SubText>
              </InfoHighlight>
              <InfoHighlight>
                <FaMapMarkerAlt className="coverageIcon" />
                <strong>
                  {numberFormatter(
                    this.props.dataLandingPageAnalytics.coverage_model.key_stats
                      .num_unique_locations
                  )}
                </strong>
                <SubText>Unique locations visited</SubText>
              </InfoHighlight>
            </Section>
            <Section>
              <H3>Coverage model</H3>
              <InfoGraphic>
                <Header>
                  <P>Retailers</P>
                  <Tooltip
                    width="auto"
                    unmountHTMLWhenHide
                    html={
                      <TooltipText>{`We've visited ${this.props.dataLandingPageAnalytics.coverage_model.breakdowns.retailers.length} important retailers in the category`}</TooltipText>
                    }
                    position="top"
                  >
                    <FaInfoCircle />
                  </Tooltip>
                </Header>
                <BarChart
                  data={
                    this.props.dataLandingPageAnalytics.coverage_model
                      .breakdowns.retailers
                  }
                />
              </InfoGraphic>
              <InfoGraphic>
                <Header>
                  <P>States</P>
                </Header>
                <BarChart
                  data={
                    this.props.dataLandingPageAnalytics.coverage_model
                      .breakdowns.states
                  }
                />
              </InfoGraphic>
              <InfoGraphic>
                <Header>
                  <P>Frequency</P>
                  <Tooltip
                    width="auto"
                    unmountHTMLWhenHide
                    html={
                      <TooltipText>
                        We try to balance the breadth and depth of coverage so
                        that you're seeing as many stores as possible while
                        longitudinally, you can also see the evolution of the
                        shelf at the same stores.
                      </TooltipText>
                    }
                    position="top"
                  >
                    <FaInfoCircle />
                  </Tooltip>
                </Header>
                <BarChart
                  data={
                    this.props.dataLandingPageAnalytics.coverage_model
                      .breakdowns.frequencies
                  }
                />
              </InfoGraphic>
              <InfoGraphic>
                <Header>
                  <P>Urbanization</P>
                  <Tooltip
                    width="auto"
                    unmountHTMLWhenHide
                    html={
                      <TooltipText>
                        We quota the visits so that you're seeing a small mix of
                        stores across different population densities.
                      </TooltipText>
                    }
                    position="top"
                  >
                    <FaInfoCircle />
                  </Tooltip>
                </Header>
                <BarChart
                  data={
                    this.props.dataLandingPageAnalytics.coverage_model
                      .breakdowns.urbanization
                  }
                />
              </InfoGraphic>
              <InfoGraphic>
                <Header>
                  <P>Day</P>
                </Header>
                <BarChart
                  data={
                    this.props.dataLandingPageAnalytics.coverage_model
                      .breakdowns.dow
                  }
                />
              </InfoGraphic>
            </Section>
            <Section>
              <H3 light>
                {`Last updated ${moment(
                  this.props.dataLandingPageAnalytics.coverage_model
                    .last_updated
                ).fromNow()}`}
              </H3>
            </Section>
          </>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverageModel);
