import styled from "styled-components";

const SelectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  border: 1px solid
    ${(props) => (props.selected ? props.theme.midBlue : props.theme.grey4)};
  border-radius: 12px;
  background-color: ${(props) => props.theme.white};
  position: relative; /* Needed for the pseudo-element positioning */
  box-shadow: ${(props) =>
    props.selected
      ? "0 2px 4px rgba(0, 0, 100, 0.3)"
      : "0 2px 4px rgba(0, 0, 0, 0.1)"};
  cursor: pointer;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;
  width: 100%; /* Ensure full width */
  margin-bottom: 1em; /* Add spacing between buttons */

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  /* Pseudo-element for gradient */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) => props.gradient};
    opacity: ${(props) =>
      props.selected ? 0.08 : 0}; /* Adjust opacity for a subtle gradient */
    transition: opacity 0.3s ease; /* Fade in effect */
    border-radius: 12px;
    pointer-events: none; /* Ensure the pseudo-element doesn’t affect button interaction */
  }
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.grey4};
  svg {
    fill: ${(props) => props.theme.grey4};
    width: 20px;
    height: 20px;
  }
`;

const Title = styled.h3`
  margin-bottom: 0.5em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 700;
  color: ${(props) => props.theme.grey1};
`;

const Description = styled.p`
  margin: 0;
  color: ${(props) => props.theme.grey3};
  font-size: 0.9em;
`;

const SelectButton = ({
  icon,
  title,
  description,
  onClick,
  selected,
  gradient,
}) => {
  return (
    <SelectButtonContainer
      onClick={onClick}
      selected={selected}
      gradient={gradient}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </SelectButtonContainer>
  );
};

export default SelectButton;
