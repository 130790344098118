import { PureComponent } from "react";
import styled from "styled-components";
import { awsPrefix } from "constants/variables/index";
import { Logo } from "components/analytics-reports/constants/styled-components/index";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
`;
const Section = styled.div`
  width: 100%;
  justify-content: flex-start;
  padding: 0.5em;
  background: ${(props) => (props.odd ? props.theme.grey6 : props.theme.white)};
  display: flex;
  box-sizing: border-box;
  align-items: center;
`;
const Name = styled.div`
  min-width: ${(props) => (props.minWidthName ? props.minWidthName : "15em")};
  max-width: 15em;
`;
const Score = styled.div`
  min-width: 3em;
  max-width: 3em;
  text-align: center;
`;
const BarContainer = styled.div`
  width: 100%;
`;
const Bar = styled.div`
  border-radius: 1em;
  height: 1em;
  background: ${(props) => props.theme.grey3};
  box-sizing: border-box;
`;

class BarChart extends PureComponent {
  render() {
    return (
      <Wrapper>
        {this.props.data.map((x, i) => (
          <Section key={`bar-${x.id}-${i}`} odd={i % 2 === 0}>
            {x.img ? (
              <Logo
                margin="0 0.5em 0 0"
                style={{
                  backgroundImage: `url("${awsPrefix}companies/${x.img}")`,
                }}
              />
            ) : null}
            <Name minWidthName={this.props.minWidthName}>{x.id}</Name>
            {this.props.hideLabels ? null : (
              <Score>{`${
                x.value > 0.02
                  ? Math.floor(x.value * 100)
                  : (x.value * 100).toFixed(1)
              }%`}</Score>
            )}
            <BarContainer>
              <Bar
                style={{
                  width: `${x.value * 100}%`,
                }}
              />
            </BarContainer>
          </Section>
        ))}
      </Wrapper>
    );
  }
}

export default BarChart;
