import * as JSZipUtils from "jszip-utils";

import JSZip from "jszip";
import moment from "moment";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";

function urlToPromise(url) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export function zipTheFeed(
  links,
  userName,
  feedData,
  finishedZippedFunc,
  exportType
) {
  toast.loading("Downloading...");
  var currentdate = new Date().toDateString();
  var zip = new JSZip();
  var zipFilename = `Shelfgram - ${exportType} Export - ${userName} - ${currentdate}.zip`;
  zip.file("README.txt", `Exported from ${window.location}\n`);
  links.forEach((url, i) => {
    var filename = feedData
      ? `${i + 1} - ${feedData[i].retailer.replace("/", "-")} - ${
          feedData[i].start_date && feedData[i].end_date
            ? moment(feedData[i].start_date).format("MMM D, YYYY") +
              " - " +
              moment(feedData[i].end_date).format("MMM D, YYYY") +
              " - "
            : ""
        }${feedData[i].city ? feedData[i].city + ", " : ""}${
          feedData[i].country ? feedData[i].country + " - " : ""
        }${
          feedData[i].date_taken
            ? moment(feedData[i].date_taken).format("MMM D, YYYY")
            : ""
        }${feedData[i].page_num ? "Page " + feedData[i].page_num : ""}.jpg`
      : `${exportType === "Flyers" ? "Flyer Page" : "Photo"} - ${i + 1}.jpg`;
    // loading a file and add it in a zip file
    zip.file(filename, urlToPromise(url), { binary: true });
    if (links.length == i + 1) {
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, zipFilename);
        toast.dismiss();
        finishedZippedFunc();
      });
    }
  });
}
