var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import React, { useEffect, useMemo, useState } from "react";
import { adminPlanId, partnerCountries, partnerCountryMapping, emptyArray } from "constants/variables";
import styled, { useTheme } from "styled-components";
import TextBox from "components/text-box";
import { Flex, FullWidth, Option } from "constants/styled-components";
import Selector from "components/selector";
import AltCTA from "components/buttons/alternate";
import { useApiCall } from "hooks/useApiCall";
import MainCTA from "components/buttons/main";
import { Link } from "react-router-dom";
import Loader from "components/loader";
import { hideModal } from "@redux/slices/modalSlice";
import { clearPendingPipeline, resetPendingPipeline, selectPendingPipeline } from "@redux/slices/curatedCollectionsSlice";
import { getImageSource } from "shared/helpers/image";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1em;\n  text-align: center;\n"], ["\n  padding: 1em;\n  text-align: center;\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1em;\n  border-radius: 3px;\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  text-transform: none;\n  width: 100%;\n"], ["\n  padding: 1em;\n  border-radius: 3px;\n  border: 2px solid ", ";\n  box-sizing: border-box;\n  text-transform: none;\n  width: 100%;\n"])), function (_a) {
    var theme = _a.theme, $isValid = _a.$isValid;
    return $isValid ? theme.veryLightGrey : "darkred";
});
var ButtonsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 1em;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 1em;\n"])));
var Error = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n  width: 100%;\n"], ["\n  color: ", ";\n  text-align: center;\n  width: 100%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.midRed;
});
export var CreatePipelineCollection = function (_a) {
    var initialName = _a.initialName, presetCountryId = _a.presetCountryId;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var pendingPipeline = useAppSelector(selectPendingPipeline);
    var _b = useState(initialName || (pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.initialName) || ''), name = _b[0], setName = _b[1];
    var _c = useState(presetCountryId || null), countryId = _c[0], setCountryId = _c[1];
    var _d = useApiCall("CREATE_RETAIL_PIPELINE"), apiCall = _d.apiCall, isFetching = _d.isFetching, isError = _d.isError;
    var planId = useAppSelector(function (state) { var _a; return (_a = state.dataUser) === null || _a === void 0 ? void 0 : _a.plan_id; });
    var countryOptions = useMemo(function () {
        if (!presetCountryId) {
            return partnerCountries.map(function (country) { return (__assign(__assign({}, country), { value: country.id, label: (_jsxs(Option, { children: [_jsx("img", { src: getImageSource(country), alt: country.name }), country.name] })) })); });
        }
        return emptyArray;
    }, [presetCountryId]);
    var presetCountry = useMemo(function () {
        return presetCountryId
            ? [__assign(__assign({}, partnerCountryMapping[presetCountryId]), { value: presetCountryId, type: "countries" })]
            : emptyArray;
    }, [presetCountryId]);
    useEffect(function () {
        if (planId && planId !== adminPlanId) {
            dispatch(hideModal());
        }
    }, [planId]);
    useEffect(function () {
        if (pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.id)
            dispatch(resetPendingPipeline());
    }, [name, countryId]);
    var handleCreateCollection = function () {
        apiCall({
            method: "POST",
            route: "collection/retail_pipelines/create",
            component: "CREATE_PIPELINE_COLLECTION",
            body: { name: name, country_id: countryId }
        });
    };
    var handleClose = function () {
        if ((pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.questionId) === 0) { // Avoid closing new question code modal
            dispatch(clearPendingPipeline());
        }
        else {
            dispatch(hideModal());
        }
    };
    return (_jsx(Container, { children: _jsxs(Flex, { flexWrap: "wrap", children: [_jsx(FullWidth, { children: _jsx(TextBox, { title: "Collection Name", text: _jsx(Input, { type: "text", name: "name", value: name, "$isValid": !!name.trim(), onChange: function (e) { return setName(e.target.value); } }) }) }), _jsx(FullWidth, { children: _jsx(TextBox, { title: "Country", text: _jsx(Selector, { portal: true, isSingle: true, minWidth: "100%", selectorType: "countries", placeholder: "Select a country...", clickAction: function (e) { return setCountryId(e.data.id); }, isDisabled: !!presetCountryId, defaultValue: presetCountry, options: countryOptions }) }) }), isError && (_jsx(Error, { children: "An error occurred while creating the collection. Please try again" })), isFetching ? (_jsx(Loader, { text: "Creating collection...", size: "1.5em" })) : (_jsxs(ButtonsContainer, { children: [_jsx(AltCTA, { text: (pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.id)
                                ? (pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.questionId) !== undefined
                                    ? "Return to Question Code"
                                    : "Close"
                                : "Cancel", clickAction: handleClose, width: "16em" }), (pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.id) ? (_jsx(Link, { to: "/lp/collection/".concat(pendingPipeline.id, "/settings"), rel: "noreferrer noopener", target: "_blank", children: _jsx(MainCTA, { text: "Finish Configuring Collection", width: "16em" }) })) : (_jsx(MainCTA, { width: "16em", text: "Create Collection", background: theme.midGreen, backgroundHover: theme.deepGreen, clickAction: handleCreateCollection, disabled: !name.trim() || !countryId }))] }))] }) }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
