import { PureComponent } from "react";
import styled from "styled-components";
import SimpleSkeleton from "components/simple-skeleton/index";

const Wrapper = styled.div`
  display: flex;
  padding-top: 1em;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  animation: slide-from-bottom 1s;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left 90%,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
`;
const Card = styled.div`
  animation: fadeIn 1s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1em;
  min-width: 250px;
  width: ${(props) => (props.width ? props.width : "12em")};
  height: ${(props) => (props.height ? props.height : "13em")};
  padding: ${(props) => (props.padding ? props.padding : "0.5em")};
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
`;

export default class SimpleSkeletonCard extends PureComponent {
  render() {
    return (
      <Wrapper>
        {[...Array(this.props.pills).keys()].map((x, i) => (
          <Card
            key={`skeleton-${i}-${x}`}
            width={this.props.width}
            height={this.props.height}
            padding={this.props.padding}
          >
            <Header>
              <SimpleSkeleton borderRadius="50%" height="2em" width="2em" />
              <Details>
                <SimpleSkeleton height="0.5em" width="60%" padding="0.25em" />
                <SimpleSkeleton height="0.5em" width="45%" padding="0.25em" />
              </Details>
            </Header>
            <SimpleSkeleton height="80%" width="100%" borderRadius="0.5em" />
          </Card>
        ))}
      </Wrapper>
    );
  }
}
