import { PureComponent } from "react";
import styled from "styled-components";

const Wrapper = styled.span`
  background: ${(props) => props.theme.midRed};
  transition: ${(props) => props.theme.fastTransition};
  width: 2em;
  height: 2em;
  max-width: 2em;
  max-height: 2em;
  animation: fadeIn 0.5s;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1em")};
  color: white;
  font-weight: bold;
  border-radius: 100%;
  position: absolute;
  top: -0.4em;
  right: -0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class NumberIndicator extends PureComponent {
  render() {
    if (this.props.number === 0) {
      return null;
    }
    return <Wrapper>{this.props.number}</Wrapper>;
  }
}
